import { Card, CardBody, CardHeader } from 'reactstrap';
import { STRIPE_PAYMENT_URL, URL_CONSTANTS } from '../store/constant';
import { useAuth } from '../hooks/useAuth';
import { Link, useNavigate } from 'react-router-dom';
import Emitter from './Emitter';
import { useEffect, useState } from 'react';
import tick from '../assets/images/tick.png';
import cross from '../assets/images/cross.png';
import axiosInstance from '../services/axio-service';

const PricingPlan = (props) => {
  const { user } = useAuth();
  let user_id = localStorage.getItem('user_id');

  console.log('user_id', user_id);
  const navigate = useNavigate();

  const [existingPackage, setExistingPackage] = useState();

  useEffect(() => {
    if (!user) return;

    axiosInstance
      .get('/subscribed-product/')
      .then((response) => {
        setExistingPackage(response?.data?.plan);
      })
      .catch((error) => {
        // Handle the error if needed
      });
  }, [user]);

  const { features } = props;
  const { availableFeature } = features[0];
  const { notAvailableFature } = features[0];

  return (
    <Card className="planCard">
      <CardBody>
        <form>
          {props?.header == 'Premium' && (
            <div className="popularWrapper">
              <div className="papularContent">
                <p className="popularText">Most Popular</p>
              </div>
              <div className="caretSvg">
                <svg width="39" height="17" viewBox="0 0 39 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M37.0488 -1.71282e-06L1.94783 1.78676e-06C1.59245 0.000650321 1.2441 0.0577182 0.94028 0.165058C0.636462 0.2724 0.388678 0.425949 0.223597 0.609181C0.0585162 0.792414 -0.0176061 0.99839 0.00342057 1.20494C0.024451 1.41149 0.141829 1.61078 0.342932 1.78138L17.8934 16.541C18.6208 17.153 20.372 17.153 21.1013 16.541L38.6518 1.78138C38.8549 1.61114 38.974 1.41174 38.9962 1.20485C39.0184 0.997953 38.9427 0.791481 38.7775 0.607861C38.6123 0.42424 38.3638 0.270493 38.059 0.163326C37.7543 0.0561601 37.4049 -0.000327905 37.0488 -1.71282e-06Z"
                    fill="#FC5185"
                  />
                </svg>
              </div>
            </div>
          )}
          <p className={`type ${props?.header}`}>{props?.header}</p>
          <p className="type_sub">{props?.subHeader}</p>
          {/* <p className={`reports ${props.header} `}>
            {' '}
            {props.report == 1 ? `${props.report} Report` : `${props.report} Reports`}
          </p> */}

          <h3 style={{ padding: '0', marginTop: '-0.5rem' }}>
            {props.header == 'Standard' && <span className="dolar">$</span>}
            <span className={props.header == 'Standard' ? `priceDollar` : 'contactQuote'}>{`${props.price}  `}</span>
          </h3>
          <p className="emailSupport"> Priority email support</p>

          {/* {props.planKey === 'free' && !user && (
            <Link
              className={'btn btn-md btn-block btn-primary w-100'}
              onClick={(e) => {
                Emitter.emit('show_signup');
                return false;
              }}
            >
              Sign Up
            </Link>
          )} */}

{/* https://buy.stripe.com/test_00geVMbWK8G2bfy4gg?client_reference_id */}
          {user
            ? props.planKey == 'standard_member_plan' && (
                <a
                  href={`${STRIPE_PAYMENT_URL.STRIPE_URL}=${user_id}`}
                  className={`btn buyNowBtn ${props.header} btn-lg btn-block w-75 p-1 ${
                    props.outline ? 'btn-outline-primary' : 'btn-primary'
                  }`}
                >
                  {props.planKey !== existingPackage ? props.buttonLabel : 'Repurchase'}
                </a>
              )
            : props.planKey == 'standard_member_plan' && (
                <button
                  className={`btn buyNowBtn ${props.header} btn-lg btn-block w-75 p-1 ${
                    props.outline ? 'btn-outline-primary' : 'btn-primary'
                  }`}
                  onClick={(e) => {
                    Emitter.emit('show_login');
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  Login
                </button>
              )}

          {props.planKey == 'premium_member_plan' && (
            <button
              className={`btn buyNowBtn ${props.header} btn-lg btn-block w-75 p-1 ${
                props.outline ? 'btn-outline-primary' : 'btn-primary'
              }`}
              onClick={(e) => {
                navigate('/contact-us');
              }}
            >
              {props.planKey !== existingPackage ? props.buttonLabel : 'Repurchase'}
            </button>
          )}
          {/* {props.planKey !== 'free' && !user && (
            <Link
              className={'btn btn-md w-75  btn-block btn-primary'}
              onClick={(e) => {
                Emitter.emit('show_login');
                return false;
              }}
            >
              Login
            </Link>
          )} */}
          <hr style={{ margin: '1rem 1.8rem' }} />
          <div className="featureWrapper">
            <p className="fatureHeading text-start ">
              {' '}
              {props.header == 'Standard' ? 'Features' : 'Area of interest : USA'}{' '}
            </p>
            <ul className="list-unstyled mt-3 mb-4">
              {availableFeature?.map((feature, i) => (
                <div className="priceFeatures gap-3 " key={i}>
                  <span>
                    {' '}
                    <img
                      width={13}
                      height={13}
                      style={{
                        maxWidth: 'none',
                      }}
                      src={tick}
                    />{' '}
                  </span>{' '}
                  <li className="priceFeaturesLi" key={`${props.planKey + i}`}>
                    {feature}
                  </li>
                </div>
              ))}

              {notAvailableFature?.map((feature, i) => (
                <div className="priceFeatures    gap-3 text-center">
                  <span>
                    {' '}
                    <img
                      src={cross}
                      width={13}
                      height={13}
                      style={{
                        maxWidth: 'none',
                      }}
                    />
                  </span>{' '}
                  <li className="priceFeaturesLi" key={`${props.planKey + i}`}>
                    {feature}
                  </li>
                </div>
              ))}
            </ul>
            <input type={'hidden'} name={'pricePlan'} value={`${props.planKey}`}></input>
          </div>
        </form>
      </CardBody>
    </Card>
  );
};

export default PricingPlan;
