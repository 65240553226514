import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import up from '../assets/images/up.png';
import down from '../assets/images/down.png';
import MyProfile from '../components/UserNavbar';
import UserFooter from './userFooter';
import rightArrow from '../assets/images/rightarrow.svg';
import lockIcon from '../assets/images/lockIcon.svg';
import '../assets/css/leads.css';
import axiosInstance from '../services/axio-service';
import { useParams } from 'react-router-dom';
import Loader from '../components/Loader';
import { useDeferredValue } from 'react';
import { Input, InputGroup, InputGroupText, Collapse } from 'reactstrap';
import searchIcon from '../assets/images/search.png';

import { useForm } from 'react-hook-form';
import CopyRightComponent from './copyright';
import Pagination from '../components/Pagination';
import { useAuth } from '../hooks/useAuth';

const Leads = () => {
  const [leads, setLeads] = useState([]);
 
 
  const [leadsCount, setLeadsCount] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [isOpen, setIsOpen] = useState(false);
  const [isAccountLocked, setIsAccountLocked] = useState(null);
  // console.log('isAccountLocked', isAccountLocked);

  const [sortBy, setSortBy] = useState('id');
  const [address, setAdresss] = useState('');
  const defferdAdress = useDeferredValue(address);
  const [sortOrder, setSortOrder] = useState('asc');
  const [facility_types, setfacility_types] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const getLeads = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `vendorleads/?sortBy=${sortBy}&sortDir=${sortOrder}&page=${currentPage}&page_size=${Number(
          itemsPerPage
        )}&address=${address}&facility_type=${facility_types.length > 0 ? facility_types.join(',') : ''}`
      );
      // setLeads([]);
      const res = await axiosInstance.get('vendoraccountstatus');
      const { is_vendor_approved } = res.data;

      setIsAccountLocked(is_vendor_approved);

      setLeads(response.data?.results);

      setLeadsCount(response.data?.count);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getLeads();
  }, []);

  useEffect(() => {
    getLeads();
  }, [currentPage, sortBy, sortOrder, facility_types, address]);

  const handleSort = (field, sortType) => {
    setSortBy(field);
    setSortOrder(sortType);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  let heading = [
    {
      item: 'Lead Id',
      value: 'id',
    },
    {
      item: 'address',
      value: 'address',
    },

    {
      item: ' Property Type',
      value: 'facility_type',
    },
    {
      item: 'Site Type',
      value: 'SiteType',
    },
    {
      item: 'Site Area',
      value: 'total_area_sqft',
    },
    {
      item: 'Date',
      value: 'created_at',
    },
  ];

  const HeadingComp = ({ item, value }) => {
    return (
      <th>
        {item}
        <span>
          <img src={up} className="upIcon" alt="" onClick={() => handleSort(value, 'asc')} />{' '}
          <img
            style={{ marginLeft: '-0.5rem' }}
            className="downIcon"
            src={down}
            onClick={() => handleSort(value, 'desc')}
            alt=""
          />
        </span>
      </th>
    );
  };

  const LeadsLock = () => {
    return (
      <div className="leadLocked">
        <div className="d-flex flex-column align-items-center">
          {/* <img src={lockIcon} alt="" /> */}
          <p className="lockedPara">
            Access to leads will be granted upon approval of your account by the administrator.
          </p>
          {/* <button className="paymentBtn">Proceed To Payment</button> */}
        </div>
      </div>
    );
  };

  function formatDate(dateString) {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);
    return formattedDate;
  }

  return (
    <div>
      <MyProfile />
      <div className="leadsWrapper" style={{ position: 'relative' }}>
        {loading && <Loader content={'Fetching Data'} />}

        {isAccountLocked==false && <LeadsLock />}
        <p className="pt-3 leadsTitle  mx-5">
          Leads<span>.</span>
        </p>
        <hr />

        <section className=" ">
          <div className="  mt-5 mx-5">
            <div className="leadsHeader">
              <div>
                <InputGroup>
                  <Input
                    placeholder="Search Address"
                    onChange={(e) => {
                      setAdresss(e.target.value);
                      setCurrentPage(1);
                    }}
                    value={address}
                  />
                  <InputGroupText>
                    {' '}
                    <img src={searchIcon} width={15} />
                  </InputGroupText>
                </InputGroup>
              </div>
            </div>
            <div className="leadsWrapperfilter">
              <div className="leadsLeftCol">
                <div className="d-flex align-items-center justify-content-between">
                  <p className="filterTitle">Filter</p>
                  <button
                    onClick={() => {
                      setfacility_types([]);
                      setAdresss('');
                      setSortBy('id');
                      setSortOrder('asc');
                      setCurrentPage(1);
                    }}
                  >
                    Clear Filter
                  </button>
                </div>

                <div className="filterCollapseIcon" onClick={() => setIsOpen(!isOpen)}>
                  <p>Indurstries</p>
                  <img src={rightArrow} className={isOpen ? 'arrowIconBottom' : 'arrowIconRight'} />
                </div>

                <Collapse isOpen={isOpen}>
                  <div className="filterCheckboxWrapper">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                         if (e.target.checked) {
                          setfacility_types([...facility_types, 'commercial']);
                        } else {
                        
                          setfacility_types(facility_types.filter((item) => item !== 'commercial'));
                        }
                      }}
                      checked={facility_types.includes('commercial')}
                    />
                    <label>Commerical</label>
                  </div>
                  <div className="filterCheckboxWrapper">
                    <input
                      type="checkbox"
                      value="residential"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setfacility_types([...facility_types, 'residential']);
                        } else {
                          setfacility_types(facility_types.filter((item) => item !== 'residential'));
                        }
                      }}
                      checked={facility_types.includes('residential')}
                      disabled
                    />
                    <label>Residential</label>
                  </div>

                  <div className="filterCheckboxWrapper">
                    <input
                      type="checkbox"
                      value="grocery"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setfacility_types([...facility_types, 'grocery']);
                        } else {
                          setfacility_types(facility_types.filter((item) => item !== 'grocery'));
                        }
                      }}
                      disabled
                      checked={facility_types.includes('grocery')}
                    />
                    <label>Grocery </label>
                  </div>
                  <div className="filterCheckboxWrapper">
                    <input
                      type="checkbox"
                      value="mixuse"
                      disabled
                      onChange={(e) => {
                        if (e.target.checked) {
                          setfacility_types([...facility_types, 'mixuse ']);
                        } else {
                          setfacility_types(facility_types.filter((item) => item !== 'mixuse'));
                        }
                      }}
                    />
                    <label>MixUse (Coming Soon)</label>
                  </div>
                  <div className="filterCheckboxWrapper">
                    <input
                      type="checkbox"
                      value="office"
                      disabled
                      onChange={(e) => {
                        if (e.target.checked) {
                          setfacility_types([...facility_types, 'office ']);
                        } else {
                          setfacility_types(facility_types.filter((item) => item !== 'office'));
                        }
                      }}
                    />
                    <label>Office (Comming Soon)</label>
                  </div>
                  <div className="filterCheckboxWrapper">
                    <input
                      type="checkbox"
                      value="retail"
                      disabled
                      onChange={(e) => {
                        if (e.target.checked) {
                          setfacility_types([...facility_types, 'retail']);
                        } else {
                          setfacility_types(facility_types.filter((item) => item !== 'retail'));
                        }
                      }}
                    />
                    <label>Retail (Comming Soon)</label>
                  </div>
                </Collapse>
              </div>
              <div className="leadsRightCol mb-5">
                <div className="leadsTable" style={{ overflowX: 'auto' }}>
  
                    <table style={{ width: '100%' }}>
                      <thead >
                        <tr style={{"backgroud":"#FAFAFA"}}>
                          {heading?.map((head) => (
                            <HeadingComp item={head.item} value={head.value} />
                          ))}

                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {leads?.map((row) => (
                          <tr key={row.id} style={{
                            background : row?.is_lead_read ? "#d9d9d9":"#fff",
                            border:"none"
                          }}>
                            <td className="idTd ">
                              {' '}
                              <div style={{ display: 'flex', justifyContent: 'center', gap: '5px' }}>
                                <p
                                  style={{
                                    padding: '0',
                                    margin: '0',
                                  }}
                                >
                                  {' '}
                                  {row.id}
                                </p>
                              </div>{' '}
                            </td>
                            <td>{row.address}</td>
                            <td>{row.facility_type}</td>
                            <td>Gas Station</td>
                            <td>{row.total_area_sqft}</td>
                            <td>{formatDate(row.created_at)}</td>

                            <td>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <div>
                                  <Link to={`/lead-detail/${row.id}/${row.is_lead_read}`}>
                                    <button className="viewBtn">View</button>
                                  </Link>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                 
                </div>
                {isAccountLocked && leads?.length > 0 && (
                  <Pagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    handlePageChange={handlePageChange}
                    itemsPerPage={itemsPerPage}
                    leadsCount={leadsCount}
                  />
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
      <CopyRightComponent />
    </div>
  );
};

export default Leads;
