import { useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';
import Emitter from '../../components/Emitter';

function StepActions({
  disablePrevious,
  disableNext,
  showResult,
  showFinish,
  showNext,
  handleGenerateReportClick,
  handlePrevious,
  handleFinishClick,
  remainingCreditError,
  user,
  reports,
  submit,
  isLoading,
  reportPath,
}) {
    const navigate = useNavigate();

  const paymentHandle = () => {
    navigate('/payment');
  };
  return (
    <div className="actions clearfix mt-5">
      <ul role="menu" aria-label="Pagination">
        <li>
          <Button
            disabled={disablePrevious === true  }
            className={'btn-previous'}
            role="menuitem"
            onClick={handlePrevious}
          >
            Previous
          </Button>
        </li>
        <li
          aria-hidden="false"
          className={showNext === true || showNext === undefined ? 'd-block' : 'd-none'}
          aria-disabled={disableNext === true}
        >
          <Button className={'btn-next'} role="menuitem">
            Next
          </Button>
        </li>
        {reportPath ? (
          <li className={showResult === true ? 'd-block' : 'd-none'}>
            <Button className={'btn-generate-report'} onClick={handleGenerateReportClick} role="menuitem">
              View/Download Report
            </Button>
          </li>
        ) : showFinish ? (
          <li className={showFinish === true ? 'd-block' : 'd-none'}>
            <Button
              className={'btn-finish'}
              onClick={() => {
                submit();
              }}
              role="menuitem"
              disabled={isLoading}
            >
              Generate Report
            </Button>
          </li>
        ) : (
        user &&  Number(reports) == 0 && (
            <li className={'d-block'}>
              <Button className={'btn-finish'} role="menuitem " onClick={paymentHandle}>
                Go to Payment
              </Button>
            </li>
          )
        )}

        {!user && (
          <li className={user === false ? 'd-block' : 'd-none'}>
            <Button
              className={'btn-finish'}
              role="menuitem"
              onClick={() => {
                Emitter.emit('show_login');
              }}
            >
              Login
            </Button>
          </li>
        )}
      </ul>
    </div>
  );
}

export default StepActions;
