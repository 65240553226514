import { useState } from 'react';
import VideoViewModel from '../components/VideoViewModel';
import SubHeader from './sub-header';
import ReactPlayer from 'react-player/youtube';

import thumbnail from '../assets/images/thumbnail.jpg';
import vendorthumbnail from '../assets/images/vendorthumbnail.png';
import howtousethumbnail from '../assets/images/howtousethumbnail.png';

import youtube from '../assets/images/youtube.png';

const HowToUse = () => {
  const [isVideoModel, setIsVideoModel] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);

  return (
    <div>
      <VideoViewModel isVideoModel={isVideoModel} setIsVideoModel={setIsVideoModel} videoUrl={videoUrl} />

      <section className="contact-hero-area">
        <SubHeader />
        <div className="payment-hero-main">
          <div className="container w-auto">
            <div className="row align-items-center">
              <div className="col-md-12 col-12">
                <div className="text-white">
                  <h2 className="wow fadeInUp contactUsTitle paymentText" data-wow-delay=".2s">
                    How to Use
                  </h2>
                  <p className="paysubText">
                    Learn how to use the Tezintel platform for projections and valuations for your business
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="">
        <div className="how-to-use-video-wrapper">
          <div className="how-to-use-video">
            {/* <img/> */}
            <div className="thumbnail-container">
              <img src={vendorthumbnail} alt="Thumbnail" className="thumbnail" />
              <img
                src={youtube}
                onClick={() => {
                  setIsVideoModel(true);
                  setVideoUrl('https://youtu.be/J9TwNFlpi84');
                }}
                alt="YouTube Icon"
                className="youtube-icon"
              />
            </div>
          </div>

          <div className="how-to-use-video">
            {/* <img/> */}
            <div className="thumbnail-container">
              <img src={howtousethumbnail} alt="Thumbnail" className="thumbnail" />
              <img
                src={youtube}
                onClick={() => {
                  setIsVideoModel(true);
                  setVideoUrl('https://youtu.be/ge95Oq7r0II');
                }}
                alt="YouTube Icon"
                className="youtube-icon"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HowToUse;
