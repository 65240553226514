import { Carousel, CarouselControl, CarouselIndicators, CarouselItem } from 'reactstrap';
import banner2 from '../assets/images/home-banner-2.jpg';
import banner1 from '../assets/images/home-banner-1.jpg';
import { useState } from 'react';
import { Link } from 'react-router-dom';

function CarouselComponent() {
  const items = [
    {
      id: 1,
    },
    {
      id: 2,
    },
    {
      id: 3,
    },
  ];

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const [activeIndex, setActiveIndex] = useState(0);

  // State for Animation
  const [animating, setAnimating] = useState(false);

  return (
    <Carousel activeIndex={activeIndex} next={next} previous={previous}>
      <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
      {[
        <CarouselItem key={'1'} onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)}>
          <img src={banner2} className="carousel-image-bg carousel-image  " alt="Tezintel" />
          <section className="main-slider">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-12 col-12">
                  <div className="hero-content">
                    <h2 className="wow fadeInUp" data-wow-delay=".4s">
                      Data Insights to Fuel Your Business Growth
                    </h2>
                    <p className="wow fadeInUp" data-wow-delay=".6s">
                      We empower better business decisions through cutting-edge data science driven by our innovative
                      projection and valuation tools
                    </p>
                    <div className="button wow fadeInUp" data-wow-delay=".8s">
                      <Link className="btn btn-icon icon-home" to={'/wizard'}>
                        GET MY INSIGHTS
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </CarouselItem>,
        <CarouselItem key={'2'} onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)}>
          <img src={banner1} className="carousel-image-bg carousel-image" alt="Tezintel" />
          <section className="main-slider">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-12 col-12">
                  <div className="hero-content">
                    <h2 className="wow fadeInUp" data-wow-delay=".4s">
                      Data Insights to Fuel Your Business Growth
                    </h2>
                    <p className="wow fadeInUp" data-wow-delay=".6s">
                      We empower better business decisions through cutting-edge data science driven by our innovative
                      projection and valuation tools
                    </p>
                    <div className="button wow fadeInUp" data-wow-delay=".8s">
                      <Link className="btn btn-icon icon-home" to={'/wizard'}>
                        GET MY INSIGHTS
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </CarouselItem>,
        <CarouselItem key={'3'} onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)}>
          <img src={banner2} className="carousel-image-bg carousel-image " alt="Tezintel" />
          <section className="main-slider">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-12 col-12">
                  <div className="hero-content">
                    <h2 className="wow fadeInUp" data-wow-delay=".4s">
                      Data Insights to Fuel Your Business Growth
                    </h2>
                    <p className="wow fadeInUp" data-wow-delay=".6s">
                      We empower better business decisions through cutting-edge data science driven by our innovative
                      projection and valuation tools
                    </p>
                    <div className="button wow fadeInUp" data-wow-delay=".8s">
                      <Link className="btn btn-icon icon-home" to={'/wizard'}>
                        GET MY INSIGHTS
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </CarouselItem>,
      ]}
      <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
      <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
    </Carousel>
  );
}

export default CarouselComponent;
