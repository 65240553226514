import React, { createContext, useContext, useState } from 'react';

export const RemainReportStateContext = createContext();

export function RemainingReportProvider({ children }) {
  const [remainingReports, setRemainingReport] = useState(0);

  return (
    <RemainReportStateContext.Provider value={{ remainingReports, setRemainingReport }}>
      {children}
    </RemainReportStateContext.Provider>
  );
}

export function useRemainingReportState() {
  const context = useContext(RemainReportStateContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppProvider');
  }
  return context;
}
