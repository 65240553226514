import React from 'react';
import './model.css'; // Ensure you have some basic styling for the modal

const MobileModel = ({ show, handleClose, children }) => {
    if (!show) {
        return null;
    }

    return (
        <div className="mb-modal-overlay">
            <div className="mb-modal-content">
                <button className="mb-modal-close" onClick={handleClose}>×</button>
                {children}
            </div>
        </div>
    );
};

export default MobileModel;
