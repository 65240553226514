import React, { useState, useEffect, useRef } from 'react';

import Map, { Source, Layer, Marker, NavigationControl, ScaleControl, useControl } from 'react-map-gl';
// @ts-ignore
import 'mapbox-gl/dist/mapbox-gl.css';
 
 

import { bbox } from '@turf/turf';
import ClipLoader from 'react-spinners/ClipLoader';

import location from '../../assets/images/mabox-marker.png';
import { useService } from '../../hooks/State';
import Loader from '../Loader';

function MapComp({
  center,
  zoom,
  SuitabilityGeometryselected,
  isLoggedIn,
  hexagonData,
  removeBoundary,
  loading,
  autocompleteValue,
  showMarker,
}) {
  const [viewState, setViewState] = useState({
    longitude: center.lng,
    latitude: center.lat,
    zoom: zoom,
  });
  const mapRef = useRef(null);
  const { lngLat, setLngLat } = useService();

  // Convert hexagon data to geojson, but ensure valid GeoJSON structure
  const geoJsonData =
    hexagonData && hexagonData.hexagons
      ? {
          type: 'FeatureCollection',
          features: hexagonData.hexagons.map((suitability) => ({
            type: 'Feature',
            geometry: suitability.geometry,
            properties: suitability.properties,
          })),
        }
      : null;

  useEffect(() => {
    if (SuitabilityGeometryselected && mapRef.current) {
      try {
        const bboxSuitability = bbox(SuitabilityGeometryselected);
        mapRef.current.fitBounds(
          [
            [bboxSuitability[0], bboxSuitability[1]],
            [bboxSuitability[2], bboxSuitability[3]],
          ],
          { padding: 50 }
        );
      } catch (error) {
        console.error('Error fitting bounds to geometry:', error);
      }
    }
  }, [SuitabilityGeometryselected]);

  useEffect(() => {
    setViewState({
      longitude: center.lng,
      latitude: center.lat,
      zoom: zoom,
    });
  }, [center, zoom]);

  // Handle when no valid GeoJSON data is available
  // if (!geoJsonData || !geoJsonData.features.length) {
  //   console.error("GeoJSON data is invalid or empty.");
  // }

  console.log('geoJsonData', geoJsonData);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      {loading && (
        <Loader/>
        // <div className="loader absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 bg-black opacity-45 w-full h-full flex justify-center items-center">
        //   <div className="absolute top-1/2 left-2/3 transform -translate-x-1/2 -translate-y-1/2">
        //     <ClipLoader color="#2A4D69" loading={loading} size={150} />
        //   </div>
        // </div>
      )}
      {hexagonData?.legend && (
        <div className="absolute bottom-0 right-0 p-4 z-10 bg-white rounded-lg shadow-md m-4 width-40 h-[9rem] overflow-y-auto">
          <div className="text-lg font-bold">Legend</div>
          <div className="flex flex-col">
            {Object.entries(hexagonData.legend).map(([label, color]) => (
              <div key={label} className="flex items-center space-x-2">
                <div className="w-4 h-4 rounded-full" style={{ backgroundColor: color }}></div>
                <div>{label}</div>
              </div>
            ))}
          </div>
        </div>
      )}
      <Map
        initialViewState={viewState}
        mapboxAccessToken="pk.eyJ1Ijoic2hhcmplZWwwNyIsImEiOiJjbGt0Y3F4cjQwMnVoM2tvMDhqNTk1MzA3In0.c_2wdbcMLmCAHwx7E-2J2Q"
        style={{ width: '100vw', height: '100vh' }}
        mapStyle="mapbox://styles/mapbox/streets-v9"
        ref={mapRef}
        renderWorldCopies={false}
        onClick={(e) => {
          setLngLat(e.lngLat);
          console.log('lngLat', lngLat);
        }}
      >
        <NavigationControl showCompass={true} showZoom={true} position="top-left" />

        {autocompleteValue && showMarker && (
          <Marker longitude={center.lng} latitude={center.lat} offsetTop={-20} offsetLeft={-10}>
            <div className="location-icon">
              <img src={location} alt="location" width={30} height={30} />
            </div>
          </Marker>
        )}

        {/* <Marker
          longitude={lngLat.lng ? lngLat.lng : 39.0997}
          latitude={lngLat.lat ? lngLat.lat : -94.5786}
          offsetTop={-20}
          offsetLeft={-10}

        >
          <div className="w-4 h-4 bg-red-500 rounded-full"></div>
        </Marker> */}
        {geoJsonData && geoJsonData?.features?.length > 0 && (
          <Source id="suitability-source" type="geojson" data={geoJsonData}>
            <Layer
              id="suitability-layer"
              type="fill"
              paint={{
                'fill-color': ['get', 'color'],
                'fill-opacity': 0.5,
              }}
            />
            <Layer id="suitability-outline-layer" type="line" paint={{ 'line-color': '#2A4D69', 'line-width': 2 }} />
          </Source>
        )}

        {!removeBoundary && SuitabilityGeometryselected && (
          <Source id="suitability-geometry-source" type="geojson" data={SuitabilityGeometryselected}>
            <Layer
              id="suitability-geometry-layer"
              type="fill"
              paint={{ 'fill-color': '#F28C28', 'fill-opacity': 0.5 }}
            />
            <Layer
              id="suitability-geometry-outline-layer"
              type="line"
              paint={{ 'line-color': '#2A4D69', 'line-width': 2 }}
            />
          </Source>
        )}
      </Map>
    </div>
  );
}

export default MapComp;