import leftdoublearrow from '../assets/images/left-arrow.png';
import leftArrow from '../assets/images/leftarrow.svg';
import rightArrow from '../assets/images/rightarrow.svg';

const Pagination = ({ currentPage, setCurrentPage, leadsCount, itemsPerPage, handlePageChange }) => {
  const totalPages = Math.ceil(leadsCount / itemsPerPage);
  const pageNumbers = [];

  if (totalPages <= 15) {
    // If total pages are 15 or less, show all pages
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
  } else {
    // Always include the first two pages
    pageNumbers.push(1, 2);

    // Calculate the range around the current page
    let startPage = Math.max(3, currentPage - 2);
    let endPage = Math.min(totalPages - 2, currentPage + 2);

    // Insert ellipsis if there's a gap between start and current range
    if (startPage > 3) {
      pageNumbers.push('...');
    }

    // Include the dynamic range around the current page
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    // Insert ellipsis if there's a gap between current range and end
    if (endPage < totalPages - 2) {
      pageNumbers.push('...');
    }

    // Always include the last two pages
    pageNumbers.push(totalPages - 1, totalPages);
  }

  return (
    <div className="paginationWrapper d-flex justify-content-end mt-3">
      <div className="pagination gap-0">
        <img
          src={leftdoublearrow}
          alt=""
          style={{
            width: '20px',
            marginRight: '5px',
            cursor: 'pointer',
          }}
          onClick={() => handlePageChange(1)}
          disabled={currentPage === 1}
        />
        <button className='pagination-arrow' disabled={currentPage === 1} onClick={() => setCurrentPage(currentPage - 1)}>
          <img src={leftArrow} />
        </button>
        {pageNumbers.map((page, index) =>
          typeof page === 'number' ? (
            <button key={index} onClick={() => handlePageChange(page)} className={currentPage === page ? 'active' : ''}>
              {page}
            </button>
          ) : (
            <span key={index} style={{ userSelect: 'none' }}>
              ...
            </span>
          )
        )}
        <button
          className='pagination-arrow'
          disabled={currentPage === Math.ceil(leadsCount / itemsPerPage)}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          <img src={rightArrow} />
        </button>

        <img
          src={leftdoublearrow}
          alt=""
          onClick={() => handlePageChange(Math.ceil(leadsCount / itemsPerPage))}
          style={{
            width: '20px',
            marginLeft: '5px',
            transform: 'rotate(180deg)',
            cursor: 'pointer',
          }}
          disabled={currentPage === Math.ceil(leadsCount / itemsPerPage)}
        />
      </div>
    </div>
  );
};

export default Pagination;
