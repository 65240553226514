import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import up from '../assets/images/up.png';
import down from '../assets/images/down.png';
import CopyRightComponent from './copyright';
import MyProfile from '../components/UserNavbar';
import UserFooter from './userFooter';
import { Input, InputGroup, InputGroupText, Collapse } from 'reactstrap';
import searchIcon from '../assets/images/search.png';
import '../assets/css/leads.css';

import axiosInstance from '../services/axio-service';
import { useParams } from 'react-router-dom';
import Loader from '../components/Loader';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { useForm } from 'react-hook-form';

import { toast } from 'react-toastify';
import rightArrow from '../assets/images/rightarrow.svg';

const VendorPage = () => {
  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [vendor_types, setvendor_types] = useState([]);
  const [vendorData, setVendorData] = useState(null);
 
  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    try {
      setLoading(true);
      let newData = {
        ...data,
        recipients: selectedEmails,
      };
      const response = await axiosInstance.post(`vendor-message/`, {
        newData,
      });
      reset();

      toast.success(response.data.message);
      setModal(false);

      setLoading(false);
    } catch (error) {
      console.log('error', error);
      toast.error(error.response.data.error);

      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const toggle = () => setModal(!modal);

  const getLeads = async () => {
    setLoading(true);
    try {
      let business_ids = vendorData.filter((vendor) => vendor_types.includes(vendor.name)).map((item) => item.id);

      const response = await axiosInstance.get(`vendors/?business_type_id=${business_ids}`);

      setVendors(response.data);

      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLeads();
  }, []);

  useEffect(() => {
    getLeads();
  }, [vendor_types, vendorData]);

  let heading = [
    {
      item: 'Id',
      value: 'id',
    },
    {
      item: 'full_name',
      value: 'full_name',
    },

    {
      item: 'email',
      value: 'email',
    },

    {
      item: 'date_joined',
      value: 'date_joined',
    },
  ];

  const HeadingComp = ({ item, value }) => {
    return <th>{item}</th>;
  };

  function formatDate(dateString) {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);
    return formattedDate;
  }

  const [selectedRows, setSelectedRows] = useState([]);

  const handleCheckboxChange = (rowId) => {
    setSelectedRows((currentSelectedRows) => {
      if (currentSelectedRows.includes(rowId)) {
        return currentSelectedRows.filter((id) => id !== rowId);
      } else {
        return [...currentSelectedRows, rowId];
      }
    });
  };

  const [selectedEmails, setSelectedEmails] = useState([]);

  // Extract emails whenever selectedRows changes
  useEffect(() => {
    const emails = vendors?.filter((lead) => selectedRows.includes(lead.id))?.map((lead) => lead.email);
    setSelectedEmails(emails);
  }, [selectedRows, vendors]);

  const renderEmailTags = () =>
    selectedEmails?.map((email) => (
      <span key={email} className="email-tag">
        {email}
      </span>
    ));

  useEffect(() => {
    const getBuissness = async () => {
      const res = await axiosInstance.get('/businesstype/');
      setVendorData(res?.data);
    };
    getBuissness();
  }, []);

  return (
    <div>
      {loading && <Loader content={'Fetching Data'} />}
      <MyProfile />
      <div className="leadsWrapper" style={{ position: 'relative' }}>
        <p className="mt-3 leadsTitle   mx-5">
          Vendor<span>.</span>
        </p>
        <hr />

        <Modal isOpen={modal} toggle={toggle}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader toggle={toggle}>Create Message</ModalHeader>
            <ModalBody className="mt-3">
              <div className="form-group">
                <label htmlFor="recipients">Recipients</label>

                {renderEmailTags()}

                {errors.recipients && <span className="text-danger">Recipients is required</span>}
              </div>
              <div className="form-group mt-2">
                <label htmlFor="subject">Subject</label>
                <input
                  type="text"
                  className="form-control"
                  id="subject"
                  name="subject"
                  {...register('subject', { required: 'subject is required' })}
                />
                {errors.subject && <span className="text-danger">Subject is required</span>}
              </div>
              <div className="form-group mt-2">
                <label htmlFor="message">Message</label>
                <textarea
                  className="form-control"
                  id="message"
                  name="message"
                  rows="4"
                  {...register('message', { required: 'message is required' })}
                ></textarea>
                {errors.message && <span className="text-danger">Message is required</span>}
              </div>
            </ModalBody>
            <ModalFooter>
              <button onClick={handleSubmit} className="sendVendorBtn">
                Submit
              </button>{' '}
            </ModalFooter>
          </form>
        </Modal>
        <section className=" ">
          <div className="  mt-5  mx-5">
            <div className="leadsHeader">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  width: '100%',
                }}
              >
                <button onClick={toggle} disabled={selectedRows?.length == 0} className="sendVendorBtn">
                  Send Message
                </button>
              </div>
            </div>
            <div className="leadsWrapperfilter">
              <div className="leadsLeftCol">
                <div className="d-flex align-items-center justify-content-between">
                  <p className="filterTitle">Filter</p>
                  <button
                    onClick={() => {
                      setvendor_types([]);
                    }}
                  >
                    Clear Filter
                  </button>
                </div>

                <div className="filterCollapseIcon" onClick={() => setIsOpen(!isOpen)}>
                  <p>Vendor Types</p>
                  <img src={rightArrow} className={isOpen ? 'arrowIconBottom' : 'arrowIconRight'} />
                </div>

                <Collapse isOpen={isOpen}>
                  {vendorData?.map((item) => (
                    <div className="filterCheckboxWrapper">
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          if (e.target.checked) {
                            setvendor_types([...vendor_types, item.name]);
                          } else {
                            setvendor_types(vendor_types.filter((item2) => item2 !== item.name));
                          }
                        }}
                        checked={vendor_types.includes(item.name)}
                      />
                      <label>{item.name}</label>
                    </div>
                  ))}
                </Collapse>
              </div>
              <div className="leadsRightCol">
                <div className="LeadsTable" style={{ overflowX: 'auto' }}>
                  <table style={{ width: '100%' }}>
                    <thead>
                      <tr>
                        {heading?.map((head) => (
                          <HeadingComp key={head.value} item={head.item} value={head.value} />
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {vendors?.map((row) => (
                        <tr
                          key={row.id}
                          style={{
                            backgroundColor: selectedRows.includes(row.id) ? '#f0f0f0' : 'transparent',
                          }}
                        >
                          <td className="idTd">
                            <div style={{ display: 'flex', justifyContent: 'center', gap: '5px' }}>
                              <input
                                type="checkbox"
                                checked={selectedRows.includes(row.id)}
                                onChange={() => handleCheckboxChange(row.id)}
                              />
                              <p style={{ padding: '0', margin: '0' }}>{row.id}</p>
                            </div>
                          </td>
                          <td>{row.full_name}</td>
                          <td>{row.email}</td>
                          <td>{formatDate(row.date_joined)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <CopyRightComponent />
    </div>
  );
};

export default VendorPage;
