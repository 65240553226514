import React, { useEffect, useState } from 'react';
import income from '../../assets/images/income.svg';

import { useAppState } from '../../hooks/useAppState';

import realestate from '../../assets/images/real-estate.svg';
import propinsurense from '../../assets/images/property-insurance.svg';
import heatbill from '../../assets/images/heat-bill.svg';
import elecbill from '../../assets/images/electric-bill.svg';
import waterSewerbill from '../../assets/images/water-bill.svg';
import maintanancebill from '../../assets/images/repair.svg';
import Management from '../../assets/images/costs.svg';
import Administrative from '../../assets/images/admin-costs.svg';

import { useForm } from 'react-hook-form';
import { Form, FormGroup, Label } from 'reactstrap';
import { WrappedInput } from '../../components/WrappedInput';
import StepActions from './step-actions';
import axios from 'axios';
import { URL_CONSTANTS } from '../../store/constant';
import LoadingOverlay from 'react-loading-overlay-ts';
import axiosInstance from '../../services/axio-service';
import { toast } from 'react-toastify';
import { WrappedNumberInput } from '../../components/WrappedNumberInput';
import { useAuth } from '../../hooks/useAuth';
import { restructureResidentailData } from '../../utils/ObjectUtils';

function OperatingExpensesStep({ step, setStep }) {
  const mystyle = {
    display: 'flex',
    flexDirection: 'col',
  };
  const mystyle2 = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  };

  const [stepValues, setStepValues] = useAppState();
   const [isLoading, setIsLoading] = useState(false);
  const [remainingReports, setRemainingReport] = useState('');

  const { user } = useAuth();

  const getRemainingReport = async () => {
    try {
      const res = await axiosInstance.get('subscribed-product/');

      setRemainingReport(res?.data?.plan);
    } catch (error) {
     
    }
  };

  useEffect(() => {
    getRemainingReport();

    if (stepValues && user) {
      getRemainingReport();
    }
  }, [stepValues, user]);

  const { register, setError, handleSubmit, formState, watch, reset } = useForm({
    defaultValues: stepValues,
    mode: 'onSubmit',
  });
  const { errors } = formState;

  const submit = (data) => {
    // stepValues.stepStatus[step] = 'done';
    // data.stepStatus = [...stepValues.stepStatus];
    let req = { ...stepValues, ...data };
    setStepValues(req);

    let newReq = restructureResidentailData(req);
    let serviceUrl = `${URL_CONSTANTS.WEB_SERVERURL}/ResidentialViewSet/`;

    setIsLoading(true);

    axiosInstance
      .post(serviceUrl, newReq)
      .then(function (response) {
        let responseObj = response.data;
        setStepValues({
          ...stepValues,
          ...{
            reportPath: responseObj.report_path,
            valuation: responseObj.valuation,
            forecast: responseObj.forecast,
          },
        });
        setStep(step + 1);
      })
      .catch(function (response) {
        toast.error('Error occured while generating report check values and try again');
      })
      .finally(function (e) {
        setIsLoading(false);
      });
  };

  const handleBeforeUnload = (event) => {
    const message = 'Are you sure you want to leave? Changes you made may not be saved.';
    event.returnValue = message;
    return message;
  };

  useEffect(() => {
    // Attach the event listener to the beforeunload event
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
  return (
    <>
      <section className="">
        <div className="section-header ">
          <img src={income} alt={''} />
          <h2 className="section-heading">Operating Expenses</h2>
        </div>
        <div>
          <h4 className="my-4 text-primary">If you know any of the operating expenses, enter them here</h4>
        </div>
        <LoadingOverlay active={isLoading} spinner text="Evaluating Results">
          <Form onSubmit={handleSubmit(submit)} noValidate>
            <div className="container">
              <div className={'row'}>
                <div className={'col'}>
                  <div className="mt-2">
                    <img src={realestate} alt={''} />
                    <h5 className="">Real estate tax</h5>
                  </div>
                  <div>
                    <WrappedNumberInput
                      errors={errors}
                      registerhandler={register}
                      min={1}
                      max={100000}
                      required={false}
                      name={'real_estate_tax'}
                      placeholder="$ per annum"
                      className={'number-fields'}
                    />
                  </div>
                </div>
                <div className={'col'}>
                  <div className="mt-3">
                    <img src={propinsurense} alt={''} />
                    <h5 className="">Property insurance</h5>
                  </div>
                  <div>
                    <WrappedNumberInput
                      errors={errors}
                      registerhandler={register}
                      min={1}
                      max={100000}
                      required={false}
                      name={'property_insurance'}
                      placeholder="$ per annum"
                      className={'number-fields'}
                    />
                  </div>
                </div>
              </div>
              <div className={'row'}>
                <div className={'col'}>
                  <div className="mt-2">
                    <img src={heatbill} alt={''} />
                    <h5 className="">Heat bill</h5>
                  </div>
                  <div>
                    <WrappedNumberInput
                      errors={errors}
                      registerhandler={register}
                      min={1}
                      max={100000}
                      required={false}
                      name={'heat_bill'}
                      placeholder="$ per annum"
                      className={'number-fields'}
                    />
                  </div>
                </div>
                <div className={'col'}>
                  <div className="mt-3">
                    <img src={elecbill} alt={''} />
                    <h5 className="">Electric bill</h5>
                  </div>
                  <div>
                    <WrappedNumberInput
                      errors={errors}
                      registerhandler={register}
                      min={1}
                      max={100000}
                      required={false}
                      name={'electric_bill'}
                      placeholder="$ per annum"
                      className={'number-fields'}
                    />
                  </div>
                </div>
              </div>
              <div className={'row'}>
                <div className={'col'}>
                  <div className="mt-2">
                    <img src={waterSewerbill} alt={''} />
                    <h5 className="">Water and sewer bill</h5>
                  </div>
                  <div>
                    <WrappedNumberInput
                      errors={errors}
                      registerhandler={register}
                      min={1}
                      max={100000}
                      required={false}
                      name={'water_and_sewer_bill'}
                      placeholder="$ per annum"
                      className={'number-fields'}
                    />
                  </div>
                </div>
                <div className={'col'}>
                  <div className="mt-3">
                    <img src={maintanancebill} alt={''} />
                    <h5 className="">Maintenance and repairs</h5>
                  </div>
                  <div>
                    <WrappedNumberInput
                      errors={errors}
                      registerhandler={register}
                      min={1}
                      max={100000}
                      required={false}
                      name={'maintenance_and_repairs'}
                      placeholder="$ per annum"
                      className={'number-fields'}
                    />
                  </div>
                </div>
              </div>
              {stepValues?.rentalType == 'True' && (
                <div className={'row'}>
                  <div className={'col'}>
                    <div className="mt-2">
                      <img src={Management} alt={''} />
                      <h5 className="">Management costs</h5>
                    </div>
                    <div>
                      <WrappedNumberInput
                        errors={errors}
                        registerhandler={register}
                        min={1}
                        max={100000}
                        required={false}
                        name={'management_cost'}
                        placeholder="$ per annum"
                        className={'number-fields'}
                      />
                    </div>
                  </div>
                  <div className={'col'}>
                    <div className="mt-3">
                      <img src={Administrative} alt={''} />
                      <h5 className="">Administrative costs</h5>
                    </div>
                    <div>
                      <WrappedNumberInput
                        errors={errors}
                        registerhandler={register}
                        min={1}
                        required={false}
                        max={100000}
                        name={'administrative_cost'}
                        placeholder="$ per annum"
                        className={'number-fields'}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <StepActions
              disablePrevious={false}
              disableNext={true}
              showNext={false}
              showFinish={true}
              showResult={true}
              handleGenerateReportClick={() => {
                window.open(process.env.REACT_APP_SERVER_BASE_URL + stepValues.reportPath, '_blank', 'noreferrer');
              }}
              reports={remainingReports}
              submit={submit}
              handleFinishClick={() => {}}
              handlePrevious={() => {
                setStep(step - 1);
              }}
              user={true}
              reportPath={stepValues?.reportPath}
            />
          </Form>
        </LoadingOverlay>
      </section>
    </>
  );
}

export default OperatingExpensesStep;
