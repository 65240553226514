import { useState } from 'react';
import { AppProvider } from '../hooks/useAppState';
import FirstStep from './form-wizard-steps/first-step';
import SecondStep from './form-wizard-steps/second-step';

function FormWizard() {
  const [steps, setSteps] = useState(0);
  
  return (
    <div>
      <section className="form-banner">
        {steps === 0 && <FirstStep step={steps} setStep={setSteps} />}
        {steps === 1 && <SecondStep step={steps} setStep={setSteps} />}
      </section>
    </div>
  );
}

export default FormWizard;
