import { useAppState } from '../../hooks/useAppState';

import siteImageIcon from '../../assets/images/site-images.svg';
import StepActions from './step-actions';
import { useForm } from 'react-hook-form';
import { Form, FormGroup, FormText, Label } from 'reactstrap';
import { WrappedInput } from '../../components/WrappedInput';
import { WrappedFileInput } from '../../components/WrappedFileInput';
import GenericStepForm from './generic-step-form';

function SiteImageStep({ step, setStep }) {
  const [stepValues, setStepValues] = useAppState();
   const { register, setError, handleSubmit, formState, getValues, reset } = useForm({
    defaultValues: stepValues,
    mode: 'onSubmit',
  });
  const { errors } = formState;

  return (
    <GenericStepForm step={step} setStep={setStep} submitHandler={handleSubmit}>
      <div className="section-header">
        <img src={siteImageIcon} />
        <h2 className="section-heading">Site Images</h2>
      </div>
      <h4 className="section-sub-heading">Site Images</h4>
      <div className="row">
        <div className="col-lg-12">
          <div className="mb-3">
            <FormGroup>
              <label className="form-label">Please upload images/pictures of the site</label>
              <WrappedFileInput
                {...register('siteImages')}
                initialLabel={stepValues?.siteImages?.[0]?.name}
                name={'siteImages'}
                accept="image/*,application/pdf"
              />
            </FormGroup>
          </div>
        </div>
      </div>
      <h4 className="section-sub-heading">Site Plans</h4>
      <div className="row">
        <div className="col-lg-12">
          <div className="mb-3">
            <label className="form-label">Please upload interior (inside) site plans</label>
            <WrappedFileInput
              {...register('interiorSitePlans')}
              initialLabel={stepValues?.interiorSitePlans?.[0]?.name}
              type="file"
              name={'interiorSitePlans'}
              accept="image/*,application/pdf"
            />
          </div>
        </div>
        <div className="col-lg-12">
          <div className="mb-3">
            <label className="form-label">Please upload exterior (outside) site plans</label>
            <WrappedFileInput
              {...register('exteriorSitePlans')}
              initialLabel={stepValues?.exteriorSitePlans?.[0]?.name}
              type="file"
              name={'exteriorSitePlans'}
              accept="image/*,application/pdf"
            />
          </div>
        </div>
      </div>
    </GenericStepForm>
  );
}

export default SiteImageStep;
