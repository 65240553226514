import { Link } from 'react-router-dom';
import { useAppState } from '../../hooks/useAppState';

import ptBuildingsIcon from '../../assets/images/pt-buildings.svg';
import projectType from '../../assets/images/project-type.svg';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { useForm } from 'react-hook-form';
import StepActions from './step-actions';
import { WrappedInput } from '../../components/WrappedInput';
import { mergeObjs } from '../../utils/ObjectUtils';
import GenericStepForm from './generic-step-form';
import { useEffect } from 'react';

function ProjectTypeStep({ step, setStep }) {
  const [stepValues, setStepValues] = useAppState();

  const defaultValues = { projType: 'projectval', devType: 'new', is_truck_stop: 'false', type_of_truck_stop: '' };

  const { setValue, getValues, register, setError, handleSubmit, formState, watch, reset } = useForm({
    defaultValues: mergeObjs(stepValues, defaultValues),
    mode: 'onSubmit',
  });
  const { errors } = formState;
  const is_truck_stop = watch('is_truck_stop');
  useEffect(() => {
    if (is_truck_stop === 'true') {
      setValue('type_of_truck_stop', 'basic');
    }
    if (is_truck_stop === 'false') {
      setValue('type_of_truck_stop', '');
    }
  }, [is_truck_stop]);
  return (
    <GenericStepForm step={step} setStep={setStep} submitHandler={handleSubmit} disablePrevious={true}>
      <img className="project-type-wrapper" src={ptBuildingsIcon} alt={''} />
      <div className="project-type-main">
        <div className="section-header">
          <img src={projectType} alt={''} />
          <h2 className="section-heading">Project Type</h2>
        </div>
        <div className="section-para">
          <p>
            The project type you have selected is{' '}
            <strong>“{stepValues.facType.charAt(0).toUpperCase() + stepValues.facType.slice(1)}”</strong>. This wizard
            will guide you and take step by step requirements. As you progress through the wizard, you'll be able to see
            a summary of your selections and progress at each stage.
          </p>
          <p>
            Once you've completed all of the steps in the wizard, you'll have a comprehensive plan for your commercial
            project that includes all of the necessary details and estimated costs. Please proceed ...
          </p>
        </div>
        {/* <h4 className="section-sub-heading">Services</h4>
        <FormGroup check>
          <Label check>
            <WrappedInput {...register('projType')} type="radio" name={'projType'} value={'valuation'} />
            Valuation
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            <WrappedInput {...register('projType')} value={'projection'} type="radio" name={'projType'} />
            Projection
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            <WrappedInput {...register('projType')} value={'projectval'} type="radio" name={'projType'} />
            Projection and Valuation
          </Label>
        </FormGroup> */}
        <h4 className="section-sub-heading">Development Type</h4>
        <FormGroup check>
          <Label check>
            <WrappedInput {...register('devType')} value={'new'} type="radio" name={'devType'} />
            New Site Development
          </Label>
        </FormGroup>
        <FormGroup check>
          <Input type="radio" name="devType" />
          <Label check>
            <WrappedInput {...register('devType')} value={'existing'} type="radio" name={'devType'} />
            Existing Site Development
          </Label>
        </FormGroup>
        {/* Gas Station Type */}
        <h4 className="section-sub-heading">Type of Gas Station</h4>
        <FormGroup check>
          <Label check>
            <WrappedInput {...register('is_truck_stop')} value={false} type="radio" name={'is_truck_stop'} />
            Regular Gas Station
          </Label>
        </FormGroup>
        <FormGroup check>
          <Input type="radio" name="is_truck_stop" />
          <Label check>
            <WrappedInput {...register('is_truck_stop')} value={true} type="radio" name={'is_truck_stop'} />
            Truck Stop
          </Label>
        </FormGroup>
        {/* Type of Truck Stop */}
        {is_truck_stop === 'true' && (
          <>
            <h4 className="section-sub-heading">Type of Truck Stop</h4>
            <FormGroup check>
              <Label check>
                <WrappedInput
                  {...register('type_of_truck_stop')}
                  value={'basic'}
                  type="radio"
                  name={'type_of_truck_stop'}
                />
                Basic Truck Stop
              </Label>
            </FormGroup>
            <FormGroup check>
              <Input type="radio" name="type_of_truck_stop" />
              <Label check>
                <WrappedInput
                  {...register('type_of_truck_stop')}
                  value={'full_service'}
                  type="radio"
                  name={'type_of_truck_stop'}
                />
                Full Service Truck Stop
              </Label>
            </FormGroup>
            <FormGroup check>
              <Input type="radio" name="type_of_truck_stop" />
              <Label check>
                <WrappedInput
                  {...register('type_of_truck_stop')}
                  value={'premium'}
                  type="radio"
                  name={'type_of_truck_stop'}
                />
                Premium Truck Stop with Maintenance Facility
              </Label>
            </FormGroup>
          </>
        )}
      </div>
    </GenericStepForm>
  );
}

export default ProjectTypeStep;
