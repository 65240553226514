// src/services/questionsService.js

import axiosInstance, { axiosUnSecureInstance } from './axio-service';

class QuestionsService {
  getQuestions() {
    return axiosUnSecureInstance('questions/', {
      method: 'GET',
    });
  }

  submitAnswers(data, id) {
    return axiosInstance('submit-answers/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      data,
    });
  }

  logIn(data) {
    return axiosUnSecureInstance('accounts/login/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    });
  }

  signup(data) {
    return axiosUnSecureInstance('/accounts/signup/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    });
  }

  suitablityGeometry(data) {
    return axiosUnSecureInstance('suitability-geometry/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    });
  }

  getSubmittedAnswers(id) {
    return axiosInstance(`get-submit-answers/?object_id=${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    });
  }

  suitabilityAnalysisData(id, data) {
    console.log('payload', id, data);
    return axiosInstance(`suitability-analysis/?id=${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
}

const questionsService = new QuestionsService();
export { questionsService };
