import { Link } from 'react-router-dom';

import loginIcon from '../assets/images/login.png';
import signUpIcon from '../assets/images/signup.png';
import SubHeader from './sub-header';
import CopyRightComponent from './copyright';
import { useState } from 'react';
import Privacy from '../components/privacy';

function GettingStarted() {
  const [isModal, setIsModal] = useState(false);
  return (
    <div>
      <Privacy isModal={isModal} setIsModal={setIsModal} />

      <section className="get-started-banner">
        <SubHeader />

        <div className="get_started_main">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="section-title dark-bg section-title-width" style={{ marginBottom: '45px' }}>
                  <h3 className="wow fadeInUp" data-wow-delay=".2s">
                    Welcome to Faster and
                  </h3>
                  <h2 className="dot wow fadeInUp" data-wow-delay=".4s">
                    Easier Data Insights
                  </h2>
                  <p className="wow fadeInUp" data-wow-delay=".6s">
                    Whether you’re looking for a fast, accurate valuation on your commercial property or to expand your
                    current business and need critical geographic and demographic insights, Tezintel’s powerful
                    algorithms return the data you need right here, right now
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="section-title dark-bg section-title-width">
                  <h3 className="wow fadeInUp" data-wow-delay=".2s">
                    Get Your Report
                  </h3>
                  <h2 className="dot wow fadeInUp" data-wow-delay=".4s">
                    In 10 Minutes
                  </h2>
                  <p className="wow fadeInUp" data-wow-delay=".6s">
                    Start your valuation and projection project or run both simultaneously here
                  </p>
                  <div className="button wow fadeInUp" data-wow-delay=".8s">
                    <Link
                      className="btn btn-icon get-started-icon"
                      onClick={() => {
                        setIsModal(true);
                      }}
                    >
                      Get My Valuation
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CopyRightComponent />
    </div>
  );
}

export default GettingStarted;
