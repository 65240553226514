import React from 'react';
import { useWatch } from 'react-hook-form';
import { Row, Col, Input } from 'reactstrap';

import './Slider.css';
import { WrappedInput } from './WrappedInput';

const RangeSliderWithTooltip = (props) => {
  const value = useWatch({
    control: props.control,
    name: props.name,
  });

  return (
    <>
      <Row>
        <Col xs="12" className="slider-container">
          <WrappedInput {...props.register(props.name)} type="range" {...props} />
        </Col>
      </Row>
      <Row>
        <Col xs="12" className="text-center">
          <label className={'fw-bold'}>{value <1000 ? value +" ft": value+"+"+" ft"} </label>
        </Col>
      </Row>
    </>
  );
};

export default RangeSliderWithTooltip;
