import { useAppState } from '../../hooks/useAppState';

import leadIcon from '../../assets/images/project-type.svg';
import { Form, FormGroup, Label } from 'reactstrap';
import { WrappedInput } from '../../components/WrappedInput';
import { useForm } from 'react-hook-form';
import StepActions from './step-actions';
import axios from 'axios';
import { useState } from 'react';

import { URL_CONSTANTS } from '../../store/constant';
import axioService from '../../services/axio-service';
import axiosInstance from '../../services/axio-service';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { useAuth } from '../../hooks/useAuth';
import GenericStepForm from './generic-step-form';

function LeadStep({ step, setStep }) {
  const [stepValues, setStepValues] = useAppState();

 
  const { user } = useAuth();

  const [leadsData, setLeadsData] = useState(null);

  const { register, setError, handleSubmit, formState, watch, reset, setValue } = useForm({
    defaultValues: { ...stepValues, ...{ isSendReport: 'True' } },
    mode: 'onSubmit',
  });
  const { errors } = formState;

  const isLeadsValue = watch('isLeads', 'True');

  //   make leadsDetailsArr empty if isLeadsValue is false if  isLeadsValue is true leadsDetailsArr  is not in stepValues then show all checkboxes
  // other wise selected checkboxes
  useEffect(() => {
    if (isLeadsValue == 'False') {
      setValue('leadsDetailsArr', []);
    } else {
      if (!stepValues.leadsDetailsArr) {
        let data = [];
        if (leadsData) {
          for (let index = 0; index < leadsData.length; index++) {
            let name = leadsData[index].name;
            data.push(name);
          }

          setValue('leadsDetailsArr', data);
          setValue('leadValue', leadsData);
        }
      } else {
        setValue('leadsDetailsArr', stepValues.leadsDetailsArr);
        setValue('leadValue', leadsData);
      }
    }
  }, [isLeadsValue, leadsData]);

  useEffect(() => {
    const getBuisnessLeads = async () => {
      try {
        const res = await axioService.get('businesstype/');

        setLeadsData(res?.data);
      } catch (error) {
       }
    };
    getBuisnessLeads();
  }, []);

  return (
    <div>
      <section>
        <GenericStepForm step={step} setStep={setStep} submitHandler={handleSubmit}>
          <div className="section-header">
            <img src={leadIcon} alt={''} />
            <h2 className="section-heading">Leads</h2>
          </div>
          <h4 className="section-sub-heading">Help/Lead Generation</h4>

          <div className="mb-3">
            <p className="mb-4 text-12">
              Do you need help in getting financing, finding the right general contractor, broker, appraiser or any
              other needs?
            </p>
            <FormGroup check inline>
              <Label check>
                <WrappedInput
                  defaultChecked={stepValues.isLeads == null || stepValues.isLeads == ''}
                  {...register('isLeads')}
                  value={'True'}
                  type="radio"
                  name={'isLeads'}
                />
                <label>Yes</label>
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Label check>
                <WrappedInput {...register('isLeads')} value={'False'} type="radio" name={'isLeads'} />
                <label>No</label>
              </Label>
            </FormGroup>
          </div>
          <div className={`mb-3 ${isLeadsValue === 'True' ? 'd-block' : 'd-none'}`}>
            <p className="mb-4 text-12">
              We can help you connect with the right organizations faster! We can send over your report and have the
              right organizations or individuals contact you directly Please check off which services you need help:
            </p>
            <div className="row leads-check">
              {leadsData?.map((item) => (
                <div className="col-lg-4">
                  <div className="mb-2">
                    <FormGroup check inline>
                      <Label check>
                        <WrappedInput
                          {...register('leadsDetailsArr')}
                          defaultChecked={isLeadsValue == 'True' ? true : false}
                          value={item?.name}
                          type="checkbox"
                          name={'leadsDetailsArr'}
                        />
                        <label>{item?.name}</label>
                      </Label>
                    </FormGroup>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={`mb-3 ${isLeadsValue === 'True' ? 'd-block' : 'd-none'}`}>
            {/* <p className="mb-4 mt-3 text-12">
                Do you authorize us to send your report to the recommended organizations that can help you with your
                needs?
              </p> */}
            <FormGroup check inline>
              <Label check>
                <WrappedInput {...register('isSendReport')} value={'True'} type="checkbox" name={'isSendReport'} />
                <label>Do you authorize</label>
              </Label>
            </FormGroup>
          </div>
        </GenericStepForm>
      </section>
    </div>
  );
}

export default LeadStep;
