import { useState, useEffect } from 'react';
import { Button, Col, Form, FormFeedback, Label, Modal, ModalBody, Row, TabContent, TabPane } from 'reactstrap';
import { WrappedInput } from './WrappedInput';
import { useForm } from 'react-hook-form';
import { useAuth } from '../hooks/useAuth';
import Emitter from './Emitter';
import axios from 'axios';
import { URL_CONSTANTS } from '../store/constant';
import { toast } from 'react-toastify';

function ForgotPassword() {
  const { register, setError, handleSubmit, formState, reset, trigger, watch } = useForm();
  const { errors } = formState;

  const [isLoading, setIsLoading] = useState(false);

  const { sendPasswordResetEmail } = useAuth();

  const forgotPasswordSubmit = (data) => {
    setIsLoading(true);

    sendPasswordResetEmail(data)
      .then((e) => {
        reset();
        Emitter.emit('password_reset');
      })
      .catch(function (error) {
        const errors = error.response.data;
        Object.keys(errors).forEach(function (key) {
          setError(key, { type: 'custom', message: errors[key] }, { shouldFocus: true });
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  let email = watch('email');

  useEffect(() => {
    if (email !== 'undefined') {
      trigger('email');
    }
  }, [email]);

  return (
    <div>
      <Form onSubmit={handleSubmit(forgotPasswordSubmit)} className=" mx-5 p-3">
        <h5 className="text-center mb-2">Reset Password</h5>
        <Row className="mb-3">
          <Col>
            <WrappedInput
              type="email"
              name="email"
              placeholder="Please Enter Registered Email"
              id="email"
              onChange={(e) => {
                setValue('email', e.target.value);
              }}
              onFocus={() => {
                trigger('email');
              }}
              onBlur={() => {
                trigger('email');
              }}
              invalid={errors?.email}
              {...register('email', {
                required: 'Email is required',
                pattern: {
                  value: /^[A-Za-z][A-Za-z0-9._%+-]*@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
                  message: 'Please enter a valid email address',
                },
              })}
            />
            {errors?.email && <FormFeedback> {errors?.email?.message}</FormFeedback>}
          </Col>
        </Row>

        <div className="text-center mt-1">
          <Button type="submit" className={'w-100  '} style={{ background: '#00ABFF', border: 'none' }}>
            {isLoading && <span className="spinner-border spinner-border-sm mr-1"></span>}
            Reset password
          </Button>
        </div>

        {/* <div className="text-center mt-3">
              <Button
                type="submit"
                className={"w-100 loginBtn"}
                disabled={isSubmitting}
              >
                {isSubmitting && (
                  <span className="spinner-border spinner-border-sm mr-1"></span>
                )}
                Login
              </Button>
            </div> */}
      </Form>
    </div>
  );
}

export default ForgotPassword;
