import { Link } from 'react-router-dom';

import { useAppState } from '../../hooks/useAppState';
import FirstStep from './first-step';
import ApartmentSteps from './apartment-steps';
import CommercialSteps from './commercial-steps';
import { useEffect } from 'react';
import GrocerySteps from './grocerysteps/apartment-steps';

function SecondStep({ step, setStep }) {
  const [stepValues, setStepValues] = useAppState();

  useEffect(() => {
    setStepValues({ ...stepValues, ...{ stepStatus: [] } });
  }, []);

  return (
    <div>
      {stepValues.facType === 'residential' && <ApartmentSteps />}
      {stepValues.facType === 'commercial' && <CommercialSteps />}
      {stepValues.facType === 'grocery' && <GrocerySteps />}
      
    </div>
  );
}

export default SecondStep;
