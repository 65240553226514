import React from 'react';
import SubHeader from '../pages/sub-header';
import myprofilelogo from '../assets//images/myprofile-logo.png';
import editsymbol from '../assets/images/editsymbol.png';
import dot from '../assets/images/dot.png';
import { Input } from 'reactstrap';
import searchicon from '../assets/images/vendor/searchicon.png';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const Vendor = () => {
  return (
    <>
      <SubHeader />
      <section className=" get-vendor">
        <div className="container w-auto">
          <div className="row align-items-center">
            <div className="col-md-12 col-12">
              <div className="hero-content">
                <span
                  className="wow fadeInUp vendorheading"
                  data-wow-delay=".2s"
                  style={{
                    color: '#FFFFFF',
                    fontSize: '44px',
                    fontWeight: 600,
                  }}
                >
                  Trusted Vendors
                </span>
                <p
                  className="vendorpara"
                  data-wow-delay=".4s"
                  style={{
                    color: '#FFFFFF',
                    fontSize: '16px',
                    fontWeight: 600,
                  }}
                >
                  Explore the list of approved vendors
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className=''>
        <div className="container mt-3 vendorsearch">
          <div>
          <p
            className="vendorpara"
            style={{
              color: '#243140',
              fontSize: '16px',
              fontWeight: 600,
              
            }}
          >
            Search your required service and we will connect you to the right vendor
          </p>
          </div>
          <div className=" searchbar">
            <Input className="custom-input" type="text" placeholder="Construction e,g" />
            <img
              src={searchicon}
              className="searchicon"
            />
          </div>
        </div>
        <div className="container mt-3 vendortagscontainer">
          <div
            className="mb-5 vendortags "
            style={{
              width: '373PX',
              height: '100px',
              backgroundColor: '#EEF3F4',
            }}
          >
            <div className="flex profileimages ">
              <div className="profile-pictureVendor">
                <img src={myprofilelogo} />
              </div>

              <div
                className="mt-3 flex "
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingLeft: '17px',
                  color: '#243140',
                }}
              >
                <h6
                  style={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  Mastersons
                </h6>
                <h6
                  style={{
                    fontSize: '14px',
                    fontWeight: '400',
                    color: '#9BA2A8',
                    textAlign: 'start',
                    marginTop: '4px',
                  }}
                >
                  Construction
                </h6>
              </div>
            </div>
          </div>
          <div
            className="mb-5 vendortags "
            style={{
              width: '373PX',
              height: '100px',
              backgroundColor: '#EEF3F4',
            }}
          >
            <div className="flex profileimages ">
              <div className="profile-pictureVendor">
                <img src={myprofilelogo} />
              </div>

              <div
                className="mt-3 flex "
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingLeft: '17px',
                  color: '#243140',
                }}
              >
                <h6
                  style={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  Beaver Builders
                </h6>
                <h6
                  style={{
                    fontSize: '14px',
                    fontWeight: '400',
                    color: '#9BA2A8',
                    textAlign: 'start',
                    marginTop: '4px',
                  }}
                >
                  Locksmith
                </h6>
              </div>
            </div>
          </div>
          <div
            className="mb-5 vendortags "
            style={{
              width: '373PX',
              height: '100px',
              backgroundColor: '#EEF3F4',
            }}
          >
            <div className="flex profileimages ">
              <div className="profile-pictureVendor">
                <img src={myprofilelogo} />
              </div>

              <div
                className="mt-3 flex "
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingLeft: '17px',
                  color: '#243140',
                }}
              >
                <h6
                  style={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  Cool Structures
                </h6>
                <h6
                  style={{
                    fontSize: '14px',
                    fontWeight: '400',
                    color: '#9BA2A8',
                    textAlign: 'start',
                    marginTop: '4px',
                  }}
                >
                  Construction
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className="container vendortagscontainer">
          <div
            className="mb-5 vendortags "
            style={{
              width: '373PX',
              height: '100px',
              backgroundColor: '#EEF3F4',
            }}
          >
            <div className="flex profileimages ">
              <div className="profile-pictureVendor">
                <img src={myprofilelogo} />
              </div>

              <div
                className="mt-3 flex "
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingLeft: '17px',
                  color: '#243140',
                }}
              >
                <h6
                  style={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  ProBlue Contractors
                </h6>
                <h6
                  style={{
                    fontSize: '14px',
                    fontWeight: '400',
                    color: '#9BA2A8',
                    textAlign: 'start',
                    marginTop: '4px',
                  }}
                >
                  Construction
                </h6>
              </div>
            </div>
          </div>
          <div
            className="mb-5 vendortags "
            style={{
              width: '373PX',
              height: '100px',
              backgroundColor: '#EEF3F4',
            }}
          >
            <div className="flex profileimages ">
              <div className="profile-pictureVendor">
                <img src={myprofilelogo} />
              </div>

              <div
                className="mt-3 flex "
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingLeft: '17px',
                  color: '#243140',
                }}
              >
                <h6
                  style={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  Done Right
                </h6>
                <h6
                  style={{
                    fontSize: '14px',
                    fontWeight: '400',
                    color: '#9BA2A8',
                    textAlign: 'start',
                    marginTop: '4px',
                  }}
                >
                  Plumbing
                </h6>
              </div>
            </div>
          </div>
          <div
            className="mb-5 vendortags "
            style={{
              width: '373PX',
              height: '100px',
              backgroundColor: '#EEF3F4',
            }}
          >
            <div className="flex profileimages ">
              <div className="profile-pictureVendor">
                <img src={myprofilelogo} />
              </div>

              <div
                className="mt-3 flex"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingLeft: '17px',
                  color: '#243140',
                }}
              >
                <h6
                  style={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  Clean Cut Builders
                </h6>
                <h6
                  style={{
                    fontSize: '14px',
                    fontWeight: '400',
                    color: '#9BA2A8',
                    textAlign: 'start',
                    marginTop: '4px',
                  }}
                >
                  Flooring
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className="container vendortagscontainer">
          <div
            className="mb-5 vendortags "
            style={{
              width: '373PX',
              height: '100px',
              backgroundColor: '#EEF3F4',
            }}
          >
            <div className="flex profileimages ">
              <div className="profile-pictureVendor">
                <img src={myprofilelogo} />
              </div>

              <div
                className="mt-3 flex "
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingLeft: '17px',
                  color: '#243140',
                }}
              >
                <h6
                  style={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  Clayco
                </h6>
                <h6
                  style={{
                    fontSize: '14px',
                    fontWeight: '400',
                    color: '#9BA2A8',
                    textAlign: 'start',
                    marginTop: '4px',
                  }}
                >
                  Flooring
                </h6>
              </div>
            </div>
          </div>
          <div
            className="mb-5 vendortags "
            style={{
              width: '373PX',
              height: '100px',
              backgroundColor: '#EEF3F4',
            }}
          >
            <div className="flex profileimages ">
              <div className="profile-pictureVendor">
                <img src={myprofilelogo} />
              </div>

              <div
                className="mt-3 flex "
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingLeft: '17px',
                  color: '#243140',
                }}
              >
                <h6
                  style={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  Dewson
                </h6>
                <h6
                  style={{
                    fontSize: '14px',
                    fontWeight: '400',
                    color: '#9BA2A8',
                    textAlign: 'start',
                    marginTop: '4px',
                  }}
                >
                  Flooring
                </h6>
              </div>
            </div>
          </div>
          <div
            className="mb-5 vendortags "
            style={{
              width: '373PX',
              height: '100px',
              backgroundColor: '#EEF3F4',
            }}
          >
            <div className="flex profileimages ">
              <div className="profile-pictureVendor">
                <img src={myprofilelogo} />
              </div>

              <div
                className="mt-3 flex "
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingLeft: '17px',
                  color: '#243140',
                }}
              >
                <h6
                  style={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  GreenNest
                </h6>
                <h6
                  style={{
                    fontSize: '14px',
                    fontWeight: '400',
                    color: '#9BA2A8',
                    textAlign: 'start',
                    marginTop: '4px',
                  }}
                >
                  Security System
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className="container vendortagscontainer">
          <div
            className="mb-5 vendortags "
            style={{
              width: '373PX',
              height: '100px',
              backgroundColor: '#EEF3F4',
            }}
          >
            <div className="flex profileimages ">
              <div className="profile-pictureVendor">
                <img src={myprofilelogo} />
              </div>

              <div
                className="mt-3 flex "
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingLeft: '17px',
                  color: '#243140',
                }}
              >
                <h6
                  style={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  Juneau
                </h6>
                <h6
                  style={{
                    fontSize: '14px',
                    fontWeight: '400',
                    color: '#9BA2A8',
                    textAlign: 'start',
                    marginTop: '4px',
                  }}
                >
                  Security System
                </h6>
              </div>
            </div>
          </div>
          <div
            className="mb-5 vendortags "
            style={{
              width: '373PX',
              height: '100px',
              backgroundColor: '#EEF3F4',
            }}
          >
            <div className="flex profileimages ">
              <div className="profile-pictureVendor">
                <img src={myprofilelogo} />
              </div>

              <div
                className="mt-3 flex "
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingLeft: '17px',
                  color: '#243140',
                }}
              >
                <h6
                  style={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  Keystone
                </h6>
                <h6
                  style={{
                    fontSize: '14px',
                    fontWeight: '400',
                    color: '#9BA2A8',
                    textAlign: 'start',
                    marginTop: '4px',
                  }}
                >
                  Security System
                </h6>
              </div>
            </div>
          </div>
          <div
            className="mb-5 vendortags "
            style={{
              width: '373PX',
              height: '100px',
              backgroundColor: '#EEF3F4',
            }}
          >
            <div className="flex profileimages ">
              <div className="profile-pictureVendor">
                <img src={myprofilelogo} />
              </div>

              <div
                className="mt-3 flex "
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingLeft: '17px',
                  color: '#243140',
                }}
              >
                <h6
                  style={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  Level Up Quality
                </h6>
                <h6
                  style={{
                    fontSize: '14px',
                    fontWeight: '400',
                    color: '#9BA2A8',
                    textAlign: 'start',
                    marginTop: '4px',
                  }}
                >
                  Locksmith
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className="container vendortagscontainer">
          <div
            className="mb-5 vendortags "
            style={{
              width: '373PX',
              height: '100px',
              backgroundColor: '#EEF3F4',
            }}
          >
            <div className="flex profileimages ">
              <div className="profile-pictureVendor">
                <img src={myprofilelogo} />
              </div>

              <div
                className="mt-3 flex "
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingLeft: '17px',
                  color: '#243140',
                  justifyContent: 'center',
                  alignItems: 'center',
                  
                }}
              >
                <h6
                  style={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  NorthWood Resources
                </h6>
                <h6
                  style={{
                    fontSize: '14px',
                    fontWeight: '400',
                    color: '#9BA2A8',
                    textAlign: 'start',
                    marginTop: '4px',
                  }}
                >
                  Locksmith
                </h6>
              </div>
            </div>
          </div>
          <div
            className="mb-5 vendortags "
            style={{
              width: '373PX',
              height: '100px',
              backgroundColor: '#EEF3F4',
            }}
          >
            <div className="flex profileimages ">
              <div className="profile-pictureVendor">
                <img src={myprofilelogo} />
              </div>

              <div
                className="mt-3 flex "
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingLeft: '17px',
                  color: '#243140',
                }}
              >
                <h6
                  style={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  Onyx General
                </h6>
                <h6
                  style={{
                    fontSize: '14px',
                    fontWeight: '400',
                    color: '#9BA2A8',
                    textAlign: 'start',
                    marginTop: '4px',
                  }}
                >
                  Locksmith
                </h6>
              </div>
            </div>
          </div>
          <div
            className="mb-5 vendortags "
            style={{
              width: '373PX',
              height: '100px',
              backgroundColor: '#EEF3F4',
            }}
          >
            <div className="flex profileimages ">
              <div className="profile-pictureVendor">
                <img src={myprofilelogo} />
              </div>

              <div
                className="mt-3 flex "
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingLeft: '17px',
                  color: '#243140',
                }}
              >
                <h6
                  style={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  RediNex Resources
                </h6>
                <h6
                  style={{
                    fontSize: '14px',
                    fontWeight: '400',
                    color: '#9BA2A8',
                    textAlign: 'start',
                    marginTop: '4px',
                  }}
                >
                  Locksmith
                </h6>
              </div>
            </div>
          </div>
        </div>
        
        <div className="container vendorpagination">
          {/* <Pagination className="vendorpagination" aria-label="Page navigation example">
            <PaginationItem>
              <PaginationLink previous href="#" />
            </PaginationItem>
            <PaginationItem>
              <PaginationLink href="#">1</PaginationLink>
            </PaginationItem>
            <PaginationItem>
              <PaginationLink href="#">2</PaginationLink>
            </PaginationItem>

            <PaginationItem>
              <PaginationLink next href="#" />
            </PaginationItem>
          </Pagination> */}
        </div>
      </section>

      <section className="mt-3 get-started">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title dark-bg">
                <h2 className="wow fadeInUp" data-wow-delay=".4s">
                  Ready to Get the Insights You Need to Grow Your Business Today?
                </h2>
                <p className="wow fadeInUp" data-wow-delay=".6s">
                  Use our cutting-edge valuation and projection model to get the data you need to grow in minutes.
                </p>
                <div className="button wow fadeInUp" data-wow-delay=".8s">
                  <a href="#" className="btn btn-icon icon-home">
                    GET MY INSIGHTS
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Vendor;
