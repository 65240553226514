import React, { useEffect, useState } from 'react';
import dot from '../assets/images/dot.png';
import { useNavigate, useParams } from 'react-router-dom';
import arrowicon from '../assets/images/vendor/arrow.png';
import CopyRightComponent from './copyright';
import MyProfile from '../components/UserNavbar';
import axios from 'axios';
import axiosInstance from '../services/axio-service';

const LeadsDetail = () => {
  const { id ,read} = useParams();
   const [response, setResponse] = useState(null);
  const navigate = useNavigate();

  const getleads = async () => {
    try {
      const response = await axiosInstance.get(`/vendorleads/${id}`);
      setResponse(response);
      
      const countResponse = await axiosInstance.get(`/vendorscount/${id}`);
      
 
     } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    getleads();
  }, [id]);

  return (
    <>
      <MyProfile />

      <div className="container leadsdetail">
        <div className="leadsitem">
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
            }}
          >
            <div
              className="leadpararesp mt-3  "
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div className="leadicontxt">
                <span
                  style={{
                    fontSize: '24px',
                    fontWeight: 700,
                  }}
                >
                  Leads
                </span>

                <img src={dot} alt="edit" className="dotimgvendor" />
              </div>
              <div>
                <p
                  style={{
                    paddingLeft: '10px',
                    marginTop: '20px',
                    fontSize: '16px',
                    fontWeight: 600,
                    color: '#96A5B6',
                  }}
                >
                  Exciting news, dear vendor! A potential customer has shown interest, bringing you a new lead
                </p>
              </div>
            </div>
          </div>
          <div>
            <button
              className="leadbutton"
              onClick={() => {
                navigate('/leads');
              }}
            >
              All Leads
            </button>
            <button
              className=" "
              onClick={() => {
                // navigate('/leads');
                response?.data?.report_path &&   window.open(process.env.REACT_APP_SERVER_BASE_URL + response?.data?.report_path, '_blank', 'noreferrer');

              }}
            >
              Download Lead
            </button>
            
          </div>
        </div>

        <div
          style={{
            color: '#D1DFE7',
            marginLeft: '-110px',
            marginRight: '-92px',
            marginTop: '0px',
          }}
        >
          <hr />
        </div>
        <div
          className="mt-2 leadsinfo "
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <h1 className="leadheader">Dear Vendor</h1>
          <p
            style={{
              paddingTop: '20px',
              display: 'flex',
              justifyContent: 'start',
              textAlign: 'start',
            }}
            className="leadtext"
          >
            We hope this message finds you well. We're thrilled to inform you about a new lead that has the potential to
            open doors for your <br /> construction business. Here are the full details:
          </p>
          <p className="mt-3 leadtext">
            {' '}
            <span className="leadstyle">Lead ID:</span> {id}
          </p>
          <p className=" leadtext">
            {' '}
            <span className="leadstyle">City:</span> {response?.data?.city}
          </p>
          <p className="leadtext">
            {' '}
            <span className="leadstyle">Address:</span> {response?.data?.address}
          </p>
          <p className="leadtext">
            {' '}
            <span className="leadstyle">Lead-Name:</span>
            {response?.data?.lead_name}
          </p>
          <p className="leadtext">
            {' '}
            <span className="leadstyle">Area:</span>
            {response?.data?.total_area_sqft}
          </p>
          <p className="leadtext">
            {' '}
            <span className="leadstyle">Zip code:</span>
            {response?.data?.zip_code}
          </p>

          <p className="leadtext">
            {' '}
            <span className="leadstyle">Email:</span> {response?.data?.email}
          </p>
        </div>
        <div className="mt-3 leadsinfo ">
          <h1 className="leadheader">Action Steps</h1>
          <div
            style={{
              display: 'flex',
              marginTop: '20px',
            }}
          >
            <img src={arrowicon} alt="edit" className="leadicon" />
            <p className="leadtext">Contact the lead to discuss their requirements and expectations.</p>
          </div>
          <div
            style={{
              display: 'flex',
            }}
          >
            <img src={arrowicon} alt="edit" className="leadicon" />
            <p className="leadtext">Evaluate the feasibility of the project based on the provided details.</p>
          </div>
          <div
            style={{
              display: 'flex',
            }}
          >
            <img src={arrowicon} alt="edit" className="leadicon" />
            <p className="leadtext">Provide a preliminary cost estimate and timeline for the project.</p>
          </div>
          <div
            style={{
              display: 'flex',
            }}
          >
            <img src={arrowicon} alt="edit" className="leadicon" />
            <p className="leadtext">Schedule a follow-up meeting to explore the project further.</p>
          </div>

          <div className="mt-2 leadtext">
            <p>
              Feel free to reach out to the lead at your earliest convenience to initiate a conversation and learn more
              about their needs. Should you have any questions or require assistance, please don't hesitate to contact
              us.{' '}
            </p>
            <p
              style={{
                marginTop: '25px',
              }}
            >
              {' '}
              Wishing you success in turning this lead into a valuable opportunity for your construction business!
            </p>
          </div>
          <div className="mt-3 leadtext">
            <p
              style={{
                fontWeight: 700,
                fontSize: '12px',
              }}
            >
              Best Regards,
            </p>
            <p>TEZINTEL Management</p>
            <p>
              {' '}
              <span
                style={{
                  fontWeight: 700,
                  fontSize: '12px',
                }}
              >
                Email:
              </span>{' '}
              info@tezintel.com
            </p>
            <p style={{ marginTop: '-15px' }}>
              <span
                style={{
                  fontWeight: 700,
                  fontSize: '12px',
                }}
              >
                Phone:{' '}
              </span>{' '}
              +1-212-456-7890
            </p>
          </div>
        </div>
      </div>
      <div>
        <CopyRightComponent />
      </div>
    </>
  );
};

export default LeadsDetail;
