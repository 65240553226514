import React from 'react';
import { Nav, NavItem, NavLink, Navbar, NavbarBrand } from 'reactstrap';
import img from '../assets/images/logo/logo.png';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import Emitter from './Emitter';
import { useAuth } from '../hooks/useAuth';
function MyProfile() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isSuperUser } = useAuth();

  return (
    <>
      <div
        className=""
        style={{
          backgroundColor: '#384957',
          color: 'white',
          fontSize: '14px',
          fontWeight: 'semibold',
          fontFamily: 'Arial',
          paddingTop: '10px',
          paddingBottom: '10px',
        }}
      >
        <Nav className="justify-content-between container  ">
          <div>
            <img
              src={img}
              alt="img"
              width={176}
              height={39}
              style={{
                cursor: 'pointer',
              }}
              className="navbar_profile_img"
              onClick={() => {
                navigate('/');
              }}
            />
          </div>
          <div className="navItemProfile">
            {!isSuperUser && (
              <NavItem>
                <Link to="/profile">
                  <NavLink style={{}} as={Link} className={pathname === '/profile' ? 'Nav-item active' : 'Nav-item'}>
                    My Profile
                  </NavLink>
                </Link>
              </NavItem>
            )}
            {!isSuperUser && (
              <NavItem>
                <Link to={'/leads'}>
                  <NavLink
                    style={{
                      fontSize: '14px',
                    }}
                    className={pathname === '/leads' ? 'Nav-item active' : 'Nav-item'}
                  >
                    Leads
                  </NavLink>
                </Link>
              </NavItem>
            )}
            {isSuperUser && (
              <NavItem>
                 
                  <NavLink
                    style={{
                      fontSize: '14px',
                    }}
                    className={pathname === '/vendor' ? 'Nav-item active cursor-pointer' : ' cursor-pointer Nav-item'}
                 
                    onClick={() => {
                      window.open(
                        process.env.REACT_APP_SERVER_BASE_URL + '/api/admindashboard',
                        '_blank',
                        'noreferrer'
                      );
                    }}>
                    Admin Dashboard
                  </NavLink>
                 
              </NavItem>
            )}

            <NavItem>
              <NavLink
                style={{
                  fontSize: '14px',
                }}
                className="Nav-item cursor-pointer"
                onClick={(e) => {
                  Emitter.emit('signOut');
                  navigate('/');
                  e.preventDefault();
                  e.stopPropagation();
                  return false;
                }}
              >
                Logout
              </NavLink>
            </NavItem>
          </div>
        </Nav>
      </div>
    </>
  );
}

export default MyProfile;
