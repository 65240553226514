import React, { createContext, useState, useContext } from 'react';

import { questionsService } from '../services/projects';
import { useAsyncFn, useAsync } from './useAsync';

// Create a context
const ServiceContext = createContext();

// Create a provider component
export const ServiceProvider = ({ children }) => {
  const [selectedService, setSelectedService] = useState('');
  const [loginLoading, setLoginLoading] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const [lngLat, setLngLat] = useState(null);
  const [selectedButtons, setSelectedButtons] = useState([]);

  const {
    value: questions,
    loading: loadingForQuestions,
    error: errorForQuestions,
  } = useAsync(() => questionsService.getQuestions(), []);
  const {
    value: submittedAnswers,
    loading: loadingForSubmittedAnswers,
    error: errorForSubmittedAnswers,
    execute: executeSubmittedAnswers,
  } = useAsyncFn(questionsService.getSubmittedAnswers);
  const {
    value: suitabilityAnalysis,
    error: errorForSuitabilityAnalysis,
    loading: loadingForSuitabilityAnalysis,
    execute: executeSuitabilityAnalysis,
  } = useAsyncFn(questionsService.suitabilityAnalysisData);

  const {
    value: suitabilityData,
    error: errorForSuitabilityData,
    loading: loadingForSuitabilityData,
    execute: executeSuitabilityData,
  } = useAsyncFn(questionsService.submitSuitabilityData);

  const {
    value: suitabilityGeometry,
    error: errorForSuitabilityGeometry,
    loading: loadingForSuitabilityGeometry,
    execute: executeSuitabilityGeometry,
  } = useAsyncFn(questionsService.suitablityGeometry);

  const logIn = async (data) => {
    setLoginLoading(true);
    setLoginError(null);
    try {
      const response = await questionsService.logIn(data);
      const { access, refresh } = response;
      if (access) {
        localStorage.setItem('access_token', access);
        localStorage.setItem('refresh_token', refresh);
      }
      setLoginLoading(false);
      return response;
    } catch (error) {
      setLoginError(error);
      setLoginLoading(false);
      throw error;
    }
  };

  const signup = async (data) => {
    try {
      const response = await questionsService.signup(data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  return (
    <ServiceContext.Provider
      value={{
        questions,
        signup,
        logIn,
        loginLoading,
        loginError,
        submittedAnswers,
        suitabilityGeometry,
        executeSubmittedAnswers,
        suitabilityAnalysis,
        executeSuitabilityAnalysis,
        suitabilityData,
        executeSuitabilityData,
        selectedService,
        setSelectedService,
        lngLat,
        setLngLat,
        selectedButtons,
        setSelectedButtons,
      }}
    >
      {children}
    </ServiceContext.Provider>
  );
};

// Custom hook to use the context in other components
export const useService = () => {
  return useContext(ServiceContext);
};
