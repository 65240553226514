import { useSearchParams, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormFeedback } from 'reactstrap';
import { useAuth } from '../hooks/useAuth';
import { useForm } from 'react-hook-form';
import { WrappedInput } from '../components/WrappedInput';

const ResetPassword = () => {
  const { register, setError, handleSubmit, formState, watch, setValue } = useForm({
    defaultValues: {},
    mode: 'onSubmit',
  });
  const { errors } = formState;

  const [searchParams, setSearchParams] = useSearchParams();
  const [token, setToken] = useState('');
  const [password, setPassword] = useState('');
  const { confirmPasswordReset } = useAuth();

  const navigate = useNavigate();

  const handleChange = (e) => {
    setPassword(e.target.value);
  };

  useEffect(() => {
    if (searchParams.get('code')) {
      setValue('token', searchParams.get('code'));
    }
  }, [searchParams]);

  const submit = (data) => {
    console.log("dataa",data)
    confirmPasswordReset(data.token, data.password, data.password)
      .then((res) => {
        navigate('/');
      })
      .catch((e) => {
        setError('terror', 'Error updating password');
      })
      .finally(() => {
        //navigate("/");
      });
  };

  return (
    <div className="border d-flex align-items-center justify-content-center" style={{ height: '40rem' }}>
      <div className="card text-center"  style={{ maxWidth: '400px', width: '100%', margin: '0 20px' }}>
        <div className="card-header h5 text-white bg-primary">Password Reset</div>
        <div className="card-body px-5">
          <div className="form-outline">
            <Form  >
              <div className="form-group">
                <div className="input-group">
                  <span className="input-group-addon">
                    <i className="glyphicon glyphicon-envelope color-blue"></i>
                  </span>
                  <WrappedInput
                     {...register('password', {
                      required: 'Password  is required',
                      minLength: {
                        value: 6,
                        message: 'Password must be at least 6 characters',
                      },
                    })}
                    type="password"
                    name={'password'}
                    className={'form-control'}
                    placeholder="Password"
                   
                  />
                </div>
              </div>
              {errors?.password && <p className='' style={{
                color:"red"
              }} > {errors?.password?.message}</p >}
              
              <div className="form-group">
                <WrappedInput {...register('terror')} type="hidden" name={'terror'} />
              </div>
              <div className="form-group  mt-3">
                <Button  onClick={handleSubmit(submit)}>Reset</Button>
              </div>
              <WrappedInput {...register('token')} type="hidden" name={'token'} />
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
