import React, { useEffect } from 'react';
import building from '../../assets/images/building-details.svg';
import buildquality from '../../assets/images/building-quality.svg';
import roof from '../../assets/images/roof.svg';
import kichens from '../../assets/images/kitchens.svg';
import bathroom from '../../assets/images/bathrooms.svg';
import aircondition from '../../assets/images/air-conditioning.svg';
import exterior from '../../assets/images/exterior.svg';
import parking from '../../assets/images/parking.svg';
import other from '../../assets/images/features.svg';

import { Link } from 'react-router-dom';
import { useAppState } from '../../hooks/useAppState';

import { Form, FormGroup, Input, Label } from 'reactstrap';
import { useForm } from 'react-hook-form';
import StepActions from './step-actions';
import { WrappedInput } from '../../components/WrappedInput';
import { mergeObjs } from '../../utils/ObjectUtils';
import GenericStepForm from './generic-step-form';

function BuildingStep({ step, setStep }) {
  const [stepValues, setStepValues] = useAppState();

  const { register, setError, handleSubmit, formState, watch, reset } = useForm({
    defaultValues: mergeObjs(stepValues, {
      building_quality: 'low',
      roof_type: 'gableRoof',
      kitchens_quality: 'kitAverage',
      bathroom_quality: 'bathOlder',
      airconditioning_type: 'windowsUnits',
      exterior_type: 'brickExterior',
      parking_type: 'noParking',
    }),
    mode: 'onSubmit',
  });
  const { errors } = formState;

  return (
    <GenericStepForm step={step} setStep={setStep} submitHandler={handleSubmit}>
      <div className="section-header">
        <img src={building} alt={''} />
        <h2 className="section-heading">Building Details</h2>
      </div>
      <h4 className="section-sub-heading">Check off any that apply about the building</h4>
      <div className="row mt-4">
        <div className="col-lg-6">
          <div className="mb-4">
            <div className="heading-with-icon with-border">
              <div className="options-image">
                <img src={buildquality} alt={''} />
              </div>
              <h3>Building Quality</h3>
            </div>
            <FormGroup check inline>
              <WrappedInput {...register('building_quality')} value={'low'} type="radio" name={'building_quality'} />
              <Label check>Low</Label>
            </FormGroup>
            <FormGroup check inline>
              <WrappedInput
                {...register('building_quality')}
                value={'average'}
                type="radio"
                name={'building_quality'}
              />
              <Label check>Average</Label>
            </FormGroup>
            <FormGroup check inline>
              <WrappedInput {...register('building_quality')} value={'high'} type="radio" name={'building_quality'} />
              <Label check>High</Label>
            </FormGroup>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="mb-4">
            <div className="heading-with-icon with-border">
              <div className="options-image">
                <img src={roof} alt={''} />
              </div>
              <h3>Roof</h3>
            </div>
            <FormGroup check inline>
              <WrappedInput {...register('roof_type')} value={'gableRoof'} type="radio" name={'roof_type'} />
              <Label check>Gable roof</Label>
            </FormGroup>
            <FormGroup check inline>
              <WrappedInput {...register('roof_type')} value={'flatRoof'} type="radio" name={'roof_type'} />
              <Label check>Flat roof</Label>
            </FormGroup>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-lg-6">
          <div className="mb-4">
            <div className="heading-with-icon with-border">
              <div className="options-image">
                <img src={kichens} alt={''} />
              </div>
              <h3>Kitchens</h3>
            </div>
            <FormGroup check inline>
              <WrappedInput
                {...register('kitchens_quality')}
                value={'kitOlder'}
                type="radio"
                name={'kitchens_quality'}
              />
              <Label check>Older</Label>
            </FormGroup>
            <FormGroup check inline>
              <WrappedInput
                {...register('kitchens_quality')}
                value={'kitAverage'}
                type="radio"
                name={'kitchens_quality'}
              />
              <Label check>Average</Label>
            </FormGroup>
            <FormGroup check inline>
              <WrappedInput
                {...register('kitchens_quality')}
                value={'kitHighEnd'}
                type="radio"
                name={'kitchens_quality'}
              />
              <Label check>High end</Label>
            </FormGroup>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="mb-4">
            <div className="heading-with-icon with-border">
              <div className="options-image">
                <img src={bathroom} alt={''} />
              </div>
              <h3>Bathrooms</h3>
            </div>
            <FormGroup check inline>
              <WrappedInput
                {...register('bathroom_quality')}
                value={'bathOlder'}
                type="radio"
                name={'bathroom_quality'}
              />
              <Label check>Older</Label>
            </FormGroup>
            <FormGroup check inline>
              <WrappedInput
                {...register('bathroom_quality')}
                value={'bathAverage'}
                type="radio"
                name={'bathroom_quality'}
              />
              <Label check>Average</Label>
            </FormGroup>
            <FormGroup check inline>
              <WrappedInput
                {...register('bathroom_quality')}
                value={'bathHighEnd'}
                type="radio"
                name={'bathroom_quality'}
              />
              <Label check>High end</Label>
            </FormGroup>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-lg-12">
          <div className="mb-4">
            <div className="heading-with-icon with-border">
              <div className="options-image">
                <img src={aircondition} alt={''} />
              </div>
              <h3>Air conditioning</h3>
            </div>
            <FormGroup check inline>
              <WrappedInput
                {...register('airconditioning_type')}
                value={'NoAirConditioning'}
                type="radio"
                name={'airconditioning_type'}
              />
              <Label check>No air conditioning</Label>
            </FormGroup>
            <FormGroup check inline>
              <WrappedInput
                {...register('airconditioning_type')}
                value={'windowsUnits'}
                type="radio"
                name={'airconditioning_type'}
              />
              <Label check>Window units</Label>
            </FormGroup>
            <FormGroup check inline>
              <WrappedInput
                {...register('airconditioning_type')}
                value={'throughWall'}
                type="radio"
                name={'airconditioning_type'}
              />
              <Label check>Through-wall conditioners</Label>
            </FormGroup>
            <FormGroup check inline>
              <WrappedInput
                {...register('airconditioning_type')}
                value={'centralAC'}
                type="radio"
                name={'airconditioning_type'}
              />
              <Label check>Central air conditioning</Label>
            </FormGroup>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-lg-12">
          <div className="mb-4">
            <div className="heading-with-icon with-border">
              <div className="options-image">
                <img src={exterior} alt={''} />
              </div>
              <h3>Exterior</h3>
            </div>
            <FormGroup check inline>
              <WrappedInput
                {...register('exterior_type')}
                value={'brickExterior'}
                type="radio"
                name={'exterior_type'}
              />
              <Label check>Brick exterior</Label>
            </FormGroup>
            <FormGroup check inline>
              <WrappedInput {...register('exterior_type')} value={'woodExterior'} type="radio" name={'exterior_type'} />
              <Label check>Wood exterior</Label>
            </FormGroup>
            <FormGroup check inline>
              <WrappedInput
                {...register('exterior_type')}
                value={'aluminumExterior'}
                type="radio"
                name={'exterior_type'}
              />
              <Label check>Vinyl or aluminum exterior</Label>
            </FormGroup>
            <FormGroup check inline>
              <WrappedInput
                {...register('exterior_type')}
                value={'shingleExterior'}
                type="radio"
                name={'exterior_type'}
              />
              <Label check>Asbestos shingle exterior</Label>
            </FormGroup>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-lg-12">
          <div className="heading-with-icon with-border">
            <div className="options-image">
              <img src={parking} alt={''} />
            </div>
            <h3>Parking</h3>
          </div>
          <FormGroup check inline>
            <WrappedInput {...register('parking_type')} value={'noParking'} type="radio" name={'parking_type'} />
            <Label check>No parking on-site</Label>
          </FormGroup>
          <FormGroup check inline>
            <WrappedInput {...register('parking_type')} value={'limitedParking'} type="radio" name={'parking_type'} />
            <Label check>Limited parking on-site</Label>
          </FormGroup>
          <FormGroup check inline>
            <WrappedInput {...register('parking_type')} value={'spacePerUnit'} type="radio" name={'parking_type'} />
            <Label check>About one space per unit on site</Label>
          </FormGroup>
          <FormGroup check inline>
            <WrappedInput {...register('parking_type')} value={'spacePerBedroom'} type="radio" name={'parking_type'} />
            <Label check>About one space per bedroom on site</Label>
          </FormGroup>
          <FormGroup check inline>
            <WrappedInput
              {...register('parking_type')}
              value={'moreSpacePerBedroom'}
              type="radio"
              name={'parking_type'}
            />
            <Label check>More than one space per bedroom on site</Label>
          </FormGroup>
        </div>
        <div className="col-lg-12">
          <div className="mb-4">
            <FormGroup check inline>
              <WrappedInput
                {...register('overnight_parking_available')}
                type="checkbox"
                name={'overnight_parking_available'}
              />
              <Label check>Overnight street parking is available</Label>
            </FormGroup>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-lg-12">
          <div className="mb-4">
            <div className="heading-with-icon with-border">
              <div className="options-image">
                <img src={other} alt={''} />
              </div>
              <h3>other_features</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4">
          <FormGroup check>
            <WrappedInput {...register('other_features')} value={'elevators'} type="checkbox" name={'other_features'} />
            <Label check>Elevator or elevators</Label>
          </FormGroup>
        </div>
        <div className="col-lg-4">
          <FormGroup check>
            <WrappedInput {...register('other_features')} type="checkbox" value={'indoorPool'} name={'other_features'} />
            <Label check>Indoor pool</Label>
          </FormGroup>
        </div>
        <div className="col-lg-4">
          <FormGroup check>
            <WrappedInput {...register('other_features')} type="checkbox" value={'outdoorPool'} name={'other_features'} />
            <Label check>Outdoor pool</Label>
          </FormGroup>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4">
          <FormGroup check>
            <WrappedInput {...register('other_features')} type="checkbox" value={'tennisCourt'} name={'other_features'} />
            <Label check>Tennis court or courts</Label>
          </FormGroup>
        </div>
        <div className="col-lg-4">
          <FormGroup check>
            <WrappedInput {...register('other_features')} type="checkbox" value={'roofDeck'} name={'other_features'} />
            <Label check>Roof deck</Label>
          </FormGroup>
        </div>
        <div className="col-lg-4">
          <FormGroup check>
            <WrappedInput {...register('other_features')} type="checkbox" value={'laundryRoom'} name={'other_features'} />
            <Label check>Laundry room</Label>
          </FormGroup>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4">
          <FormGroup check>
            <WrappedInput {...register('other_features')} type="checkbox" value={'balconies'} name={'other_features'} />
            <Label check>Balconies and patios</Label>
          </FormGroup>
        </div>
        <div className="col-lg-4">
          <FormGroup check>
            <WrappedInput
              {...register('other_features')}
              type="checkbox"
              value={'laundryFacilities'}
              name={'other_features'}
            />
            <Label check>In-unit laundry facilities</Label>
          </FormGroup>
        </div>
        <div className="col-lg-4">
          <FormGroup check>
            <WrappedInput
              {...register('other_features')}
              type="checkbox"
              value={'storageCubicles'}
              name={'other_features'}
            />
            <Label check>Storage cubicles</Label>
          </FormGroup>
        </div>
      </div>
    </GenericStepForm>
  );
}

export default BuildingStep;
