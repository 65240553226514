import { useEffect, useState } from 'react';

const useMobileDetection = () => {
  const [showModal, setShowModal] = useState(false);
  const [browser, setBrowser] = useState(null);

  useEffect(() => {
    const isMobileDevice = () => /Mobi|Android/i.test(navigator.userAgent);

    const getBrowserType = () => {
      const ua = navigator.userAgent;
      if (/CriOS/i.test(ua)) {
        return 'chrome';
      } else if (/Safari/i.test(ua) && !/Chrome/i.test(ua)) {
        return 'safari';
      } else {
        return 'other';
      }
    };

    if (isMobileDevice()) {
      const browserType = getBrowserType();
      setBrowser(browserType);
      setShowModal(true)
    }
  }, []);

  return [showModal, setShowModal, browser];
};

export default useMobileDetection;