import { useAppState } from '../../hooks/useAppState';

import unitsIcon from '../../assets/images/units.svg';
import stdicon from '../../assets/images/studios.svg';
import bdicon from '../../assets/images/bed-icon.svg';
import { useForm } from 'react-hook-form';
import { Form, FormGroup, Label } from 'reactstrap';
import { WrappedInput } from '../../components/WrappedInput';
import StepActions from './step-actions';
import { ErrorMessage } from '@hookform/error-message';
import { mergeObjs } from '../../utils/ObjectUtils';
import GenericStepForm from './generic-step-form';
import { WrappedNumberInput } from '../../components/WrappedNumberInput';

function UnitStep({ step, setStep }) {
  const [stepValues, setStepValues] = useAppState();

  const { register, setError, handleSubmit, formState, getValues, watch } = useForm({
    defaultValues: mergeObjs(stepValues, {
      unitType: 'Single',
      // num_studios: 0,
      // studio_avg_rent: 0,
      // studio_total_sqft: 0,
      num_one_bedroom: 0,
      one_bedroom_avg_rent: 0,
      one_bedroom_total_sqft: 0,
      num_two_bedroom: 0,
      two_bedroom_avg_rent: 0,
      two_bedroom_total_sqft: 0,
      num_three_bedroom: 0,
      three_bedroom_avg_rent: 0,
      three_bedroom_total_sqft: 0,
      totalAreaSqFt: 0,
      rentalType: 'True',
    }),
    mode: 'onSubmit',
  });
  const { errors } = formState;

  let rentalType = watch('rentalType');

  const valueIsZeroOrEmpty = (value) => {
    return value === '' || value === 0;
  };

  const valueIsGtThanZero = (value) => {
    return value !== '' && value > 0;
  };

  const validateStudio = (data) => {
    let message = 'Please fill fll values and must values be greater than or Equal to 0';

    let studio_avg_rent = getValues('studio_avg_rent');
    let studio_total_sqft = getValues('studio_total_sqft');

    if (valueIsZeroOrEmpty(data) && valueIsZeroOrEmpty(studio_avg_rent) && valueIsZeroOrEmpty(studio_total_sqft)) {
      return true;
    } else {
      if (!valueIsGtThanZero(data) || !valueIsGtThanZero(studio_avg_rent) || !valueIsGtThanZero(studio_total_sqft))
        return message;
      else return true;
    }
  };

  const validateOneBedroom = (data) => {
    let message = 'Either fill all values or leave all values  zero';

    let one_bedroom_avg_rent = getValues('one_bedroom_avg_rent');
    let one_bedroom_total_sqft = getValues('one_bedroom_total_sqft');

    if (
      valueIsZeroOrEmpty(data) &&
      valueIsZeroOrEmpty(one_bedroom_avg_rent) &&
      valueIsZeroOrEmpty(one_bedroom_total_sqft)
    ) {
      return true;
    } else {
      if (
        !valueIsGtThanZero(data) ||
        !valueIsGtThanZero(one_bedroom_avg_rent) ||
        !valueIsGtThanZero(one_bedroom_total_sqft)
      )
        return message;
      else return true;
    }
  };

  const validateTwoBedroom = (data) => {
    let message = 'Either fill all values or leave all values empty/zero';

    let two_bedroom_avg_rent = getValues('two_bedroom_avg_rent');
    let two_bedroom_total_sqft = getValues('two_bedroom_total_sqft');

    if (
      valueIsZeroOrEmpty(data) &&
      valueIsZeroOrEmpty(two_bedroom_avg_rent) &&
      valueIsZeroOrEmpty(two_bedroom_total_sqft)
    ) {
      return true;
    } else {
      if (
        !valueIsGtThanZero(data) ||
        !valueIsGtThanZero(two_bedroom_avg_rent) ||
        !valueIsGtThanZero(two_bedroom_total_sqft)
      )
        return message;
      else return true;
    }
  };

  const validateThreeBedroom = (data) => {
    let message = 'Either fill all values or leave all values empty';

    let three_bedroom_avg_rent = getValues('three_bedroom_avg_rent');
    let three_bedroom_total_sqft = getValues('three_bedroom_total_sqft');

    if (
      valueIsZeroOrEmpty(data) &&
      valueIsZeroOrEmpty(three_bedroom_avg_rent) &&
      valueIsZeroOrEmpty(three_bedroom_total_sqft)
    ) {
      return true;
    } else {
      if (
        !valueIsGtThanZero(data) ||
        !valueIsGtThanZero(three_bedroom_avg_rent) ||
        !valueIsGtThanZero(three_bedroom_total_sqft)
      )
        return message;
      else return true;
    }
  };

  return (
    <GenericStepForm step={step} setStep={setStep} submitHandler={handleSubmit}>
      <div className="section-header">
        <img src={unitsIcon} alt={''} />
        <h2 className="section-heading">Units</h2>
      </div>
      <h4 className="section-sub-heading">Type of unit</h4>
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
            <div className="col-5 mb-3">
              <FormGroup check>
                <WrappedInput {...register('unitType')} type="radio" name={'unitType'} checked value={'Single'} />{' '}
                <Label check>Single-family unit</Label>
              </FormGroup>
              <FormGroup check>
                <WrappedInput {...register('unitType')} value={'Multiple'} type="radio" name={'unitType'} />{' '}
                <Label check>Multi-family unit</Label>
              </FormGroup>
            </div>
            <div className="col-4">
              <WrappedNumberInput
                errors={errors}
                registerhandler={register}
                min={1}
                max={500000}
                name={'totalAreaSqFt'}
                className={''}
                placeholder="Total Area SqFt."
              />
              <div className="col-2"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="mb-3">
        <p className="mb-4 text-12">
          Is this property primarily used as a rental property or to receive rental income?
        </p>
        <FormGroup check inline>
          <Label check>
            <WrappedInput {...register('rentalType')} value={'True'} type="radio" name={'rentalType'} />
            <label>Yes</label>
          </Label>
        </FormGroup>
        <FormGroup check inline>
          <Label check>
            <WrappedInput {...register('rentalType')} value={'False'} type="radio" name={'rentalType'} />
            <label>No</label>
          </Label>
        </FormGroup>
      </div>

      {rentalType == 'True' && (
        <div className="count-wrapper">
          <div className="row">
            <div className="col-lg-4">
              <div className="mb-4">
                <div className="count-options">
                  <fieldset className={`border ${errors?.num_studios ? 'border-danger' : ''} p-2`}>
                    <legend className="float-none w-auto">
                      <img src={stdicon} alt={''} />
                      <h4>Studios</h4>
                    </legend>
                    <div className={'container'}>
                      <div className={'row justify-content-center'}>
                        <div className={'col-4 m-0 p-0'}>
                          <WrappedInput
                            {...register('num_studios', { valueAsNumber: true, validate: validateStudio })}
                            type="number"
                            name={'num_studios'}
                            className={''}
                            placeholder="No."
                          />
                        </div>
                        <div className={'col-6 m-0 p-0 ms-1'}>
                          <WrappedInput
                            {...register('studio_avg_rent', { valueAsNumber: true })}
                            type="number"
                            name={'studio_avg_rent'}
                            className={''}
                            placeholder="$ per month"
                          />
                        </div>
                      </div>
                      <div className={'row justify-content-center mt-2'}>
                        <div className={'col-10 m-0 p-0'}>
                          <WrappedInput
                            {...register('studio_total_sqft', { valueAsNumber: true })}
                            type="number"
                            name={'studio_total_sqft'}
                            className={''}
                            placeholder="Total sq. ft"
                          />
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  <ErrorMessage errors={errors} name="num_studios" as={'p'} className={'error-msg'} />
                </div>
              </div>
            </div>
            <div className="col-lg-4 " style={{
              marginLeft:"0.333333%"
            }}>
              <div className="mb-4">
                <div className="count-options">
                  <fieldset className={`border ${errors?.num_one_bedroom ? 'border-danger' : ''} p-2`}>
                    <legend className="float-none w-auto">
                      <img src={bdicon} alt={''} />
                      <h4>1-bedrooms</h4>
                    </legend>
                    <div className={'container'}>
                      <div className={'row justify-content-center'}>
                        <div className={'col-4 m-0 p-0'}>
                          <WrappedInput
                            {...register('num_one_bedroom', { validate: validateOneBedroom, valueAsNumber: true })}
                            type="number"
                            name={'num_one_bedroom'}
                            className={''}
                            placeholder="No."
                          />
                        </div>
                        <div className={'col-6 m-0 p-0 ms-1'}>
                          <WrappedInput
                            {...register('one_bedroom_avg_rent', { valueAsNumber: true })}
                            type="number"
                            name={'one_bedroom_avg_rent'}
                            className={''}
                            placeholder="$ per month"
                          />
                        </div>
                      </div>
                      <div className={'row justify-content-center mt-2'}>
                        <div className={'col-10 m-0 p-0'}>
                          <WrappedInput
                            {...register('one_bedroom_total_sqft', { valueAsNumber: true })}
                            type="number"
                            name={'one_bedroom_total_sqft'}
                            className={''}
                            placeholder="Total sq. ft"
                          />
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  <ErrorMessage errors={errors} name="num_one_bedroom" as={'p'} className={'error-msg'} />
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="mb-4">
                <div className="count-options">
                  <fieldset className={`border ${errors?.num_two_bedroom ? 'border-danger' : ''} p-2`}>
                    <legend className="float-none w-auto">
                      <img src={bdicon} alt={''} />
                      <h4>2-bedrooms</h4>
                    </legend>
                    <div className={'container'}>
                      <div className={'row justify-content-center'}>
                        <div className={'col-4 m-0 p-0'}>
                          <WrappedInput
                            {...register('num_two_bedroom', { validate: validateTwoBedroom, valueAsNumber: true })}
                            type="number"
                            name={'num_two_bedroom'}
                            className={''}
                            placeholder="No."
                          />
                        </div>
                        <div className={'col-6 m-0 p-0 ms-1'}>
                          <WrappedInput
                            {...register('two_bedroom_avg_rent', { valueAsNumber: true })}
                            type="number"
                            name={'two_bedroom_avg_rent'}
                            className={''}
                            placeholder="$ per month"
                          />
                        </div>
                      </div>
                      <div className={'row justify-content-center mt-2'}>
                        <div className={'col-10 m-0 p-0'}>
                          <WrappedInput
                            {...register('two_bedroom_total_sqft', { valueAsNumber: true })}
                            type="number"
                            name={'two_bedroom_total_sqft'}
                            className={''}
                            placeholder="Total sq. ft"
                          />
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  <ErrorMessage errors={errors} name="num_two_bedroom" as={'p'} className={'error-msg'} />
                </div>
              </div>
            </div>
            <div className="col-lg-4 " style={{
              marginLeft:"0.333333%"
            }}>
              <div className="mb-4">
                <div className="count-options">
                  <fieldset className={`border ${errors?.num_three_bedroom ? 'border-danger' : ''} p-2`}>
                    <legend className="float-none w-auto">
                      <img src={bdicon} alt={''} />
                      <h4>3-bedrooms</h4>
                    </legend>
                    <div className={'container'}>
                      <div className={'row justify-content-center'}>
                        <div className={'col-4 m-0 p-0'}>
                          <WrappedInput
                            {...register('num_three_bedroom', {
                              validate: validateThreeBedroom,
                              valueAsNumber: true,
                            })}
                            type="number"
                            name={'num_three_bedroom'}
                            className={''}
                            placeholder="No."
                          />
                        </div>
                        <div className={'col-6 m-0 p-0 ms-1'}>
                          <WrappedInput
                            {...register('three_bedroom_avg_rent', { valueAsNumber: true })}
                            type="number"
                            name={'three_bedroom_avg_rent'}
                            className={''}
                            placeholder="$ per month"
                          />
                        </div>
                      </div>
                      <div className={'row justify-content-center mt-2'}>
                        <div className={'col-10 m-0 p-0'}>
                          <WrappedInput
                            {...register('three_bedroom_total_sqft', { valueAsNumber: true })}
                            type="number"
                            name={'three_bedroom_total_sqft'}
                            className={''}
                            placeholder="Total sq. ft"
                          />
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  <ErrorMessage errors={errors} name="num_three_bedroom" as={'p'} className={'error-msg'} />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <p>
                Enter the number of units of each type and the Market Rent that one of these units would get per month.
                (Market Rent is the rent the landlord could get if the units were rented today to new tenants.)
              </p>
              <p>
                Enter the average rent for each type. (For instance, if there are two one-bedrooms and they could get
                $1,000 and $1,400 because one is better than the other, enter the average, $1,200, as the one-bedroom
                Market Rent.)
              </p>
            </div>
          </div>
        </div>
      )}
    </GenericStepForm>
  );
}

export default UnitStep;
