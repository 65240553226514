import { useEffect, useState } from 'react';
import { Button, Col, Form, FormFeedback, FormGroup, Label, Row } from 'reactstrap';
import { WrappedInput } from './WrappedInput';
import { useForm } from 'react-hook-form';
import { useAuth } from '../hooks/useAuth';
import Emitter from './Emitter';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../services/axio-service';
import PhoneInput from 'react-phone-input-2';
import { toast } from 'react-toastify';

function SignUpBox() {
  const { register, setError, handleSubmit, formState, reset, setValue, watch, trigger } = useForm({
    defaultValues: { user_type: 'BUSINESSOWNWER' },
  });
  const { errors } = formState;
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userCreated, setIsUserCreated] = useState(null);

  const [vendorData, setVendorData] = useState(null);

  const { signUp } = useAuth();

  const signUpSubmit = (data) => {
    let newData = {};

    if (data.user_type === 'BUSINESSOWNWER') {
      newData = {
        email: data.email,
        full_name: data.full_name,
        password: data.password,
        phone_number: data.phone_number,
        user_type: data.user_type,
      };
    } else if (data.user_type === 'VENDOR') {
      newData = {
        email: data.email,
        full_name: data.full_name,
        password: data.password,
        phone_number: data.phone_number,
        user_type: data.user_type,
        vendor_type: data.vendor_type ? data.vendor_type : 1,
      };
    }

    setIsSubmitting(true);
    signUp(newData)
      .then(function (response) {
        Emitter.emit('user_signedup');

        setIsUserCreated(response);

        // if (response) {
        //   console.log('navigate', 2323);

        //   navigate('/otp', {
        //     state: {
        //       userId: response.userid,
        //       phone_number: newReq.phone_number,
        //     },
        //   });
        // }
        // reset();
      })
      .catch(function (error) {
        const errors = error?.response?.data;
        if (errors?.detail === 'Email address already taken.') {
          setError('email', { type: 'custom', message: 'Email address already taken' }, { shouldFocus: true });
        } else if (errors?.twillioError) {
          toast.error(errors?.twillioError);
        } else {
          Object.keys(errors).forEach(function (key) {
            setError(key, { type: 'custom', message: errors[key] }, { shouldFocus: true });
          });
        }
      })
      .then(function () {
        setIsSubmitting(false);
      });
  };

  let user_type = watch('user_type');

  useEffect(() => {
    const getBuissness = async () => {
      const res = await axiosInstance.get('/businesstype/');
      setVendorData(res?.data);
    };
    getBuissness();
  }, []);

  useEffect(() => {
    if (userCreated) {
      console.log('userCreated', userCreated);
      navigate('/otp', {
        state: {
          userId: userCreated?.userid,
          phone_number: userCreated?.phone_number,
          email: userCreated?.email,
        },
      });
    }
  }, [userCreated]);

  const password = watch('password');

  const validatePassword = (value) => value === password || 'Confirmation Password incorrect';

  const email = watch('email');

  useEffect(() => {
    if (email !== undefined) {
      trigger('email');
    }
  }, [trigger, email]);

  return (
    <>
      <div
        className="formcont my-class"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0px 50px',
          position: 'relative',
          zIndex: '99',
          background: '$',
        }}
      >
        <FormGroup check inline className="formcont">
          <Label check>
            <WrappedInput
              style={{
                color: '#0497FE',
              }}
              defaultChecked={user_type}
              {...register('user_type')}
              checked={user_type == 'BUSINESSOWNWER'}
              value={'BUSINESSOWNWER'}
              type="radio"
              name={'user_type'}
            />
            <label
              style={{
                color: '#0D0E0F',
                fontSize: '14px',
                fontWeight: 600,

                paddingTop: '1.7px',
              }}
            >
              Personal
            </label>
          </Label>
        </FormGroup>
        <FormGroup check inline>
          <Label check>
            <WrappedInput
              {...register('user_type')}
              checked={user_type == 'VENDOR'}
              value={'VENDOR'}
              type="radio"
              name={'user_type'}
            />
            <label
              style={{
                color: '#0D0E0F',
                fontSize: '14px',
                fontWeight: 600,
                paddingTop: '1.7px',
              }}
            >
              Vendor
            </label>
          </Label>
        </FormGroup>
      </div>

      <hr className="sign_up_hr" />
      <div className="formWrappersign">
        <Form onSubmit={handleSubmit(signUpSubmit)}>
          {user_type == 'BUSINESSOWNWER' && (
            <FormGroup
              tag="fieldset"
              style={{
                color: '#8995B2',
                fontSize: '14px',
                fontWeight: '500',
              }}
            >
              <Row
                className="mt-3"
                style={{
                  fontSize: '14px',
                }}
              >
                <Col>
                  <WrappedInput
                    type="name"
                    className="signup-field"
                    name="full_name"
                    placeholder="Full Name"
                    invalid={errors?.full_name}
                    {...register('full_name', { required: 'Full Name  is required' })}
                  />
                  {errors?.full_name && <FormFeedback> {errors?.full_name?.message}</FormFeedback>}
                </Col>
              </Row>

              <Row
                className="mt-3"
                style={{
                  color: '#8995B2',
                  fontSize: '14px',
                  fontWeight: '500',
                }}
              >
                <Col className="inputfields">
                  <WrappedInput
                    type="email"
                    name="email"
                    className="signup-field"
                    placeholder="Email"
                    invalid={errors?.email}
                    {...register('email', {
                      required: 'Email is required',
                      pattern: {
                        value: /^[A-Za-z][A-Za-z0-9._%+-]*@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
                        message: 'Please enter a valid email address',
                      },
                    })}
                  />
                  {errors?.email && <FormFeedback>{errors?.email?.message}</FormFeedback>}
                </Col>
              </Row>
              <Row
                className="mt-3"
                style={{
                  color: '#8995B2',
                  fontSize: '14px',
                  fontWeight: '500',
                }}
              >
                <Col className="inputfields">
                  {/* <WrappedInput
                      type="number"
                      className="signup-field"
                      name="phone_number"
                      placeholder="Phone number"
                      invalid={errors?.phone_number}
                      {...register('phone_number', { required: 'Phone Number  is required' })}
                    /> */}
                  <PhoneInput
                    className="phoneNumber"
                    country={'us'}
                    value={watch('phone_number')}
                    onChange={(value) => {
                      setValue('phone_number', value);
                    }}
                    onFocus={() => {
                      trigger('phone_number');
                    }}
                    onBlur={() => {
                      trigger('phone_number');
                    }}
                    inputProps={{
                      required: true,
                    }}
                  />
                  {errors?.phone_number && (
                    <p
                      style={{
                        color: 'red',
                        padding: 0,
                        margin: 0,
                        fontSize: '12px',
                      }}
                    >
                      {' '}
                      {errors?.phone_number?.message}
                    </p>
                  )}
                </Col>
              </Row>

              <Row className="mt-3 justify-content-center d-flex">
                <Col className=" ">
                  <WrappedInput
                    type="password"
                    name="password"
                    className="signup-field"
                    placeholder="Password"
                    invalid={errors?.password}
                    {...register('password', {
                      required: 'Password  is required',
                      minLength: {
                        value: 6,
                        message: 'Password must be at least 6 characters',
                      },
                    })}
                    onChange={(e) => {
                      setValue('password', e.target.value);
                      trigger('password');
                    }}
                    onFocus={() => {
                      trigger('password');
                    }}
                    onBlur={() => {
                      trigger('password');
                    }}
                  />
                  {errors?.password && <FormFeedback> {errors?.password?.message}</FormFeedback>}
                </Col>
              </Row>
              <Row className="mt-3 justify-content-center d-flex">
                <Col className="">
                  <WrappedInput
                    type="password"
                    name="confirm_password"
                    className="signup-field"
                    placeholder="Confirm Password"
                    invalid={errors?.confirm_password}
                    {...register('confirm_password', {
                      required: 'Confirm Password is Required',
                      minLength: {
                        value: 6,
                        message: 'Password must be at least 6 characters',
                      },

                      validate: validatePassword,
                    })}
                    onChange={(e) => {
                      setValue('confirm_password', e.target.value);
                      trigger('confirm_password');
                    }}
                    onFocus={() => {
                      trigger('confirm_password');
                    }}
                    onBlur={() => {
                      trigger('confirm_password');
                    }}
                  />
                  {errors?.confirm_password && <FormFeedback> {errors?.confirm_password?.message}</FormFeedback>}
                </Col>
              </Row>
            </FormGroup>
          )}

          {user_type == 'VENDOR' && (
            <FormGroup tag="fieldset">
              <FormGroup
                tag="fieldset"
                style={{
                  color: '#8995B2',
                  fontSize: '14px',
                  fontWeight: '500',
                }}
              >
                <Row
                  className="mt-3"
                  style={{
                    fontSize: '14px',
                  }}
                >
                  <Col className="inputfields">
                    <WrappedInput
                      type="name"
                      className="signup-field"
                      name="full_name"
                      placeholder="Full Name"
                      invalid={errors?.full_name}
                      {...register('full_name', { required: 'full Name  is required' })}
                    />
                    {errors?.full_name && <FormFeedback> {errors?.full_name?.message}</FormFeedback>}
                  </Col>
                </Row>

                <Row
                  className="mt-3"
                  style={{
                    color: '#8995B2',
                    fontSize: '14px',
                    fontWeight: '500',
                  }}
                >
                  <Col className="inputfields">
                    <WrappedInput
                      type="email"
                      className="signup-field"
                      name="email"
                      placeholder="Email"
                      invalid={errors?.email}
                      {...register('email', { required: 'Email  is required' })}
                    />
                    {errors?.email && <FormFeedback> {errors?.email?.message}</FormFeedback>}
                  </Col>
                </Row>
                <Row
                  className="mt-3"
                  style={{
                    color: '#8995B2',
                    fontSize: '14px',
                    fontWeight: '500',
                  }}
                >
                  <Col className="inputfields">
                    {/* <WrappedInput
                      type="number"
                      className="signup-field"
                      name="phone_number"
                      placeholder="Phone number"
                      invalid={errors?.phone_number}
                      {...register('phone_number', { required: 'Phone Number  is required' })}
                    /> */}
                    <PhoneInput
                      className="phoneNumber"
                      country={'us'}
                      value={watch('phone_number')}
                      onChange={(value) => {
                        setValue('phone_number', value);
                      }}
                      onFocus={() => {
                        trigger('phone_number');
                      }}
                      onBlur={() => {
                        trigger('phone_number');
                      }}
                      inputProps={{
                        required: true,
                      }}
                    />
                    {errors?.phone_number && (
                      <p
                        style={{
                          color: 'red',
                          padding: 0,
                          margin: 0,
                          fontSize: '12px',
                        }}
                      >
                        {' '}
                        {errors?.phone_number?.message}
                      </p>
                    )}
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col className="inputfields">
                    <WrappedInput
                      type="password"
                      className="signup-field"
                      name="password"
                      placeholder="Password"
                      invalid={errors?.password}
                      {...register('password', { required: 'Password  is required' })}
                      onChange={(e) => {
                        setValue('password', e.target.value);
                        trigger('password');
                      }}
                      onFocus={() => {
                        trigger('password');
                      }}
                      onBlur={() => {
                        trigger('password');
                      }}
                    />
                    {errors?.password && <FormFeedback> {errors?.password?.message}</FormFeedback>}
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col className="inputfields">
                    <WrappedInput
                      type="password"
                      name="confirm_password"
                      className="signup-field"
                      placeholder="Confirm Password"
                      invalid={errors?.confirm_password}
                      {...register('confirm_password', { required: true, validate: validatePassword })}
                      onChange={(e) => {
                        setValue('confirm_password', e.target.value);
                        trigger('confirm_password');
                      }}
                      onFocus={() => {
                        trigger('confirm_password');
                      }}
                      onBlur={() => {
                        trigger('confirm_password');
                      }}
                    />
                    {errors?.confirm_password && <FormFeedback> {errors?.confirm_password?.message}</FormFeedback>}
                  </Col>
                </Row>
              </FormGroup>

              {user_type == 'VENDOR' && (
                <Row className="mb-3">
                  <Col className=" ">
                    <select
                      className="selectedoptions"
                      onChange={(e) => {
                        setValue('vendor_type', e.target.value);
                      }}
                      value={watch('vendor_type')}
                      name="vendor_type"
                    >
                      {vendorData?.map((item, index) => (
                        <option value={Number(item?.id)} key={index}>
                          {item?.name}
                        </option>
                      ))}
                    </select>
                  </Col>
                </Row>
              )}
            </FormGroup>
          )}

          <div className="text-center">
            <Button type="submit" className={'w-100   loginBtn '} disabled={isSubmitting}>
              {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
              Sign up
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
}

export default SignUpBox;
