import { Link } from 'react-router-dom';
import { useState } from 'react';

import valuationLayerIcon from '../assets/images/valuation-layer-1.jpg';
import propertyOwnerIcon from '../assets/images/use-cases/property-owner.png';
import prospectiveBuyersIcon from '../assets/images/use-cases/buyers.png';
import lendersIcon from '../assets/images/use-cases/lenders.png';
import realEstateAgentsIcon from '../assets/images/use-cases/agents.png';

import expertInsightsIcon from '../assets/images/advantages/expert-insights.png';
import saveTimeIcon from '../assets/images/advantages/save-time.png';
import detailedResultsIcon from '../assets/images/advantages/detailed-results.png';

import SubHeader from './sub-header';
import CopyRightComponent from './copyright';
import Privacy from '../components/privacy';

function ValuationPage() {
  const [isModal, setIsModal] = useState(false);

  return (
    <div>
      <Privacy isModal={isModal} setIsModal={setIsModal} />
      <section className="valuation-hero-area">
        <SubHeader />
        <div className="container">
          <div className="valuation_main">
            <div className="row align-items-center">
              <div className="col-md-12 col-12">
                <div className="text-white">
                  <h2 className="wow fadeInUp contactUsTitle" data-wow-delay=".4s">
                    On-Demand Property Valuation Services
                  </h2>
                  <p className="wow fadeInUp contactUsDesc" data-wow-delay=".6s">
                    We help you move at the speed of business with fast, accurate property valuations
                  </p>
                  <div className="button wow fadeInUp" data-wow-delay=".8s">
                    <a
                      href="#"
                      className="btn btn-icon valuation-icon"
                      onClick={() => {
                        setIsModal(true);
                      }}
                    >
                      GET MY VALUATION
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-md-8">
              <div className="section-title mb-0 white-bg">
                <h3 className="wow fadeInUp" data-wow-delay=".2s">
                  Time Is Money
                </h3>
                <h2 className="dot wow fadeInUp" data-wow-delay=".4s">
                  Don’t Waste Either Of Them
                </h2>
                <p className="wow fadeInUp" data-wow-delay=".6s">
                  When you’ve found the ideal commercial property to expand your business, you’re looking to sell, or
                  you simply want to know your property’s real-time value, time is of the essence. And so is good data
                  on that property.
                </p>
                <p className="wow fadeInUp" data-wow-delay=".8s">
                  And when you’re ready to list your property or strike a deal, you want to be fully informed about that
                  property’s valuation. But waiting on a commercial real estate appraiser to conduct an in-depth
                  valuation takes time, potentially resulting in lost deals. Until now.{' '}
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <img className="wow fadeInUp" data-wow-delay=".2s" src={valuationLayerIcon} />
            </div>
          </div>
        </div>
      </section>
      <section className="powerful-tools valuation-banner-2">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="pt-5 section-title dark-bg">
                <h3 className="wow fadeInUp" data-wow-delay=".2s">
                  The Industry’s Most Powerful
                </h3>
                <h2 className="dot wow fadeInUp" data-wow-delay=".4s">
                  Real Estate Valuation Engine
                </h2>
                <p className="wow fadeInUp" data-wow-delay=".6s">
                  We help you save time and money and boost your profits through our powerful real estate valuation
                  engine. Input key property details, and we’ll deliver valuation insights that help you make the most
                  informed decision, whether you’re looking to buy, sell, or underwrite commercial real estate.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="section-title dark-bg">
                <h3 className="wow fadeInUp" data-wow-delay=".2s">
                  In less than
                </h3>
                <h2 className="dot wow fadeInUp" data-wow-delay=".4s">
                  Ten Minutes
                </h2>
                <p className="wow fadeInUp" data-wow-delay=".6s">
                  You can have the data you need at your fingertips to help you stay one step ahead of the competition
                  and strike the right deal for your situation.{' '}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="use-cases">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title dark-bg">
                <h3 className="wow fadeInUp" data-wow-delay=".2s">
                  Diverse Audience
                </h3>
                <h2 className="dot wow fadeInUp" data-wow-delay=".4s">
                  Multiple Use Cases
                </h2>
              </div>
            </div>
          </div>
          <div className="row gx-5">
            <div className="col-md-4 offset-md-2">
              <div className="case wow fadeInUp" data-wow-delay=".2s">
                <h4 className="case-title">Property Owners</h4>
                <img src={propertyOwnerIcon} />
              </div>
            </div>
            <div className="col-md-4">
              <div className="case wow fadeInUp" data-wow-delay=".3s">
                <h4 className="case-title">Prospective Buyers</h4>
                <img src={prospectiveBuyersIcon} />
              </div>
            </div>
          </div>
          <div className="row gx-5">
            <div className="col-md-4 offset-md-2">
              <div className="case wow fadeInUp" data-wow-delay=".4s">
                <h4 className="case-title">Lenders</h4>
                <img src={lendersIcon} />
              </div>
            </div>
            <div className="col-md-4">
              <div className="case wow fadeInUp" data-wow-delay=".5s">
                <h4 className="case-title">Commercial Real Estate Agents</h4>
                <img src={realEstateAgentsIcon} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="advantages-section bg-white">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title white-bg">
                <h3 className="wow fadeInUp" data-wow-delay=".2s">
                  The Tezintel
                </h3>
                <h2 className="dot wow fadeInUp" data-wow-delay=".4s">
                  Advantage
                </h2>
                <p className="wow fadeInUp" data-wow-delay=".6s">
                  Our sales projection and forecasting tool is a better way of getting the data you need to make more
                  strategic decisions about your business.
                </p>
              </div>
            </div>
          </div>

          <div className="row mt-4 advantages-gx-5">
            <div className="col-lg-4 col-md-6 col-12 wow fadeInUp" data-wow-delay=".2s">
              <div className="single-featuer advantage-card">
                <div className="advantage-icon">
                  <img src={expertInsightsIcon} alt="#" />
                </div>
                <h3>Expert Insights</h3>
                <p>
                  Our models are created by industry experts with decades of experience, delivering insights you can
                  trust.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 wow fadeInUp" data-wow-delay=".4s">
              <div className="single-featuer advantage-card">
                <div className="advantage-icon">
                  <img src={saveTimeIcon} alt="#" />
                </div>
                <h3>Save Time</h3>
                <p>
                  Time is money. And we save you time. We cut wait times down to minutes so you can make the right
                  decisions right now.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 wow fadeInUp" data-wow-delay=".6s">
              <div className="single-featuer advantage-card">
                <div className="advantage-icon">
                  <img src={detailedResultsIcon} alt="#" />
                </div>
                <h3>Detailed Results</h3>
                <p>
                  In-depth, easy-to-read reports give you valuable business and property insights to help you scale your
                  business confidently.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="get-started">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title dark-bg">
                <h2 className="wow fadeInUp" data-wow-delay=".2s">
                  Ready to Get Your Real-Time Commercial Real Estate Valuation?{' '}
                </h2>
                <p className="wow fadeInUp" data-wow-delay=".4s">
                  Our valuation tool gives you the powerful insights you need to make your next business moves.{' '}
                </p>
                <div className="button wow fadeInUp" data-wow-delay=".6s">
                  <a
                    className="btn btn-icon valuation-icon"
                    onClick={() => {
                      setIsModal(true);
                    }}
                  >
                    Get My Valuation
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CopyRightComponent />
    </div>
  );
}

export default ValuationPage;
