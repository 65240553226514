import React from 'react';
import ReactPlayer from 'react-player/youtube';
import '../assets/css/VideoViewModel.css';  

function VideoViewModel({ isVideoModel, setIsVideoModel, videoUrl }) {
  const toggle = () => {
    setIsVideoModel(!isVideoModel);
  };

  return (
    <div>
      {isVideoModel && (
        <div className="custom-modal-overlay">
          <div className="custom-modal">
            <div className="custom-modal-header">
              <button onClick={toggle} className="close-button">
                &times;
              </button>
            </div>
            <div className="custom-modal-body-video">
              <ReactPlayer url={videoUrl} className="videoPlayer" controls={true} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default VideoViewModel;
