import React from 'react';

import incomeIcon from '../../assets/images/income.svg';

import { useAppState } from '../../hooks/useAppState';

import laundryIcon from '../../assets/images/laundry.svg';
import parkingIcon from '../../assets/images/parking.svg';
import storageIcon from '../../assets/images/storage.svg';
import antennasIcon from '../../assets/images/antennas.svg';
import billboardsIcon from '../../assets/images/billboards.svg';
import featuresIcon from '../../assets/images/features.svg';

import { useForm } from 'react-hook-form';
import { Form, FormGroup, Label } from 'reactstrap';
import { WrappedInput } from '../../components/WrappedInput';
import GenericStepForm from './generic-step-form';
import { WrappedNumberInput } from '../../components/WrappedNumberInput';

function ExtraIncomeStep({ step, setStep }) {
  const [stepValues, setStepValues] = useAppState();

  const { register, setError, handleSubmit, formState, watch, reset } = useForm({
    defaultValues: stepValues,
    mode: 'onSubmit',
  });
  const { errors } = formState;

  const isRental = stepValues?.rentalType === 'True' ? false : true;

  return (
    <GenericStepForm step={step} setStep={setStep} submitHandler={handleSubmit}>
      <div className="section-header">
        <img src={incomeIcon} alt={''} />
        <h2 className="section-heading">Extra Income</h2>
      </div>
      <h4 className="section-sub-heading">If tenants pay extra for any of the following categories</h4>
      <div className="count-wrapper">
        <div className="row">
          <div className="col-lg-4">
            <div className="mb-4">
              <div className="heading-with-icon">
                <div className="options-image">
                  <img src={laundryIcon} alt={''} />
                </div>
                <h3>Laundry</h3>
              </div>
              <div className="count-options">
                <WrappedNumberInput
                  errors={errors}
                  registerhandler={register}
                  min={1}
                  max={100000}
                  required={false}
                  name={'laundry_income'}
                  className={'form-control count-amount w-100'}
                  placeholder="$ per month."
                  disabled={isRental}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="mb-4">
              <div className="heading-with-icon">
                <div className="options-image">
                  <img src={parkingIcon} alt={''} />
                </div>
                <h3>Parking</h3>
              </div>
              <div className="count-options">
                <WrappedNumberInput
                  errors={errors}
                  registerhandler={register}
                  min={1}
                  max={100000}
                  required={false}
                  name={'parking_income'}
                  className={'form-control count-amount w-100'}
                  placeholder="$ per month."
                  disabled={isRental}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="mb-4">
              <div className="heading-with-icon">
                <div className="options-image">
                  <img src={storageIcon} alt={''} />
                </div>
                <h3>Storage</h3>
              </div>
              <div className="count-options">
                <WrappedNumberInput
                  errors={errors}
                  registerhandler={register}
                  min={1}
                  max={100000}
                  required={false}
                  name={'storage_income'}
                  className={'form-control count-amount w-100'}
                  placeholder="$ per month."
                  disabled={isRental}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="mb-4">
              <div className="heading-with-icon">
                <div className="options-image">
                  <img src={antennasIcon} alt={''} />
                </div>
                <h3>Antennas</h3>
              </div>
              <div className="count-options">
                <WrappedNumberInput
                  errors={errors}
                  registerhandler={register}
                  min={1}
                  max={100000}
                  required={false}
                  name={'antennas_income'}
                  className={'form-control count-amount w-100'}
                  placeholder="$ per month."
                  disabled={isRental}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="mb-4">
              <div className="heading-with-icon">
                <div className="options-image">
                  <img src={billboardsIcon} alt={''} />
                </div>
                <h3>Billboards</h3>
              </div>
              <div className="count-options">
                <WrappedNumberInput
                  errors={errors}
                  registerhandler={register}
                  min={1}
                  max={100000}
                  required={false}
                  name={'billboards_income'}
                  className={'form-control count-amount w-100'}
                  placeholder="$ per month."
                  disabled={isRental}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="mb-4">
              <div className="heading-with-icon">
                <div className="options-image">
                  <img src={featuresIcon} alt={''} />
                </div>
                <h3>Other</h3>
              </div>
              <div className="count-options">
                <WrappedNumberInput
                  errors={errors}
                  registerhandler={register}
                  min={1}
                  max={100000}
                  required={false}
                  name={'other_income'}
                  className={'form-control count-amount  w-100'}
                  placeholder="$ per month."
                  disabled={isRental}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <FormGroup check>
            <div className="mb-3">
              <WrappedInput
                {...register('elec_bill_by_tenants')}
                type="checkbox"
                name={'elec_bill_by_tenants'}
                disabled={isRental}
              />
              <Label check>Tenants pay their own electric bills</Label>
            </div>
          </FormGroup>
          <FormGroup check>
            <WrappedInput
              {...register('heat_bill_by_tenants')}
              type="checkbox"
              name={'heat_bill_by_tenants'}
              disabled={isRental}
            />
            <Label check>Tenants pay their own heat bills</Label>
          </FormGroup>
        </div>
      </div>
    </GenericStepForm>
  );
}

export default ExtraIncomeStep;
