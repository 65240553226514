import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import Emitter from './Emitter';
import closeIcon from '../assets/images/close.svg';
import Countdown from './Countdown';
import axiosInstance from '../services/axio-service';
import { getMonthName } from '../utils/ObjectUtils';

function OfferModel({ isOffer, setIsOffer }) {
  const [expiryDate, setExpiryDate] = useState(null);
  const [expiryMonthYear, setExpiryMonthYear] = useState(null);
  const toggle = () => {
    setIsOffer(!isOffer);
  };

  const getOfferConfig = async () => {
    try {
      const res = await axiosInstance.get('report-offer-config/');

      setExpiryDate(new Date(res.data));

      let dataSplit = res.data.split('-');
      let month = getMonthName(Number(dataSplit[1]));

      setExpiryMonthYear(dataSplit[2] + ' ' + month);
    } catch (error) {}
  };

  useEffect(() => {
    getOfferConfig();
  }, []);

  const today = new Date();
  // const expiryDate = new Date('2024-06-30');

  return (
    <div>
      <Modal isOpen={isOffer && today < expiryDate} toggle={toggle} className="offerModel" centered>
        <div className="offerWrapper">
          <img src={closeIcon} onClick={() => setIsOffer(false)} className="closeIcon" width={20} />

          <p className="freeReport"> Unlock 2 FREE Reports When You Sign Up Today!</p>
          <p className="freeReportLimit">
            Limited time offer <span className="offerSpan">(Valid Till {expiryMonthYear})</span>
          </p>
          <Countdown expiryDate={expiryDate} />
          <ModalBody className="offerModelBody custom-modal-body">
            <h5 className="offerTitle"> Unlock Insights: Claim Your Free Reports Today!</h5>

            <p className="my-3 offerDesc">
              Dive into detailed analysis and expert findings with our special offer. Sign up now and get two
              comprehensive reports absolutely free hurry, this offer won’t last long!
            </p>

            <div className="singOfferUpBtnWrapper">
              <button
                className="singOfferUpBtn"
                onClick={(e) => {
                  Emitter.emit('show_signup');
                  setIsOffer(false);
                }}
              >
                Sign Up
              </button>
            </div>
          </ModalBody>
        </div>
      </Modal>
    </div>
  );
}

export default OfferModel;
