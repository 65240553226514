import React from 'react'
import { Spinner } from 'reactstrap';

const Loader = ({content}) => {
  return (
    <div>
        <div
        className="d-flex flex-row"
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          alignItems: 'center',
          justifyContent: 'center',
          background: 'rgba(0, 0, 0, 0.5)',
          zIndex:9999
        }}
      >
        <div>
          {' '}
          <span className="text-white">{content}</span>{' '}
        </div>
        <Spinner className="ms-2 text-white" color="secondary"></Spinner>
      </div>
    </div>
  )
}

export default Loader