import React, { useState } from 'react';
import { countries } from '../data/counties';

function CountryDropdown({ setValue, fieldName, watch }) {
  const handleChange = (e) => {
    setValue(fieldName, e.target.value);
  };

  return (
    <div>
      <select
        id="country"
        className="countrydropdown"
        placeholder="Select Country"
        value={watch(fieldName)}
        onChange={handleChange}
      >
        <option value="" disabled hidden>
          Select Country
        </option>

        {countries.map((country, index) => (
          <option key={index} value={country}>
            {country}
          </option>
        ))}
      </select>
    </div>
  );
}

export default CountryDropdown;
