import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo/logo.png';

import residentialapartment from '../../assets/images/screens/apartment.png';
import commercialaprtment from '../../assets/images/screens/gasstation.png';
import industrial from '../../assets/images/screens/grocery.png';
import mixuse from '../../assets/images/screens/mixuse.png';
import office from '../../assets/images/screens/office.png';
import retail from '../../assets/images/screens/retail.png';
import { useAppState } from '../../hooks/useAppState';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance, { axiosUnSecureInstance } from '../../services/axio-service';
const CustomSteps = ({ step, setStep }) => {
  const [featuresConfig, setfeaturesConfig] = useState(null);
   useEffect(() => {
    const getFeatures = async () => {
      const res = await axiosUnSecureInstance.get('/features/');
      setfeaturesConfig(res?.data);
    };
    getFeatures();
  }, []);

  const navigate = useNavigate();
  const [stepValues, setStepValues] = useAppState();

  useEffect(() => {
    if (stepValues.facType != undefined) setStep(1);
  }, [stepValues.facType]);
  return (
    <div className="fiststepcontainer">
      <div>
        <img
          src={logo}
          className="main-logo"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            navigate('/');
          }}
        />
      </div>
      <div>
        <p
          style={{
            fontSize: '24px',
            color: '#fff',
            margin: '30px 0px 60px ',
          }}
        >
          What kind of property do you want to value?
        </p>
      </div>
      <div className="fiststepchild1">
        <div
          className="stepscard"
          id="commercial-property"
          onClick={() => {
            featuresConfig?.commercial && setStepValues({ ...stepValues, ...{ facType: 'commercial' } });
          }}
        >
          <img src={commercialaprtment} alt="Commercial Tezintel" />
          <h3>
            {' '}
            Gas Station
            <span
              style={{
                fontSize: '12px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {featuresConfig?.commercial == false && '(Coming Soon)'}
            </span>{' '}
          </h3>
        </div>
        <div
          className="stepscard"
          id="apartment-property"
          onClick={() => {
            featuresConfig?.residential && setStepValues({ ...stepValues, ...{ facType: 'residential' } });
          }}
        >
          <img src={residentialapartment} alt="Apartment Tezintel" />
          <h3>
            Apartment{' '}
            <span
              style={{
                fontSize: '12px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {featuresConfig?.grocery == false && '(Coming Soon)'}
            </span>{' '}
          </h3>
        </div>
        <div
          className="stepscard"
          id="industrial-property"
          onClick={() => {
            featuresConfig?.grocery && setStepValues({ ...stepValues, ...{ facType: 'grocery' } });
          }}
        >
          <img src={industrial} alt="Apartment Tezintel" />
          <h3>
            Grocery{' '}
            <span
              style={{
                fontSize: '12px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {featuresConfig?.grocery == false && '(Coming Soon)'}
            </span>
          </h3>
        </div>
      </div>
      <div className="fiststepchild2">
        <div
          className="stepscard"
          onClick={() => {
            featuresConfig?.mixuse && setStepValues({ ...stepValues, ...{ facType: 'mixuse' } });
          }}
        >
          <img src={mixuse} alt="Apartment Tezintel" />
          <h3>
            Mix Use{' '}
            <span
              style={{
                fontSize: '12px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {featuresConfig?.mixuse == false && '(Coming Soon)'}
            </span>
          </h3>
        </div>
        <div
          className="stepscard"
          onClick={() => {
            featuresConfig?.office && setStepValues({ ...stepValues, ...{ facType: 'office' } });
          }}
        >
          <img src={office} alt="Apartment Tezintel" />
          <h3>
            Office{' '}
            <span
              style={{
                fontSize: '12px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {featuresConfig?.office == false && '(Coming Soon)'}
            </span>
          </h3>
        </div>
        <div
          className="stepscard"
          onClick={() => {
            featuresConfig?.retail && setStepValues({ ...stepValues, ...{ facType: 'retail' } });
          }}
        >
          <img src={retail} alt="Apartment Tezintel" />
          <h3>
            Retail{' '}
            <span
              style={{
                fontSize: '12px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {featuresConfig?.retail == false && '(Coming Soon)'}
            </span>
          </h3>
        </div>
      </div>
    </div>
  );
};

export default CustomSteps;
