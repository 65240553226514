import { Autocomplete, useLoadScript } from '@react-google-maps/api';
import React, { useState, useEffect } from 'react';
// import Link from "next/link";

import { HiOutlineChevronDoubleLeft, HiOutlineChevronDoubleRight } from 'react-icons/hi';

import axios from 'axios';

import logo from '../assets/images/logo/logo.png';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import axiosInstance, { axiosUnSecureInstance } from '../services/axio-service';

// import { Check } from "lucide-react";

import { ClipLoader } from 'react-spinners';
import { set, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import CryptoJS from 'crypto-js';
import { Link, useNavigate } from 'react-router-dom';
import MapComp from '../components/map/Map';
import { useAsyncFn } from '../hooks/useAsync';
import { useService } from '../hooks/State';
import { questionsService } from '../services/projects';
import { useAppState } from '../hooks/useAppState';
import { useAuth } from '../hooks/useAuth';

const CircleNavigation = ({ steps, currentStep, formData, onCircleClick, setError }) => {
  const isQuestionAnswered = (index) => {
    const step = steps[index];
    return formData.some((entry) => entry.question_id === step.id && entry.selected_answer_ids.length > 0);
  };

  const handleCircleClick = (index) => {
    if (index < currentStep && isQuestionAnswered(index)) {
      setError('');
      onCircleClick(index);
    } else if (index > currentStep) {
      const currentQuestion = steps[currentStep];
      const currentAnswer = formData.find((entry) => entry.question_id === currentQuestion.id);

      if (!currentAnswer || currentAnswer.selected_answer_ids.length === 0) {
        setError('Please select at least one option .');
      } else {
        setError('');
        onCircleClick(index);
      }
    } else {
      onCircleClick(index);
    }
  };

  return (
    <div className="flex justify-center gap-2 mb-4">
      {steps.map((step, index) => {
        const isActive = currentStep === index;
        const isAnswered = isQuestionAnswered(index);

        return (
          <button
            key={step.id}
            onClick={() => handleCircleClick(index)}
            className={`flex items-center justify-center w-8 h-8 rounded-full text-black font-bold transition-all duration-200 ${
              isActive ? 'bg-blue-700' : isAnswered ? 'bg-blue-500' : 'bg-gray-300'
            }`}
          >
            {index + 1}
          </button>
        );
      })}
    </div>
  );
};

const SiteSuitability = () => {
  const {
    questions,
    SuitabilityGeometry,
    submittedAnswers,
    suitabilityAnalysis,
    answers,
    logIn,
    loginLoading,
    loginError,
    suitabilityData,
    executeSuitabilityData,
    setLngLat,
    lngLat,
  } = useService();
  
  
  const navigate=useNavigate();

  const { user } = useAuth();
  console.log('user', user);
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState([]);
  const [steps, setSteps] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState('');
  const [userInfo, setUserInfo] = useState({
    email: '',
    password: '',
  });
  const [isRememberMe, setIsRememberMe] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(user);
  const [cancelLogin, setCancelLogin] = useState(true);
  const [autocomplete, setAutocomplete] = useState(null);
  const [autocompleteValue, setAutocompleteValue] = useState('');
  const defaultCenter = { lat: 40.7128, lng: -74.006 };
  const [center, setCenter] = useState(defaultCenter);
  const [zoom, setZoom] = useState(3);
  const [selectedType, setSelectedType] = useState('County');
  const [SubmittedAnswerselected, setSubmittedAnswers] = useState([]);
  const [SuitabilityGeometryselected, setSuitabilityGeometry] = useState([]);
  const [SuitabilityGeometryId, setSuitabilityGeometryId] = useState(null);
  const [removeBoundary, setRemoveBoundary] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mainLoading, setMainLoading] = useState(false);
  const [hexagonData, setHexagonData] = useState(null);
  const [showMarker, setShowMarker] = useState(false);
  const [loginStatus, setLoginStatus] = useState(false);
  const [isOTPModel, setIsOTPModel] = useState(false);

  const libraries = ['places'];

  const secretKey = process.env.NEXT_PUBLIC_REMEMBER_ME_PASS_KEY;

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey : process.env.REACT_APP_GOOGLE_TOKEN,
    libraries,
  });

  const handleOptionChange = (answer_id) => {
    setError('');
    const currentQuestion = steps[currentStep];

    if (!currentQuestion) return;

    if (currentQuestion.allowMultiple) {
      const selectedQuestion = formData.find((entry) => entry.question_id === currentQuestion.id);
      let updatedAnswers = [];

      if (selectedQuestion) {
        updatedAnswers = selectedQuestion.selected_answer_ids.includes(answer_id)
          ? selectedQuestion.selected_answer_ids.filter((id) => id !== answer_id)
          : [...selectedQuestion.selected_answer_ids, answer_id];

        setFormData(
          formData.map((entry) =>
            entry.question_id === currentQuestion.id ? { ...entry, selected_answer_ids: updatedAnswers } : entry
          )
        );
      } else {
        setFormData([...formData, { question_id: currentQuestion.id, selected_answer_ids: [answer_id] }]);
      }
    } else {
      setFormData([
        ...formData.filter((entry) => entry.question_id !== currentQuestion.id),
        { question_id: currentQuestion.id, selected_answer_ids: [answer_id] },
      ]);
    }
  };

  const handleNext = () => {
    const currentQuestion = steps[currentStep].id;
    const questionData = formData.find((entry) => entry.question_id === currentQuestion);

    if (!questionData || questionData?.selected_answer_ids?.length === 0) {
      setError('Please select at least one option.');
      return;
    }

    if (currentStep < steps?.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      if (user) {
        console.log('checkkkkkk', user);
        handleSubmit();
        setFormData([]);
      } else {
        console.log('anotherr');
      }
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleAutocompleteLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };

  const handlePlaceChanged = async () => {
    if (autocomplete) {
      console.log('autocomplete', autocomplete);
      const place = autocomplete.getPlace();
      if (place.geometry) {
        const newCenter = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        setCenter({ lat: newCenter.lat, lng: newCenter.lng });
        setAutocompleteValue(place.formatted_address);

        const data = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
          type: selectedType,
        };
        try {
          setLoading(true);
          const response = await questionsService.suitablityGeometry(data);

          setShowMarker(true);
          setSuitabilityGeometry(response.data.data.county_geom);
          setSuitabilityGeometryId(response.data.data.county_geom.id);
        } catch (error) {
          console.error('Error submitting data:', error);
        } finally {
          setLoading(false);
        }
      }
    }
  };

  const handleAutocompleteChange = (e) => {
    setAutocompleteValue(e.target.value);
    setHexagonData(null);
    setSuitabilityGeometry(null);
    setSuitabilityGeometryId(null);
    setRemoveBoundary(false);
  };

  const fetchAddressFromLngLat = async (lngLat) => {
    const geocoder = new google.maps.Geocoder();
    console.log('geocoder', geocoder);
    geocoder.geocode({ location: lngLat }, async (results, status) => {
      if (status === 'OK') {
        if (results[4]) {
          console.log('results', results);
          setAutocompleteValue(results[4]?.formatted_address);
          const data = {
            lat: lngLat.lat,
            lng: lngLat.lng,
            type: selectedType,
          };

          try {
            setLoading(true);
            const response = await questionsService.suitablityGeometry(data);
            console.log('new', response.data.county_geom);
            setShowMarker(true);
            setRemoveBoundary(false);
            setHexagonData(null);

            setSuitabilityGeometry(response.data.data.county_geom);
            setSuitabilityGeometryId(response.data.data.county_geom.id);
          } catch (error) {
            console.error('Error submitting data:', error);
          } finally {
            setLoading(false);
          }
        } else {
          console.error('No results found');
        }
      } else {
        console.error('Geocoder failed due to: ' + status);
      }
    });
  };

  useEffect(() => {
    if (lngLat) {
      setCenter({ lat: lngLat.lat, lng: lngLat.lng });
      fetchAddressFromLngLat(lngLat);
    }
  }, [lngLat]);

  useEffect(() => {
    if (questions) {
      const fetchedSteps = questions?.data?.results?.map((question) => ({
        id: question.id,
        question: question.question_text,
        options: question.answers.map((answer) => ({
          answer_id: answer.id,
          answer_text: answer.answer_text,
        })),
        allowMultiple: question.allow_multiple_selections,
      }));

      setSteps(fetchedSteps);
    }
  }, [questions]);

  const handleSubmit = async () => {
    console.log('submit');
    // Filter unanswered questions
    const unansweredQuestions = steps.filter(
      (step) => !formData.some((entry) => entry.question_id === step.id && entry?.selected_answer_ids?.length > 0)
    );
    const combinedAnswers = formData.map((entry) => ({
      question_id: entry.question_id,
      selected_answer_ids: entry.selected_answer_ids,
    }));

    const finalData = {
      answers: combinedAnswers,
    };

    try {
      setLoading(true);
      setHexagonData(null);
      const response = await questionsService.submitAnswers(finalData);

      if (response) {
        let submittedQuestionData = await questionsService.getSubmittedAnswers(response?.data?.object_id);

        submittedQuestionData = submittedQuestionData?.data?.results;
        const res = await axiosUnSecureInstance.post(`suitability-analysis/?id=${SuitabilityGeometryId}`, {
          data: submittedQuestionData,
        });
        if (res) {
          setHexagonData(res.data.data);
          setCurrentStep(0);
          toast.success('Data submitted successfully');

          // Reset form state
          setFormData([]);
          setCurrentStep(0);
          setRemoveBoundary(true);
        }
      }
    } catch (error) {
      if (error.response.status === 401) {
        toast.error('Please login to continue');
        localStorage.removeItem('access_token');
        localStorage.clear();
      }
      console.error('Error:', error);
      toast.error('There was an error during submission. Please try again.');
    } finally {
      setLoading(false);
      setRemoveBoundary(true);
    }
  };

  return (
    <div>
      <div className="flex flex-row h-screen overflow-hidden">
        {/* Sidebar and Form */}
        <div
          className={`transition-width duration-300 ease-in-out ${
            isSidebarOpen ? 'w-1/3' : 'w-0'
          } relative bg-blue-800 text-white`}
        >
          {/* Toggle button */}
          <div className="absolute top-1/2 -right-4 transform -translate-y-1/2" onClick={toggleSidebar}>
            {isSidebarOpen ? (
              <div className="bg-blue-800 p-2 mr-[5px] text-white rounded-full shadow-md cursor-pointer">
                <HiOutlineChevronDoubleLeft />
              </div>
            ) : (
              <div className="mr-[-20px] bg-blue-800 p-2 text-white rounded-full shadow-md cursor-pointer">
                <HiOutlineChevronDoubleRight />
              </div>
            )}
          </div>

          {/* Sidebar content */}
          <div className={`h-full p-4 overflow-y-auto ${isSidebarOpen ? 'block' : 'hidden'}`}>
            <div className="flex items-center justify-between mb-4">
              <img src={logo} alt="logo" className='cursor-pointer' width={170} onClick={() => navigate('/')} />
            </div>

            <div>
              {user && steps && steps?.length > 0 && (
                <CircleNavigation
                  steps={steps}
                  currentStep={currentStep}
                  formData={formData}
                  onCircleClick={(index) => setCurrentStep(index)}
                  setError={setError}
                />
              )}
            </div>

            <div className="w-full my-3 p-2 rounded-md">
              {isLoaded ? (
                <Autocomplete onLoad={handleAutocompleteLoad} onPlaceChanged={handlePlaceChanged}>
                  <input
                    type="text"
                    value={autocompleteValue}
                    onChange={handleAutocompleteChange}
                    placeholder="Enter your address"
                    className="w-full my-1 p-1 text-black border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                  />
                </Autocomplete>
              ) : (
                <p>Loading Google Map Geocoder...</p>
              )}
            </div>

            {user && steps && steps?.length > 0 && (
              <div className="space-y-4">
                <h2 className="text-xl mb-4 text-white">{steps[currentStep].question}</h2>
                <div className="space-y-2">
                  {steps[currentStep].options.map((option) => (
                    <button
                      key={option.answer_id}
                      onClick={() => handleOptionChange(option.answer_id)}
                      className={`py-2 mx-2 px-4 rounded-lg border ${
                        formData.find(
                          (entry) =>
                            entry.question_id === steps[currentStep].id &&
                            entry.selected_answer_ids.includes(option.answer_id)
                        )
                          ? 'bg-[#fff] text-blue-500'
                          : 'bg-blue-500 text-white'
                      }`}
                    >
                      {option.answer_text}
                    </button>
                  ))}
                </div>
              </div>
            )}
            {error && <p className="text-red-500 mt-2">{error}</p>}

            <div className="flex justify-between mt-10">
              <button
                onClick={handlePrevious}
                className={`p-2 stepperBtn text-black border rounded-lg ${
                  currentStep === 0 ? 'cursor-not-allowed opacity-50' : ''
                }`}
                disabled={currentStep === 0}
              >
                Previous
              </button>
              <button
                onClick={handleNext}
                className={`p-2  stepperBtn  text-white border rounded-lg ${
                  !selectedType || !autocompleteValue ? 'cursor-not-allowed opacity-50' : ''
                }`}
                disabled={!selectedType || !autocompleteValue}
              >
                {currentStep === steps?.length - 1 ? 'Submit' : 'Next'}
              </button>
            </div>
          </div>
        </div>

        {/* Map Component */}
        <div
          className={`transition-width duration-300 ease-in-out  ${
            isSidebarOpen ? 'w-2/3' : 'w-full ml-[30px]'
          } h-full`}
          style={{ width: isSidebarOpen ? 'calc(100% - 27rem)' : '100%' }}
        >
          <MapComp
            SuitabilityGeometryselected={SuitabilityGeometryselected}
            isLoggedIn={isLoggedIn}
            center={center}
            zoom={zoom}
            selectedType={selectedType}
            removeBoundary={removeBoundary}
            SuitabilityGeometryId={SuitabilityGeometryId}
            loading={loading}
            key={isSidebarOpen}
            setHexagonData={setHexagonData}
            hexagonData={hexagonData}
            autocompleteValue={autocompleteValue}
            showMarker={showMarker}
          />
        </div>
      </div>
    </div>
  );
};

export default SiteSuitability;
