import React from 'react';
import SubHeader from './sub-header';

const PrivacyPolicy = () => {
  return (
    <div>
      <div className="privacy-bg">
        <SubHeader />
        <div className="contentMain">
          <div className="contentPrivacy">
            <p className="agreementTitle">END USER AGREEMENT</p>

            <p className="ptitleDescirpiton">
              This End User License Agreement (“Agreement”) is an agreement between One Brother Investments, LLC
              (“Company,” “we,” “us” or “our”) and you or the entity that you represent for our Tezintel application and
              software (the “Software”).
            </p>
            <p className="ptitleDescirpiton">
              PLEASE READ THIS AGREEMENT CAREFULLY. IT CONTAINS IMPORTANT TERMS THAT AFFECT YOU AND YOUR USE OF THE
              SOFTWARE. BY INSTALLING, COPYING OR USING THE SOFTWARE, YOU AGREE TO BE BOUND BY THE TERMS OF THIS
              AGREEMENT. IF YOU DO NOT AGREE TO THESE TERMS, DO NOT INSTALL, COPY, OR USE THE SOFTWARE. IF YOU ARE
              ACCESSING OR USING THE SOFTWARE ON BEHALF OF ANY ENTITY OR OTHERWISE IN PERFORMANCE OF SERVICES FOR ANY
              ENTITY, YOU REPRESENT AND WARRANT THAT YOU ARE AUTHORIZED TO ACCEPT THIS AGREEMENT ON SUCH ENTITY’S
              BEHALF, THAT ACCEPTANCE OF THIS AGREEMENT AND USE OF THIS SOFTWARE WILL NOT VIOLATE ANY AGREEMENT YOU HAVE
              WITH SUCH ENTITY, AND THAT SUCH ENTITY AGREES TO INDEMNIFY YOU AND COMPANY FOR VIOLATIONS OF THIS
              AGREEMENT.
            </p>

            <p className="titleHeading">Disclaimer</p>
            <p className="ptitleDescirpiton">
              The contents and data of projections, forecasts, valuations, and analysis, and, market growth and/or
              development supplied by Tezintel are based on information furnished by and collected from third parties.
              Tezintel makes no warranty of that there has been no material change in the data since its generation,
              including but not limited to: merchantability and fitness for a particular purpose with regard to The
              contents and data of projections, forecasts, valuations, and analysis, and, market growth and/or
              development. Projections, forecasts, valuations, and analysis, and, market growth and/or development are
              always subject to change. The contents and data of projections, forecasts, valuations, and analysis, and,
              market growth and/or development provided by Tezintel is not intended to provide a guarantee of actual
              business performance. By making use of this service and data, the end-user understands and agrees that no
              guarantee or warranty is provided by Tezintel for said projections, forecasts, valuations, and analysis,
              and, market growth and/or development.
            </p>

            <p className="titleHeading">License Limitations</p>
            <p className="ptitleDescirpiton">
              As a user of Tezintel, you will not do, or authorize or permit any third party to do, any of the
              following: (a) distribute, sub-license, sell, assign, or otherwise transfer or make available the
              Software; (b) use the Software for any purpose other than the Permitted Purpose; (c) reverse engineer,
              decompile, disassemble or otherwise attempt to discover or re-create the source code for the Software; (d)
              modify, adapt, alter, improve or create any derivative works of the Software; (e) connect the Software
              with any other online services or use the Software in conjunction with other software or services not
              provided by or permitted by Company [in connection with the Company Services]; (f) remove, circumvent or
              create or use any workaround to any copy protection or security feature in or relating to the Software; or
              (g) remove, alter or obscure any copyright, trademark or other proprietary rights notice on or in the
              Software.
            </p>

            <p className="titleHeading">Ownership and Confidentiality</p>
            <p className="ptitleDescirpiton">
              The Software is licensed, not sold, to you. We own all right, title, and interest in and to the Software,
              including all copyright, patent and other intellectual property or other proprietary rights in the
              Software. No title to or ownership of the Software or any associated intellectual property or proprietary
              rights are transferred to you by this Agreement. You also acknowledge that when you download, log in
              online, install, and/or use the Software, we have the right to automatically collect information on your
              use of the Software. We may also require that you provide information regarding yourself in order to
              download, install or use the Software or certain features. By your using, installing, downloading, or
              providing information, you consent to us collecting information on your use of the Software.
            </p>

            <p className="titleHeading">Disclaimers</p>
            <p className="ptitleDescirpiton">
              THE SOFTWARE IS PROVIDED TO YOU ON AN “AS IS” BASIS AND WITH ALL FAULTS AND DEFECTS WITHOUT WARRANTIES OF
              ANY KIND, EITHER EXPRESS OR IMPLIED. COMPANY AND ITS LICENSORS DISCLAIM ALL OTHER WARRANTIES, EXPRESS OR
              IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
              PURPOSE, TITLE, AND NON-INFRINGEMENT. COMPANY DOES NOT REPRESENT OR WARRANT THAT THE SOFTWARE IS FREE OF
              BUGS, ERRORS, VIRUSES, OR OTHER DEFECTS OR THAT THE SOFTWARE WILL TRANSMIT DATA IN A SECURE MANNER.
              COMPANY AND ITS LICENSORS SHALL HAVE NO LIABILITY OF ANY KIND FOR THE USE OF OR INABILITY TO USE THE
              SOFTWARE. COMPANY AND ITS LICENSORS PROVIDES ANY WARRANTY OR REPRESENTATIONS OF ANY KIND THAT THE SOFTWARE
              WILL OPERATE WITHOUT INTERRUPTION, MEET ANY PERFORMANCE OR RELIABILITY STANDARDS, ACHIEVE ANY KIND OF
              INTENDED PURPOSE, BE COMPATIBLE WITH ANY OTHER SOFTWARE OR SYSTEM, OR HAVE ERRORS THAT CAN BE CORRECTED.
            </p>
            <p>
              Some jurisdictions do not allow the exclusion of implied warranties or limitations on how long an implied
              warranty may last. To the extent that those laws apply to this Agreement, the exclusions and limitations
              set forth above may not apply.
            </p>

            <p className="titleHeading">Indemnification</p>
            <p className="ptitleDescirpiton">
              You will defend, indemnify and hold harmless Company and its affiliates, independent contractors, service
              providers, suppliers, partners, resellers, distributors and consultants, and their respective directors,
              officers, employees and agents (collectively, the “Company Parties”) from and against any third party
              claims, suits or actions and any resulting damages, costs, liabilities and expenses (including, but not
              limited to, reasonable attorneys’ fees) arising out of or related to: (a) your use of, or inability to
              use, the Software; (b) your violation of any terms of this Agreement, or, (c) in the unlikely event that a
              third-party gains access to the Software illegally or without authorization and utilizes it for own means
              and/or collects information after such access.
            </p>

            <p className="titleHeading">Limitation of Liability</p>
            <p className="ptitleDescirpiton">
              IN NO EVENT SHALL ANY OF THE COMPANY PARTIES BE LIABLE FOR ANY DIRECT, SPECIAL, INDIRECT OR CONSEQUENTIAL
              DAMAGES, OR ANY OTHER DAMAGES OF ANY KIND, INCLUDING BUT NOT LIMITED TO LOSS OF USE, LOSS OF PROFITS OR
              LOSS OF DATA, WHETHER IN AN ACTION IN CONTRACT, TORT (INCLUDING BUT NOT LIMITED TO NEGLIGENCE) OR
              OTHERWISE, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OF OR INABILITY TO USE THE SOFTWARE, EVEN
              IF A COMPANY PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT SHALL THE AGGREGATE
              LIABILITY OF THE COMPANY PARTIES, WHETHER IN CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE, WHETHER
              ACTIVE, PASSIVE OR IMPUTED), PRODUCT LIABILITY, STRICT LIABILITY OR OTHER THEORY, ARISING OUT OF OR
              RELATING TO THIS AGREEMENT OR THE SOFTWARE EXCEED THE COMPENSATION YOU HAVE PAID, IF ANY, TO COMPANY FOR
              THE SOFTWARE.
            </p>
            <p className="ptitleDescirpiton">
              Some jurisdictions do not allow the exclusion or limitation of certain damages. To the extent that those
              laws apply to this Agreement, the exclusions and limitations set forth above may not apply.
            </p>
            <p className="titleHeading">Termination</p>
            <p className="ptitleDescirpiton">
              Company may terminate this Agreement upon (a) minor or material breach of any of the provisions within
              this Agreement by you, (b) you attempting to or taking a course of action involving the Software that we
              believe may be criminal or fraudulent, and/or, (c) you insist that we modify the Software for your use in
              a way which would have the Software be potentially used in a criminal or fraudulent manner, or do so
              yourself.
            </p>
            <p className="ptitleDescirpiton">
              In the event of the termination of this Agreement for any reason: (a) the license granted to you in this
              Agreement will terminate; (b) you must immediately cease all use of the Software and destroy or erase all
              copies of the Software in your possession or control.
            </p>
            <p className="titleHeading">“Bug” Fixes, Changes, and Modification of the Software</p>
            <p className="ptitleDescirpiton">
              While the Software is provided “as is,” installation and use issues (“bugs”) are common, and such issues
              often require a software fix. You understand and agree that from time to time, we can and will update,
              modify, and change Tezintel to resolve bugs and modify features. You agree to our right having to do this,
              including if future versions of Tezintel may not have the same features as the version which you
              originally installed.
            </p>
            <p className="titleHeading"> Governing Law; Jurisdiction; Mandatory Arbitration</p>
            <p className="ptitleDescirpiton">
              Unless expressly prohibited by local law, this Agreement is governed by the laws of the State of Texas
              without regard to any conflict of law principles to the contrary. You hereby irrevocably consent to
              jurisdiction of the state and federal courts located in Harris County with respect to any proceeding
              regarding this Agreement or the Software. The 1980 UN Convention for the International Sale of Goods or
              any successor thereto does not apply to this Agreement. You will not prosecute any action, suit,
              proceeding, or claim arising under or by reason of this Agreement or the Software except in such
              jurisdiction.
            </p>
            <p className="ptitleDescirpiton">
              In lieu of a lawsuit or any legal action in regards to any dispute arising from this Agreement or any
              action derived or related to/from it, you agree to legally binding arbitration by a neutral third party
              from the Texas chapter of the American Association Arbitration in Harris County, Texas.
            </p>
            <p className="ptitleDescirpiton">
              Arbitration is a type of alternative dispute resolution, which provides a way to settle claims without
              litigation. Unlike litigation, arbitration takes place before an impartial third party (normally called an
              arbitrator), and the parties have the ability to present evidence and testimony at a hearing before the
              arbitrator. The arbitrator then makes a legally binding decision. Arbitration typically has a more relaxed
              application of rules of evidence and procedure, and, tends to occur in a less formal setting. Typically,
              each party pays half of the arbitration fees and costs, which can be significant.
            </p>
            <p className="ptitleDescirpiton">
              Each party shall bear its own attorney fees and legal costs in connection with the aforementioned
              arbitration. You also expressly agree that the Federal Arbitration Act (FAA) shall supersede and override
              the Texas Arbitration Act (TAA), and that any ruling made within the Arbitration may be submitted to any
              Texas state court of competent jurisdiction for enforcement thereof.
            </p>

            <p className="titleHeading"> Non-Defamation</p>
            <p className="ptitleDescirpiton">
              You agree that you will not (nor will you cause or cooperate with others to) publicly criticize, ridicule,
              misrepresent, disparage or defame us or any of its attorneys, employees, contractors, services, results of
              your particular case, or our legal services in general, via any written or oral statement or image
              including but not limited to any statements made via websites, blogs, general postings on the Internet, or
              emails, regardless of whether or not they are made anonymously or through the use of a pseudonym. You
              agree to provide full cooperation and assistance in assisting us to investigate such statements if we
              believe that you are the source of the statements or that you may have information to help track down the
              source. The foregoing does not apply to statutorily privileged statements made to governmental or law
              enforcement agencies. Violation of this clause shall be deemed a breach of contract and actionable, with
              all actual and exemplary damages and attorney and legal fees attaching as allowed under law. You agree
              that you have had a meaningful opportunity to negotiate the standardized terms of this clause and the
              contract in general.
            </p>

            <p className="titleHeading"> General</p>
            <p className="ptitleDescirpiton">
              (a) <a href=""> Legal Compliance; Export Restrictions. </a>
              You represent and warrant that (i) you are not located in a country that is subject to a United States
              embargo, or that has been designated by the United States government as a “terrorist supporting” country;
              and (ii) you are not listed on any United States government list of prohibited or restricted parties. The
              Software and related technology are subject to applicable United States export laws and regulations. You
              must comply with all applicable laws and regulations, including without limitation all applicable United
              States and international export laws and regulations, with respect to the Software and related technology.
              Without limitation, you may not export, re-export or otherwise transfer the Software or related
              technology, without a United States government license: (i) to any person or entity on any United States
              export control list; (ii) to any country subject to a United States sanctions; or (iii) for any prohibited
              end use.
            </p>
            <p className="ptitleDescirpiton">
              (b) <a href="">U.S. Government License Rights.</a> If acquired by any agency of the United States
              government, such agency acknowledges that (i) the Software constitutes “commercial computer software” or
              “commercial computer software documentation” for purposes of 48 C.F.R. 12.212 and 48 C.F.R. 227.7202-3, as
              applicable, and (ii) such agency’s rights are limited to those specifically granted to you pursuant to
              this Agreement.
            </p>
            <p className="ptitleDescirpiton">
              (c) <a href="">Severability.</a> If any provision of this Agreement is held by a court of competent
              jurisdiction to be invalid, illegal, or unenforceable, the remainder of this Agreement will remain in full
              force and effect, and the remaining provisions will be amended to achieve as closely as possible the
              effect of the original term and all other provisions of this Agreement will continue in full force.
            </p>
            <p className="ptitleDescirpiton">
              (d) <a href=""> Assignment.</a> You may not assign or otherwise transfer this Agreement, or assign,
              sublicense or otherwise transfer any of your rights under this Agreement without the prior written consent
              of Company, and any attempted assignment without such consent will be void.
            </p>
            <p className="ptitleDescirpiton">
              (e) <a href=""> Attorney’s Fees.</a> In any action or proceeding to enforce rights under this Agreement,
              the prevailing party will be entitled to recover costs and reasonable attorney’s fees.
            </p>
            <p className="ptitleDescirpiton">
              (f) <a href=""> Entire Agreement. </a>This Agreement sets forth our entire agreement with respect to the
              Software and supersedes all prior and contemporaneous understandings and agreements with respect to the
              Software whether written or oral.
            </p>
            <p className="titleHeading">DISCLAIMER</p>
            <p className="ptitleDescirpiton">
              By clicking OKAY below, you confirm and agree that Tezintel makes no warranty and you understand and make
              no demand from Tezintel that the contents and data provided herein has not experienced material changes
              since its generation. By continuing to use this application, user understands and agrees that Tezintel
              makes no warranty of merchantability, warranty for fitness for a particular purpose, or any other inherent
              or statutory warranty in regards to this content and data contained herein. The content and data contained
              herein is not intended to provide a guarantee of actual performance or outcome. If you do not agree with
              this, then please do not click OKAY, and, contact Tezintel.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
