import React, { useEffect, useRef } from 'react';
import MyProfile from '../components/UserNavbar';

import { useForm } from 'react-hook-form';
import { useState } from 'react';

import { Button } from 'reactstrap';
import { Row, Col } from 'reactstrap';
import { WrappedInput } from '../components/WrappedInput';
import CopyRightComponent from './copyright';
import plusicon from '../assets/images/plus-icon.png';
import myprofilelogo from '../assets//images/myprofile-logo2.png';
import editsymbol from '../assets/images/editsymbol.png';
import axiosInstance from '../services/axio-service';
import { toast } from 'react-toastify';
import Loader from '../components/Loader';
import CountryDropdown from '../components/CountryDropdown';
import { WrappedNumberInput } from '../components/WrappedNumberInput';
import { yupResolver } from '@hookform/resolvers/yup';
import { profileValidationSchema } from '../validation';
import PhoneInput from 'react-phone-input-2';

const Userprofile = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
    trigger,
  } = useForm({
    resolver: yupResolver(profileValidationSchema),
  });

  const [businessName, setBusinessName] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [addAdditionalTags, setAddAdditionalTags] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [suggestedTags, setSuggestedTags] = useState(null);
  const [addAddTag, setaddTags] = useState('');

  const [profileImg, setProfileImg] = useState();
  const [previewImg, setPreviewImg] = useState(myprofilelogo);

  const addTag = (tag) => {
    if (!selectedTags?.includes(tag)) {
      setSelectedTags([...selectedTags, tag]);
    }
  };

  const removeTag = (tag) => {
    setSelectedTags(selectedTags.filter((t) => t !== tag));
  };

  const profileUpdateSumbit = (data) => {
    // const newData = {
    //   pointOfContact: data?.pointOfContact,
    //   companyName: data?.companyName,
    //   phoneNumber: data?.phoneNumber,
    //   address: data?.address,
    //   email: '',
    //   lead: '',
    //   tags: selectedTags,
    // };

    const newData = {
      ...data,
      tags: selectedTags,
      lead: '',
      point_of_contact: '',
    };

    const formData = new FormData();
    formData.append('profile', profileImg);
    formData.append('newData', JSON.stringify(newData));
    setIsSubmitting(true);
    axiosInstance
      .post('vendorprofile/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        toast.success('Profile is Updated');
      })
      .catch((err) => {
        toast.error('Something went wrong when updating profile');
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const fileInputRef = useRef(null);

  const handleFileUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];

      if (!validImageTypes.includes(selectedFile.type)) {
        toast.error('Please upload a valid image file (.png, .jpg, .jpeg).');
        return;
      }

      const fileExtension = selectedFile.name.split('.').pop().toLowerCase();
      if (!['png', 'jpg', 'jpeg'].includes(fileExtension)) {
        toast.error('Please upload a valid image file (.png, .jpg, .jpeg).');
        return;
      }

      setProfileImg(selectedFile);
      setPreviewImg(URL.createObjectURL(selectedFile));
    }
  };

  useEffect(() => {
    setBusinessName(JSON.parse(localStorage.getItem('userData'))?.business_name);

    const requests = [axiosInstance.get('vendorprofile/'), axiosInstance.get('vendortags/')];

    Promise.all(requests)
      .then((responses) => {
        const [profileRes, tagsRes] = responses;

        const profileData = profileRes?.data;
        setValue('phone_number', profileData?.phone_number);
        const tagsData = tagsRes?.data;
        // local
        const profile = `${process.env.REACT_APP_SERVER_BASE_URL}/api${profileData.profile}`;

        profileData &&
          Object.keys(profileData).forEach((key) => {
            if (key !== 'tags' && key !== 'lead' && key !== 'profile') {
              setValue(key, profileData[key]);
            }
          });

        // for production use direct profileData.profile

        if (profileData.profile) {
          setPreviewImg(profileData.profile);
        } else {
          setProfileImg(myprofilelogo);
        }

        if (Array.isArray(profileData?.tags)) {
          setSelectedTags(profileData.tags);
        }

        setSuggestedTags(tagsData);
      })
      .catch((errors) => {
        console.error('Error fetching data:', errors);
      });
  }, []);

  return (
    <>
      <div className="copyright_wrapper">
        <MyProfile />
        {isSubmitting && <Loader content={'Submitting'} />}
        <div className="leadsWrapper" style={{ position: 'relative' }}>
          <form onSubmit={handleSubmit(profileUpdateSumbit)}>
            <div className=" ">
              <div
                className="mt-3  "
                style={{
                  fontWeight: 'bold',
                  fontSize: '24px',
                  color: '#243140',
                  display: 'flex',
                }}
              >
                <p className="  leadsTitle container">
                  Vendor Profile<span>.</span>
                </p>
              </div>
              <hr
                style={{
                  marginTop: '0px',
                }}
              />
              <div className="container">
                <div className="firstRowWrapper">
                  <div className="profileimages ">
                    <div className="profileimages ">
                      <div className="profile-picture" onClick={handleFileUploadClick}>
                        <div className="editSymbol">
                          <img src={editsymbol} width={30} height={30} />
                        </div>

                        <img src={previewImg} height={100} width={100} style={{ marginTop: '-1rem' }} />

                        <input
                          type="file"
                          id="profile-upload"
                          style={{ display: 'none' }}
                          ref={fileInputRef}
                          onChange={handleFileChange}
                        />
                      </div>

                      <div className="mt-4">
                        <h6
                          style={{
                            fontSize: '14px',
                            fontWeight: '400',
                            color: '#9BA2A8',
                          }}
                        >
                          {businessName}
                        </h6>
                      </div>
                    </div>

                    <div className="mt-4">
                      <h6
                        style={{
                          fontSize: '14px',
                          fontWeight: '400',
                          color: '#9BA2A8',
                        }}
                      >
                        {businessName}
                      </h6>
                    </div>
                  </div>
                  <div>
                    <Row className="mb-4">
                      <Col>
                        <WrappedInput
                          className={`form-control form-control-lg   ${errors?.company_name ? 'is-invalid' : ''}`}
                          type="text"
                          name="company_name"
                          placeholder="Company Name"
                          onChange={(e) => setValue('company_name', e.target.value)}
                          {...register('company_name')}
                        />
                        {errors?.company_name && <p className="errorMsg text-red">{errors?.company_name?.message}</p>}
                      </Col>
                      <Col>
                        <WrappedInput
                          className={`form-control form-control-lg   ${
                            errors?.service_description ? 'is-invalid' : ''
                          }`}
                          type="text"
                          name="service_description"
                          placeholder="Service Description"
                          onChange={(e) => setValue('service_description', e.target.value)}
                          {...register('service_description')}
                        />
                        {errors?.service_description && (
                          <p className="errorMsg">{errors?.service_description?.message}</p>
                        )}
                      </Col>
                    </Row>
                  </div>
                </div>

                <div className="my-1">
                  <div className=" formdisplay">
                    <Row className=" ">
                      <div className="businnessHr">
                        <p
                          style={{
                            color: '#243140',
                            fontWeight: 700,
                            fontSize: '16px',
                          }}
                        >
                          Point of Contact
                        </p>

                        <hr />
                      </div>
                      <Row className="">
                        <Col md={4} lg={3}>
                          <WrappedInput
                            className={`form-control form-control-lg   ${errors?.first_name ? 'is-invalid' : ''}`}
                            type="text"
                            name="first_name"
                            placeholder="First Name"
                            onChange={(e) => setValue('first_name', e.target.value)}
                            {...register('first_name')}
                          />
                          {errors?.first_name && <p className="errorMsg">{errors?.first_name?.message}</p>}
                        </Col>
                        <Col md={4} lg={3}>
                          <WrappedInput
                            className={`form-control form-control-lg   ${errors?.last_name ? 'is-invalid' : ''}`}
                            type="text"
                            name="last_name"
                            placeholder="Last Name"
                            onChange={(e) => setValue('last_name', e.target.value)}
                            {...register('last_name')}
                          />
                          {errors?.last_name && <p className="errorMsg">{errors?.last_name?.message}</p>}
                        </Col>
                        <Col md={4} lg={3}>
                          <PhoneInput
                            className="phoneNumber"
                            country={'us'}
                            value={watch('phone_number')}
                            onChange={(value) => {
                              setValue('phone_number', value);
                            }}
                            onFocus={() => {
                              trigger('phone_number');
                            }}
                            onBlur={() => {
                              trigger('phone_number');
                            }}
                            inputProps={{
                              required: true,
                            }}
                          />
                          {errors?.phone_number && <p className="errorMsg">{errors?.phone_number?.message}</p>}
                        </Col>
                        <Col lg={3}>
                          <WrappedInput
                            className={`form-control form-control-lg   ${errors?.email ? 'is-invalid' : ''}`}
                            type="text"
                            name="email"
                            placeholder="Email"
                            onChange={(e) => setValue('email', e.target.value)}
                            {...register('email')}
                          />
                          {errors?.email && <p className="errorMsg">{errors?.email?.message}</p>}
                        </Col>
                      </Row>

                      <div className="serviceHr">
                        <p
                          style={{
                            color: '#243140',
                            fontWeight: 700,
                            fontSize: '16px',
                          }}
                        >
                          Address
                        </p>

                        <hr />
                      </div>
                      <Row className="mb-2">
                        <Col md={6} lg={4}>
                          <WrappedInput
                            className={`form-control form-control-lg   ${errors?.address ? 'is-invalid' : ''}`}
                            type="text"
                            name="address"
                            placeholder="Address"
                            onChange={(e) => setValue('address', e.target.value)}
                            {...register('address')}
                          />
                          {errors?.address && <p className="errorMsg">{errors?.address?.message}</p>}
                        </Col>
                        <Col md={6} lg={4}>
                          <WrappedInput
                            className={`form-control form-control-lg   ${errors?.city ? 'is-invalid' : ''}`}
                            type="text"
                            name="city"
                            placeholder="City"
                            onChange={(e) => setValue('city', e.target.value)}
                            {...register('city')}
                          />
                          {errors?.city && <p className="errorMsg">{errors?.city?.message}</p>}
                        </Col>
                        <Col md={6} lg={4}>
                          <WrappedInput
                            className={`form-control form-control-lg   ${errors?.region ? 'is-invalid' : ''}`}
                            type="text"
                            name="region"
                            placeholder="Region"
                            onChange={(e) => setValue('region', e.target.value)}
                            {...register('region')}
                            onFocus={() => {
                              trigger('region');
                            }}
                            onBlur={() => {
                              trigger('region');
                            }}
                          />
                          {errors?.region && <p className="errorMsg">{errors?.region?.message}</p>}
                        </Col>
                      </Row>
                      <Row className="mb-4">
                        <Col md={6} lg={4}>
                          <WrappedNumberInput
                            className={`form-control form-control-lg   ${errors?.postal_code ? 'is-invalid' : ''}`}
                            errors={errors}
                            registerhandler={register}
                            type="number"
                            name="postal_code"
                            placeholder="Postal Code"
                            onChange={(e) => setValue('postal_code', e.target.value)}
                            onFocus={() => {
                              trigger('postal_code');
                            }}
                            onBlur={() => {
                              trigger('postal_code');
                            }}
                          />
                          {/* {errors?.postal_code && <p className="errorMsg">{errors?.postal_code?.message}</p>} */}
                        </Col>
                        <Col md={6} lg={4}>
                          <CountryDropdown setValue={setValue} fieldName={'country'} watch={watch} />
                        </Col>
                        <Col md={6} lg={4} className="websiteCol">
                          <WrappedInput
                            className={`form-control form-control-lg   ${errors?.website ? 'is-invalid' : ''}`}
                            type="text"
                            name="website"
                            placeholder="Website"
                            onChange={(e) => setValue('website', e.target.value)}
                            {...register('website')}
                            onFocus={() => {
                              trigger('website');
                            }}
                            onBlur={() => {
                              trigger('website');
                            }}
                          />
                          {errors?.website && <p className="errorMsg">{errors?.website?.message}</p>}
                        </Col>
                      </Row>
                    </Row>
                  </div>
                  <div className="serviceHr">
                    <p
                      style={{
                        color: '#243140',
                        fontWeight: 700,
                        fontSize: '16px',
                      }}
                    >
                      Services
                    </p>

                    <hr />
                  </div>
                </div>
                <div className="vendortagcontainer">
                  <div className="mt-3">
                    <div
                      className="tag-container"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <div className="selected-tags">
                        {selectedTags &&
                          selectedTags.map(
                            (tag) =>
                              tag !== '' && (
                                <div key={tag} className="tag">
                                  {tag}
                                  <span className="remove-tag" onClick={() => removeTag(tag)}>
                                    &times;
                                  </span>
                                </div>
                              )
                          )}
                      </div>
                      <div
                        className="flex mt-0 mb-0 mb-lg-0 additionalservices"
                        style={{
                          display: 'flex',
                        }}
                      >
                        {addAdditionalTags ? (
                          <WrappedInput
                            className="form-control form-control-lg my-1 signUpField  "
                            type="name"
                            name="AddTags"
                            placeholder="AddTags"
                            {...register('addTags')}
                            onChange={(e) => {
                              setaddTags(e.target.value);
                            }}
                            onKeyDown={(e) => {
                              if (e.key == 'Enter') {
                                addTag(addAddTag);
                                setAddAdditionalTags(false);
                                setaddTags('');
                              }
                            }}
                          />
                        ) : (
                          <button
                            style={{
                              width: '100%',
                              height: '100%',
                              color: '#0497FE',
                              border: '1px solid #0497FE',
                              borderRadius: '10px',
                              marginTop: '10px',
                              fontWeight: 'bold',
                            }}
                            className="mb-lg-3"
                            onClick={() => {
                              setAddAdditionalTags(true);
                            }}
                          >
                            Additional Services
                            <span>
                              <img src={plusicon} alt="Add Tag" className="plus-icon" />
                            </span>
                          </button>
                        )}
                      </div>
                      <div className="tagsWrapper">
                        <p
                          style={{
                            color: '#2B2C2D',
                            fontSize: '12px',
                            fontWeight: 'bold',
                          }}
                          className="suggestedTex"
                        >
                          Suggested based on your profile
                        </p>
                        <div className="suggested-tags tagbox mt-lg-3">
                          {suggestedTags?.map((item, index) => {
                            if (item.tag) {
                              return (
                                <div key={index} className="tag" onClick={() => addTag(item?.tag)}>
                                  {item?.tag}
                                  {item?.tag && (
                                    <span className="add-tag" onClick={() => addTag(item?.tag)}>
                                      <img src={plusicon} alt="Add Tag" className="plus-icon" />
                                    </span>
                                  )}
                                </div>
                              );
                            } else {
                              return null;
                            }
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="mt-4  mb-5 buttons"
                    style={{
                      display: 'flex',
                      gap: '8px',
                    }}
                  >
                    <Button
                      style={{
                        padding: '5px 10px',
                        backgroundColor: '#0497FE',
                        color: '#FFFFFF',
                        borderRadius: '10px',
                        width: '82px',
                        height: '37px',
                        border: 'none',
                      }}
                      type="submit"
                      className="update-button mb-3 mb-lg-0"
                    >
                      Update
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <CopyRightComponent />
      </div>
    </>
  );
};

export default Userprofile;
