import { useAppState } from '../../hooks/useAppState';
import { useForm } from 'react-hook-form';
import { mergeObjs } from '../../utils/ObjectUtils';
import { Form } from 'reactstrap';
import StepActions from './step-actions';
import { useEffect } from 'react';

function GenericStepForm({ step, setStep, children, submitHandler, formSubmitted, disablePrevious }) {
  const [stepValues, setStepValues] = useAppState();
  console.log('Step Values: ', stepValues);
  useEffect(() => {
    if (stepValues.stepSubmitInvoked === true) {
      submitHandler(
        function (data) {
          stepValues.stepStatus[step] = 'done';
          data.stepStatus = [...stepValues.stepStatus];
          setStepValues({ ...stepValues, ...data, ...{ stepSubmitInvoked: false } });
          setStep(stepValues.stepSubmitInvokedIndex);
        },
        function (error) {
          setStepValues({ ...stepValues, ...{ stepSubmitInvoked: false } });
        }
      )();
    }
  }, [stepValues.stepSubmitInvoked]);

  const submit = (data) => {
    if (formSubmitted) {
      formSubmitted(data);
      return;
    }

    stepValues.stepStatus[step] = 'done';
    data.stepStatus = [...stepValues.stepStatus];
    data.stepSubmitInvoked = false;
    setStepValues({ ...stepValues, ...data });
    setStep(step + 1);
  };

  return (
    <div>
      <section>
        <Form onSubmit={submitHandler(submit)} noValidate>
          <>{children}</>
          <StepActions
            disablePrevious={disablePrevious || false}
            disableNext={false}
            handlePrevious={() => {
              setStepValues({ ...stepValues, ...{ stepSubmitInvoked: false } });
              if (disablePrevious === undefined || disablePrevious === false) setStep(step - 1);
            }}
          />
        </Form>
      </section>
    </div>
  );
}

export default GenericStepForm;
