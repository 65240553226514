import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { Spinner } from 'reactstrap';
import { useEffect, useState } from 'react';
import { AUTH_CONSTANTS } from '../store/constant';

function PrivateRoute({ path, element }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { id ,read} = useParams();

  const [isUser, setUser] = useState(null);

  const [loading, setLoading] = useState(true);
  const { user, isSuperUser } = useAuth();

  const userInfo = JSON.parse(localStorage.getItem(AUTH_CONSTANTS.USER_DATA));

  useEffect(() => {
    const checkUser = () => {
      if (userInfo?.user_type === 'VENDOR') {
        if (
          pathname === '/profile' ||
          pathname === '/leads' ||
          pathname === '/submittedreports' ||
          pathname === `/lead-detail/${id}/${read}`
        ) {
          setUser(user);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } else if (isSuperUser) {
        if (pathname === '/vendor') {
          setUser(user);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } else {
        if (pathname === '/vendor' || pathname === '/profile' || pathname === '/leads') {
          setLoading(false);
        } else {
          setUser(user);
          setLoading(false);
        }
      }
    };

    const authenticationTimeout = setTimeout(checkUser, 1000);

    return () => {
      clearTimeout(authenticationTimeout);
    };
  }, [user, pathname, userInfo?.user_type]);

  useEffect(() => {
    if (!loading && !isUser) {
      navigate('/');
    }
  }, [loading, isUser, navigate, isSuperUser]);

  if (loading) {
    return (
      <div
        className="d-flex flex-row"
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          alignItems: 'center',
          justifyContent: 'center',
          background: 'rgba(0, 0, 0, 0.9)',
        }}
      >
        <div>
          {' '}
          <span className="text-white">Authenticating</span>{' '}
        </div>
        <Spinner className="ms-2 text-white" color="secondary"></Spinner>
      </div>
    );
  }

  return element;
}

export default PrivateRoute;
