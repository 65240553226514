import React, { useState, useEffect } from 'react';

const Countdown = ({ expiryDate }) => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const today = new Date();
      // const expiryDate = new Date('2024-06-30');
      const difference = expiryDate.getTime() - today.getTime();

      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      setTimeLeft({ days, hours, minutes, seconds });
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  return (
    <div>
      <div className="countdown">
        <p className="countdown-text">Offer Ends in:</p>
        <div className="countDownWrapper mt-1">
          <div>
            <p className="countdown-timer">
              {timeLeft.days} <br /> Days
            </p>
          </div>
          <div>
            <p className="countdown-timer">
              {timeLeft.hours} <br /> Hours
            </p>
          </div>
          <div>
            <p className="countdown-timer">
              {timeLeft.minutes} <br /> Minutes
            </p>
          </div>
          <div>
            <p className="countdown-timer">
              {timeLeft.seconds} <br /> Seconds
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Countdown;
