import { FormGroup, Input, Label } from 'reactstrap';
import React, { useState } from 'react';

import { v4 as uuidv4 } from 'uuid';

export const WrappedFileInput = React.forwardRef((props, ref) => {
  const [fileName, setFileName] = useState(props.initialLabel);
  const tId = uuidv4();
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFileName(file ? file.name : null);
    props?.onChange(event);
  };

  return (
    <div className="input-group ">
      <label htmlFor={`${tId}`} className="input-group-text" role="button">
        Chose File
      </label>
      <label className="form-control" role="button">
        {fileName || 'No file chosen'}
        <Input {...props} innerRef={ref} type="file" onChange={handleFileChange} id={`${tId}`} className="d-none" />
      </label>
    </div>
  );
});
