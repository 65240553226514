import { Link } from 'react-router-dom';
import React from 'react';

import projectionL1Icon from '../assets/images/use-cases/projection-l1.jpg';
import convenienceStoresIcon from '../assets/images/use-cases/stores.png';

import fastFoodIcon from '../assets/images/use-cases/food.png';
import carWashIcon from '../assets/images/use-cases/car-wash.png';
import travelCentersIcon from '../assets/images/use-cases/travel-centers.png';
import quickServiceRestaurantIcon from '../assets/images/use-cases/restaurant.png';
import liquorStoresIcon from '../assets/images/use-cases/liquor-stores.png';
import truckStopsIcon from '../assets/images/use-cases/truck.png';
import fuelStationsIcon from '../assets/images/use-cases/fuel-stations.png';

import expertInsightsIcon from '../assets/images/advantages/expert-insights.png';
import saveTimeIcon from '../assets/images/advantages/save-time.png';
import detailedResultsIcon from '../assets/images/advantages/detailed-results.png';
import Privacy from '../components/privacy';
import propertyOwnerIcon from '../assets/images/use-cases/property-owner.png';
import prospectiveBuyersIcon from '../assets/images/use-cases/buyers.png';
import lendersIcon from '../assets/images/use-cases/lenders.png';
import realEstateAgentsIcon from '../assets/images/use-cases/agents.png';
import SubHeader from './sub-header';
import CopyRightComponent from './copyright';

function ProjectionPage() {
  const [isModal, setIsModal] = React.useState(false);

  return (
    <div>
      <Privacy isModal={isModal} setIsModal={setIsModal} />
      <section className=" projection-hero-area">
      <SubHeader />
      <div className='payment-hero-main'>
      <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 col-12">
              <div className=" ">
                <h2 className="wow fadeInUp contactUsTitle" data-wow-delay=".2s">
                  Real-Time Sales Forecasting For Your Business
                </h2>
                <p className="wow fadeInUp contactUsDesc" data-wow-delay=".3s">
                  We improve your business’s potential and predictability with on-demand sales projections
                </p>
                <div className="button wow fadeInUp" data-wow-delay=".4s">
                  <a href="#" className="btn btn-icon icon-projection"
                      onClick={() => {
                        setIsModal(true);
                      }}
                  >
                    GET MY PROJECTION & Evaluation
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     
      </section>
      <section className="section">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-md-8">
              <div className="section-title mb-0 white-bg">
                <h3 className="wow fadeInUp" data-wow-delay=".2s">
                  Don’t Leave
                </h3>
                <h2 className="dot wow fadeInUp" data-wow-delay=".4s">
                  Your Success To Chance
                </h2>
                <p className="wow fadeInUp" data-wow-delay=".6s">
                  With so much data available today at your fingertips, there is no reason to leave your success to
                  chance. But finding the data you need takes time and making sense of it can be confusing.
                </p>
                <p className="wow fadeInUp" data-wow-delay=".8s">
                  Whether you’re looking to scale your current on-site business operations, expand into new markets, or
                  build a new business from the ground up, Tezintel insights can help you make the right decisions at the
                  right time to fuel your growth.
                </p>
                <p className="wow fadeInUp" data-wow-delay="1s">
                  With Tezintel’s powerful projection and forecasting tool on your side, you never have to leave
                  important decisions to chance.{' '}
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <img className="wow fadeInUp" data-wow-delay=".2s" src={projectionL1Icon} />
            </div>
          </div>
        </div>
      </section>
      <section className="powerful-tools projection-banner-2">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="pt-5 section-title dark-bg">
                <h3 className="wow fadeInUp" data-wow-delay=".2s">
                  The Industry’s Most Powerful
                </h3>
                <h2 className="dot wow fadeInUp" data-wow-delay=".4s">
                  Sales Projection and Forecasting Tool
                </h2>
                <p className="wow fadeInUp" data-wow-delay=".6s">
                  We help businesses turn data into decisions with our powerful sales projection and forecasting tool.
                  Input key property details, such as location, type of business, service offerings, and more. We'll
                  deliver sales forecasts to empower informed decision-making, whether your business already exists or
                  you’re looking to buy or build.{' '}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="section-title dark-bg">
                <h3 className="wow fadeInUp" data-wow-delay=".2s">
                  In less than
                </h3>
                <h2 className="dot wow fadeInUp" data-wow-delay=".4s">
                  Ten Minutes
                </h2>
                <p className="wow fadeInUp" data-wow-delay=".6s">
                  Tezintel arms you with the deep industry insights to make strategic site and sales decisions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="use-cases">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title dark-bg">
                <h3 className="wow fadeInUp" data-wow-delay=".2s">
                  Diverse Audience
                </h3>
                <h2 className="dot wow fadeInUp" data-wow-delay=".4s">
                  Multiple Use Cases
                </h2>
              </div>
            </div>
          </div>
          <div className="row tez-gx-5">
            <div className="col-md-4">
              <div className="case wow fadeInUp" data-wow-delay=".2s">
                <h4 className="case-title">Convenience Stores</h4>
                <img src={convenienceStoresIcon} />
              </div>
            </div>
            <div className="col-md-4">
              <div className="case wow fadeInUp" data-wow-delay=".3s">
                <h4 className="case-title">Fast Food Restaurants</h4>
                <img src={fastFoodIcon} />
              </div>
            </div>
            <div className="col-md-4">
              <div className="case wow fadeInUp" data-wow-delay=".4s">
                <h4 className="case-title">Car Washes</h4>
                <img src={carWashIcon} />
              </div>
            </div>
            <div className="col-md-4">
              <div className="case wow fadeInUp" data-wow-delay=".5s">
                <h4 className="case-title">Travel Centers</h4>
                <img src={travelCentersIcon} />
              </div>
            </div>
            <div className="col-md-4">
              <div className="case wow fadeInUp" data-wow-delay=".6s">
                <h4 className="case-title">Quick Service Restaurant</h4>
                <img src={quickServiceRestaurantIcon} />
              </div>
            </div>
            <div className="col-md-4">
              <div className="case wow fadeInUp" data-wow-delay=".7s">
                <h4 className="case-title">Liquor Stores</h4>
                <img src={liquorStoresIcon} />
              </div>
            </div>
            <div className="col-md-4">
              <div className="case wow fadeInUp" data-wow-delay=".8s">
                <h4 className="case-title">Truck Stops</h4>
                <img src={truckStopsIcon} />
              </div>
            </div>
            <div className="col-md-4">
              <div className="case wow fadeInUp" data-wow-delay=".9s">
                <h4 className="case-title">Fuel Stations</h4>
                <img src={fuelStationsIcon} />
              </div>
            </div>
            <div className="col-md-4">
              <div className="case wow fadeInUp" data-wow-delay=".9s">
                <h4 className="case-title">Property Owners</h4>
                <img src={propertyOwnerIcon} />
              </div>
            </div>
            <div className="col-md-4">
              <div className="case wow fadeInUp" data-wow-delay=".9s">
                <h4 className="case-title">Prospective Buyers</h4>
                <img src={prospectiveBuyersIcon} />
              </div>
            </div>
            <div className="col-md-4">
              <div className="case wow fadeInUp" data-wow-delay=".9s">
                <h4 className="case-title">Lenders</h4>
                <img src={lendersIcon} />
              </div>
            </div>
            <div className="col-md-4">
              <div className="case wow fadeInUp" data-wow-delay=".9s">
                <h4 className="case-title">Commercial Real Estate Agents</h4>
                <img src={realEstateAgentsIcon} />
              </div>
            </div>
            
           
             
          </div>
        </div>
      </section>
      <section className="advantages-section bg-white">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title white-bg">
                <h3 className="wow fadeInUp" data-wow-delay=".2s">
                  The Tezintel
                </h3>
                <h2 className="dot wow fadeInUp" data-wow-delay=".4s">
                  Advantage
                </h2>
                <p className="wow fadeInUp" data-wow-delay=".6s">
                  Our sales projection and forecasting tool is a better way of getting the data you need to make more
                  strategic decisions about your business.
                </p>
              </div>
            </div>
          </div>

          <div className="row mt-4 advantages-gx-5">
            <div className="col-lg-4 col-md-6 col-12 wow fadeInUp" data-wow-delay=".2s">
              <div className="single-featuer advantage-card">
                <div className="advantage-icon">
                  <img src={expertInsightsIcon} alt="#" />
                </div>
                <h3>Expert Insights</h3>
                <p>
                  Our models are created by industry experts with decades of experience, delivering insights you can
                  trust.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 wow fadeInUp" data-wow-delay=".4s">
              <div className="single-featuer advantage-card">
                <div className="advantage-icon">
                  <img src={saveTimeIcon} alt="#" />
                </div>
                <h3>Save Time</h3>
                <p>
                  Time is money. And we save you time. We cut wait times down to minutes so you can make the right
                  decisions right now.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 wow fadeInUp" data-wow-delay=".6s">
              <div className="single-featuer advantage-card">
                <div className="advantage-icon">
                  <img src={detailedResultsIcon} alt="#" />
                </div>
                <h3>Detailed Results</h3>
                <p>
                  In-depth, easy-to-read reports give you valuable business and property insights to help you scale your
                  business confidently.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="get-started">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title dark-bg">
                <h2 className="wow fadeInUp" data-wow-delay=".2s">
                  Ready to Get the Sales and Site Insights to Drive New Business Potential?
                </h2>
                <p className="wow fadeInUp" data-wow-delay=".4s">
                  With our forecasting tool, get the powerful insights you need to scale your business to new heights.
                </p>
                <div className="button wow fadeInUp" data-wow-delay=".6s">
                  <Link className="btn btn-icon icon-projection" 
                      onClick={() => {
                        setIsModal(true);
                      }}
                  >
                    Get My Projection
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CopyRightComponent />
    </div>
  );
}

export default ProjectionPage;
