import React from 'react';
import errorImg from '../assets/images/page-notfound2.png';
import { useNavigate } from 'react-router-dom';
const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className=" errorMain ">
        <h1 className="oops text-center mb-3">OOPS...</h1>
        <div className="d-flex justify-content-center">
          <img src={errorImg} />
        </div>

        <h1 className="text-center p-0 m-0" style={{ fontSize: '32px' }}>
          Something went wrong
        </h1>

        <p
          className=" mt-3 text-center"
          style={{
            fontSize: '20px',
            fontWeight: 500,
          }}
        >
          We apologize, but the page you are attempting to access cannot be found.
        </p>
        <div className="d-flex justify-content-center">
          <button
            style={{
              padding: '7px 10px',
              backgroundColor: '#0497FE',
              color: '#FFFFFF',
              borderRadius: '10px',
              width: '82px',
              height: '37px',
              border: 'none',
            }}
            onClick={() => {
              navigate('/');
            }}
            className="      text-center "
          >
            Go Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
