import React from "react"
import OTPComponent from "../components/OTPComponent"

const Otp = () => {
    return(
        <>
         <OTPComponent/>
        </>
    )
}
export default Otp