import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import LoginBox from '../components/Login';
import '../assets/css/delete.css';
import { useAuth } from '../hooks/useAuth';
import axiosInstance from '../services/axio-service';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function DeleteUser() {
  const [token, setToken] = useState('');
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const { user, signOut } = useAuth(); // Assuming this gives you the user state
  const [isUser, setIsUser] = useState(false); // Corrected to useState

  const toggleLoginModal = () => {
    setIsLoginModalOpen(!isLoginModalOpen);
  };

  useEffect(() => {
    const storedToken = localStorage.getItem('accessToken');
    setToken(storedToken);
  }, []);

  // Set isUser when user changes
  useEffect(() => {
    setIsUser(!!user); // Convert user to boolean
  }, [user]); // Watch the user state

  // Close login modal when token changes (indicating a login attempt)
  useEffect(() => {
    if (token) {
      setIsLoginModalOpen(false); // Close modal if there's a token
    }
  }, [token]);

  let navigate = useNavigate();
  return (
    <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
      {user ? (
        <Button
          color="danger"
          onClick={async () => {
            try {
              let res = await axiosInstance.post('deleteuser/');

              if (res) {
                signOut();
                toast.success('Account Deleted Successfully');
                navigate('/');

              }
            } catch (error) {}
          }}
        >
          Delete Account
        </Button>
      ) : (
        <Button onClick={toggleLoginModal} style={{ margin: '10px' }}>
          Login
        </Button>
      )}

      <Modal isOpen={isLoginModalOpen} toggle={toggleLoginModal} centered>
        <ModalHeader toggle={toggleLoginModal}>Login</ModalHeader>
        <ModalBody>
          <LoginBox cb={() => setIsLoginModalOpen(false)} />
        </ModalBody>
      </Modal>
    </div>
  );
}

export default DeleteUser;
