import SubHeader from './sub-header';
import { FormGroup, Input, Label } from 'reactstrap';
import PricingPlan from '../components/PricingPlan';
import { useAuth } from '../hooks/useAuth';
import axiosInstance from '../services/axio-service';
import { URL_CONSTANTS } from '../store/constant';
import { toast } from 'react-toastify';
import { useState } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import CopyRightComponent from './copyright';
import '../assets/css/paymentCard.css';

import Privacy from '../components/privacy';

const Plans = ({ planContents, planSelected }) => {
  // const { user } = useAuth();
  const handlePlanSelection = (planKey) => {
    if (planKey === 'premium_member_plan') {
      window.location.href = '/contact-us'; 
    } else {
      planSelected(planKey); 
    }
  };


  const plans = planContents.map((obj, i) => {
    return (
      <div className={'planItem'} key={i}>
        <PricingPlan
          {...obj}
          planSelected={() => handlePlanSelection(obj.planKey)} 
        />
      </div>
    );
  });

  return <>{plans}</>;
};

function Payment() {
  const [isLoading, setIsLoading] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const planContents = [
    // {
    //   header: 'Free',
    //   planKey: 'free',
    //   price: 0,
    //   features: [],
    //   buttonLabel: 'Sign up for free',
    //   outline: true,
    //   pricePer: 'report',
    // },
    {
      header: 'Standard',
      subHeader: 'Discover Financial Ease with our  Standard Payment Plan',
      planKey: 'standard_member_plan',
      report: 1,
      price: 1495,
      features: [
        {
          availableFeature: [
            'Valuation report',
            'Forecasting report ',
            'Demographics report ',
            'Market trends report',
            'Near by analysis report ',
            'Traffic counts report ',
          ],
          notAvailableFature: [],
        },
      ],
      buttonLabel: 'Buy Now',
      outline: false,
      pricePer: '',
    },
    {
      header: 'Premium',
      subHeader: 'Due Diligence, Property Schematics, & Loan Processing',
      planKey: 'premium_member_plan',
      report: 5,
      price: "Contact Now for a Quote",
      features: [
        {
          availableFeature: [
            'Define and Summarize General Permit Requirements and Timelines',
            'Define General Grading and Drainage Requirements',
            'Visit Site and Document Existing Visible Characteristics and Features',
            'Review Survey and Title for Encroachments, Encumbrances, and Restrictions',
            'Planning Approval and Platting Process',
            'Zoning Information and Developer Requirements',
            'Architectural Requirements',
            'Utility Research',
            'Access, Driveways, and Right-of-Way',
            'Property Schematics',
          ],
          notAvailableFature: [],
        },
      ],
      buttonLabel: 'Contact Now',

      outline: false,
      pricePer: '',
    },
  ];

  return (
    <div>
      <Privacy isModal={isModal} setIsModal={setIsModal} />
      <section className="  contact-hero-area">
        <SubHeader />
        <div className='payment-hero-main'>
        <div className="container w-auto">
          <div className="row align-items-center">
            <div className="col-md-12 col-12">
              <div className="text-white">
                <h2 className="wow fadeInUp contactUsTitle paymentText" data-wow-delay=".2s">
                  Payments
                </h2>
                <p className="paysubText  ">Explore Our Flexible Payment Plans for Your Project</p>
              </div>
            </div>
          </div>
        </div>
        </div>
     
      </section>
      <section className="section form-section">
        <LoadingOverlay active={isLoading} spinner text="Processing">
          <div className={'container card-deck mb-3 text-center'}>
            <div className="paymentTextWrapper text-black">
              <p className="text-start  mb-3 p-0 chooseText text-black">Choose Your</p> <br />
              <p className="text-start  mb-3 paymentTextPlan text-black">
                Payment Plan<span>.</span>
              </p>
            </div>

            <div className={'planWrapper'}>
              <Plans
                planContents={planContents}
                planSelected={(planId, existingPackage) => {
                  setIsLoading(true);
                  axiosInstance
                    .post(`/create-checkout-session/`, { pricePlan: planId })
                    .then((response) => {
                      window.location = response.data?.redirectUrl;
                    })
                    .catch((e) => {
                      toast.error('Error occurred');
                    })
                    .finally(() => {
                      setIsLoading(false);
                    });
                }}
              />
            </div>
          </div>
        </LoadingOverlay>
      </section>
      <section className="get-started">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title dark-bg">
                <h2 className="wow fadeInUp" data-wow-delay=".4s">
                  Ready to Get the Insights You Need to Grow Your Business Today?
                </h2>
                <p className="wow fadeInUp" data-wow-delay=".6s">
                  Use our cutting-edge valuation and projection model to get the data you need to grow in minutes.
                </p>
                <div className="button wow fadeInUp" data-wow-delay=".8s">
                  <a href="#" className="btn btn-icon icon-home"
                   onClick={() => {
                    setIsModal(true);
                  }}
                  >
                    GET MY INSIGHTS
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CopyRightComponent />
    </div>
  );
}

export default Payment;
