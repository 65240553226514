import { Link } from 'react-router-dom';

import sec1Image from '../assets/images/sec-1.jpg';
import reValuationImage from '../assets/images/re-valuation.png';
import sForcastingImage from '../assets/images/s-forecasting.png';
import expertInsightsImage from '../assets/images/advantages/expert-insights.png';
import saveTimeImage from '../assets/images/advantages/save-time.png';
import detailedResultsImage from '../assets/images/advantages/detailed-results.png';

import CarouselComponent from './carousel';
import SubHeader from './sub-header';
import CopyRightComponent from './copyright';
import banner1 from '../assets/images/home-banner-1.jpg';
import banner2 from '../assets/images/home-banner-2.jpg';
import { useState } from 'react';
import Privacy from '../components/privacy';
import OfferModel from '../components/offerModel';

function Main() {
  const [isModal, setIsModal] = useState(false);
  const [isOffer, setIsOffer] = useState(true);

  document.body.style.paddingRight = '0px';

  return (
    <div>
      <Privacy isModal={isModal} setIsModal={setIsModal} />
      <OfferModel isOffer={isOffer} setIsOffer={setIsOffer} />
      <div className="home-banner-bg">
        <SubHeader />
        <section className="home-banner-bg-main ">
          <div className="container-lg text-white ">
            <div className=" ">
              <div className="">
                <div className="  d-flex gap-2 flex-column">
                  <h2 className="wow fadeInUp text-white header_title" data-wow-delay=".4s">
                    Data Insights to Fuel Your Business Growth
                  </h2>
                  <p className="wow fadeInUp header_descriptin header_subtitle" data-wow-delay=".6s">
                    We empower better business decisions through cutting-edge data science driven by our innovative
                    projection and valuation tools
                  </p>
                  <div className="button insightBtn wow fadeInUp" data-wow-delay=".8s">
                    <Link
                      className="btn btn-icon icon-home"
                      onClick={() => {
                        setIsModal(true);
                      }}
                    >
                      {' '}
                      GET MY INSIGHTS
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="section">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-md-8">
              <div className="section-title mb-0 white-bg">
                <h3 className="wow fadeInUp" data-wow-delay=".2s">
                  We Give You Data
                </h3>
                <h2 className="dot wow fadeInUp" data-wow-delay=".2s">
                  You Make Decisions
                </h2>
                <p className="wow fadeInUp" data-wow-delay=".2s">
                  In today's competitive marketplace, having a complete picture of your business's current value and
                  potential is crucial in boosting profits and gaining an advantage over the competition.
                </p>
                <p className="wow fadeInUp" data-wow-delay=".2s">
                  And data-driven decision-making is only one piece of the puzzle. Making timely decisions is another.
                </p>
                <p className="wow fadeInUp" data-wow-delay=".2s">
                  We empower better data-driven decision-making that gives you the power to act when you want to act,
                  not on someone else’s schedule while you’re waiting for time-consuming reports.
                </p>
                <p className="wow fadeInUp" data-wow-delay=".2s">
                  Our on-demand real estate valuation and sales forecasting and projection engine cuts your wait time
                  from weeks to minutes, arming you with the data you need to make decisions now--because boosting your
                  profits can’t always wait weeks or months.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <img className="wow fadeInUp" data-wow-delay=".2s" src={sec1Image} />
            </div>
          </div>
        </div>
      </section>

      <section className="call-action powerful-tools overlay">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title dark-bg">
                <h3 className="wow fadeInUp mt-5" data-wow-delay=".2s">
                  One Powerful Tool
                </h3>
                <h2 className="dot wow fadeInUp" data-wow-delay=".4s">
                  Multiple Use Cases
                </h2>
                <p className="wow fadeInUp" data-wow-delay=".6s">
                  Our valuation and projection engine is driven by data science and expert insights, giving you accurate
                  and timely information you can trust.
                </p>
              </div>
            </div>
          </div>
          <div className="row tools-gx-5">
            <div className="col-md-6">
              <div className="tools-card wow fadeInUp" data-wow-delay=".2s">
                <div className="d-flex align-items-center">
                  <div className="card-desc flex-grow-1">
                    <h3>Real Estate Valuation</h3>
                    <p>
                      You share your property details. We calculate its valuation. Whether you’re a real estate agent,
                      buyer, seller, or lender, get an accurate property valuation in minutes, not weeks.
                    </p>
                    {/* <a className="card-link cursor-pointer">Learn More</a> */}
                  </div>
                  <div className="card-image flex-shrink-0">
                    <img src={reValuationImage} alt="Real Estate Valuation" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="tools-card wow fadeInUp" data-wow-delay=".4s">
                <div className="d-flex align-items-center">
                  <div className="card-desc flex-grow-1">
                    <h3>Sales Forecasting</h3>
                    <p>
                      Input key business details such as location, product and service offerings, and more, and get
                      detailed insights that can help you make informed decisions.
                    </p>
                    {/* <a className="card-link cursor-pointer">Learn More</a> */}
                  </div>
                  <div className="card-image flex-shrink-0">
                    <img src={sForcastingImage} alt="Sales Forecasting" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="advantages-section bg-white">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title white-bg">
                <h3 className="wow fadeInUp" data-wow-delay=".2s">
                  The Tezintel
                </h3>
                <h2 className="dot wow fadeInUp" data-wow-delay=".4s">
                  Advantage
                </h2>
                <p className="wow fadeInUp" data-wow-delay=".6s">
                  Our sales projection and forecasting tool is a better way of getting the data you need to make more
                  strategic decisions about your business.
                </p>
              </div>
            </div>
          </div>

          <div className="row mt-4 advantages-gx-5">
            <div className="col-lg-4 col-md-6 col-12 wow fadeInUp" data-wow-delay=".2s">
              <div className="single-featuer advantage-card">
                <div className="advantage-icon">
                  <img src={expertInsightsImage} alt="#" />
                </div>
                <h3>Expert Insights</h3>
                <p>
                  Our models are created by industry experts with decades of experience, delivering insights you can
                  trust.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 wow fadeInUp" data-wow-delay=".4s">
              <div className="single-featuer advantage-card">
                <div className="advantage-icon">
                  <img src={saveTimeImage} alt="#" />
                </div>
                <h3>Save Time</h3>
                <p>
                  Time is money. And we save you time. We cut wait times down to minutes so you can make the right
                  decisions right now.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 wow fadeInUp" data-wow-delay=".6s">
              <div className="single-featuer advantage-card">
                <div className="advantage-icon">
                  <img src={detailedResultsImage} alt="#" />
                </div>
                <h3>Detailed Results</h3>
                <p>
                  In-depth, easy-to-read reports give you valuable business and property insights to help you scale your
                  business confidently.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="get-started">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title dark-bg">
                <h2 className="wow fadeInUp" data-wow-delay=".4s">
                  Ready to Get the Insights You Need to Grow Your Business Today?
                </h2>
                <p className="wow fadeInUp" data-wow-delay=".6s">
                  Use our cutting-edge valuation and projection model to get the data you need to grow in minutes.
                </p>
                <div className="button insightBtn wow fadeInUp" data-wow-delay=".8s">
                  <Link
                    className="btn btn-icon icon-home"
                    onClick={() => {
                      setIsModal(true);
                    }}
                  >
                    {' '}
                    GET MY INSIGHTS
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CopyRightComponent />
    </div>
  );
}

export default Main;
