import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';

function Privacy({ isModal, setIsModal }) {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
 
  const toggle = () => {
    setIsModal(!isModal);
  };

  return (
    <div className="moconatiner">
      <Modal isOpen={isModal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Terms of Use</ModalHeader>
        <ModalBody className="modalconatiner custom-modal-body">
          Before going forward, we require that you read, understand, or agree to the Terms of Use. &nbsp;
          <span>
            <Link className="" to={'/user-aggrement'}>
              View Full Terms of Use
            </Link>
          </span>{' '}
          <div>
            <label>
              <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} /> I have read and understand
              the Terms of Use.
            </label>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => {
              setIsModal(false);
              navigate('/Wizard');
            }}
            disabled={!isChecked}
            className="sumbitBtnPrivacy"
          >
            <span>Submit</span>
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default Privacy;
