import { Link } from 'react-router-dom';

import loginIcon from '../assets/images/login.png';
import signUpIcon from '../assets/images/signup.png';
import ScrollToTop from 'react-scroll-to-top';

import appleicon from '../assets/images/apple.png';
import googleicon from '../assets/images/google-play.png';
import shop from '../assets/images/shop.png';

function CopyRightComponent() {
  return (
    <div>
      <div className="copyright">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <p className="copyright-text">&copy; Copyright {new Date().getFullYear()} Tezintel All Rights Reserved</p>
            </div>
            <div className="col-md-6 footcont">
              <div className="footerdiv">
                <div>
                  <a href="https://play.google.com/store/apps/details?id=com.tezIntelMobile"  target="_blank">
                    <img src={googleicon} alt="googleicon" className="footericon" />
                  </a>
                </div>
                <div>
                  <a href="https://play.google.com/store/apps/details?id=com.tezIntelMobile" target="_blank">
                    <p className="footerpara">GET IT ON </p>
                    <h5 className="footertext">Google Play</h5>
                  </a>
                </div>
              </div>
              <div className="footerdiv">
                <div>
                  <a href="https://apps.apple.com/us/app/tezintel/id6470182263"  target="_blank">
                    <img src={appleicon} alt="googleicon" className="footericon" />
                  </a>
                </div>
                <div>
                  <a href="https://apps.apple.com/us/app/tezintel/id6470182263" target="_blank">
                    <p className="footerpara">Download on the </p>
                    <h5 className="footertext">App Store</h5>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop
        className={'scroll-top'}
        smooth
        component={
          <svg width="15px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
              fill="#ffffff"
              d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"
            />
          </svg>
        }
      />
    </div>
  );
}

export default CopyRightComponent;
