import { Link } from 'react-router-dom';
import logo from '../assets/images/logo/logo.png';
import logo2 from '../assets/images/logo/logo3.png';
import { useAuth } from '../hooks/useAuth';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { RxHamburgerMenu } from 'react-icons/rx';
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md';

function SubHeader({ teamPage }) {
  const { user } = useAuth();
  const location = useLocation();
  const { pathname } = location;
  const [isToggle, setIsToggle] = useState(false);
  const [mobileDropdown, setMobileDropdown] = useState(false);
  const [isdropDownToggle, setIsDropdownToggle] = useState(false);

  let timeoutId;

  const handleMouseEnter = () => {
    clearTimeout(timeoutId);

    setIsDropdownToggle(true);
  };

  const handleMouseLeave = () => {
    timeoutId = setTimeout(() => setIsDropdownToggle(false), 150);
  };

  return (
    <header className="navbar-area">
      <div className="container">
        <nav className="flex items-center  justify-between py-3">
          {/* Logo on the Left */}
          <Link to="/">
            <img className="w-40" src={teamPage ? logo2 : logo} alt="Logo" />
          </Link>

          {/* Toggler button for mobile view */}
          <button className="navbar-toggler   p-2 block lg:hidden" onClick={() => setIsToggle(!isToggle)}>
            <RxHamburgerMenu color="white" size={30} />
          </button>

          {/* Nav Items (hidden on mobile by default) */}
          <div className={` hidden lg:block `}>
            <ul className="flex flex-col lg:flex-row lg:space-x-11  lg:space-y-0 items-center  mr-[4.2rem]  ">
              <li>
                <Link
                  to="/"
                  className={`${pathname === '/' ? 'text-[#00abff]' : 'text-white'} font-[16px] hover:text-[#00abff]`}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/payment"
                  className={`${pathname === '/payment' ? 'text-[#00abff]' : 'text-white'} hover:text-[#00abff]`}
                >
                  Payment
                </Link>
              </li>
              {user && (
                <li>
                  <Link
                    to="/submittedreports"
                    className={`${
                      pathname === '/submittedreports' ? 'text-[#00abff]' : 'text-white'
                    } hover:text-[#00abff]`}
                  >
                    Submitted Reports
                  </Link>
                </li>
              )}
              {/* Services Dropdown */}
              <li className="relative z-50" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    setIsDropdownToggle(!isdropDownToggle);
                  }}
                  className={`${
                    pathname === '/projection' || pathname === '/valuation' ? 'text-[#00abff]' : ''
                  } text-[#fff] hover:text-[#00abff] cursor-pointer`}
                >
                  Services
                </a>
                <ul
                  className={`${
                    isdropDownToggle ? 'block fade-in' : 'hidden fade-out'
                  } absolute left-0 mt-2  p-2 w-60 bg-white shadow-lg rounded-lg transition-all duration-1000`}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <li className="">
                    <Link to="/site-selection" className="block px-4 py-2 text-[#000] hover:text-[#00abff]">
                      Site Selection
                    </Link>
                  </li>
                  <li>
                    <Link to="/projection" className="block px-4 py-2 text-[#000] hover:text-[#00abff]">
                      Site Feasibility
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link
                  to="/how-to-use"
                  className={`${pathname === '/how-to-use' ? 'text-[#00abff]' : 'text-white'} hover:text-[#00abff]`}
                >
                  How to use Tezintel
                </Link>
              </li>
              <li>
                <Link
                  to="/team"
                  className={`${pathname === '/team' ? 'text-[#00abff]' : 'text-white'} hover:text-[#00abff]`}
                >
                  Team
                </Link>
              </li>
              <li>
                <Link
                  to="/contact-us"
                  className={`${pathname === '/contact-us' ? 'text-[#00abff]' : 'text-white'} hover:text-[#00abff]`}
                >
                  Contact Us
                </Link>
              </li>
             
              {/* <li className="rounded-md text-white">
                <Link
                  to="/getstarted"
                  className=" px-4 py-2 rounded-lg   bg-[#00abff] text-[#fff] hover:bg-white hover:text-[#00abff]"
                >
                  Get Started
                </Link>
              </li> */}
            </ul>
          </div>
        </nav>

        <div className={` ${isToggle ? 'block' : 'hidden'}   lg:hidden `}>
          <ul className="flex flex-col lg:flex-row lg:space-x-11 space-y-3  lg:space-y-0 items-start  xl:mr-[70px]   bg-white text-black rounded-lg p-4">
            <li>
              <Link
                to="/"
                className={`${pathname === '/' ? 'text-[#00abff]' : 'text-black '} font-[16px] hover:text-[#00abff]`}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/payment"
                className={`${pathname === '/payment' ? 'text-[#00abff]' : 'text-black '} hover:text-[#00abff]`}
              >
                Payment
              </Link>
            </li>
            {user && (
              <li>
                <Link
                  to="/submittedreports"
                  className={`${
                    pathname === '/submittedreports' ? 'text-[#00abff]' : 'text-black '
                  } hover:text-[#00abff]`}
                >
                  Submitted Reports
                </Link>
              </li>
            )}
            <div className="w-full">
              <div
                className="w-full flex items-center justify-between cursor-pointer"
                onClick={() => setMobileDropdown(!mobileDropdown)}
              >
                <div className="text-black">Services</div>

                <div className="">
                  {mobileDropdown ? <MdKeyboardArrowUp size={25} /> : <MdKeyboardArrowDown size={25} />}
                </div>
              </div>
            </div>
            <ul className={`${mobileDropdown ? 'block' : 'hidden'} `}>
              <li>
                <Link to="/site-selection" className="block px-4 py-2 text-black hover:text-[#00abff]">
                  Site Selection
                </Link>
              </li>
              <li>
                <Link to="/projection" className="block px-4 py-2 text-black hover:text-[#00abff]">
                  Site Feasibility
                </Link>
              </li>
            </ul>

            {/* Services Dropdown */}
            {/* <li className="relative" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setIsDropdownToggle(!isdropDownToggle);
                }}
                className={`${
                  pathname === '/projection' || pathname === '/valuation' ? 'text-[#00abff]' : ''
                } text-black  hover:text-[#00abff]`}
              >
                Services
              </a>
              <ul
                className={`${
                  isdropDownToggle ? 'block' : 'hidden'
                } absolute left-0 mt-2 w-48 text-black  shadow-lg rounded-lg`}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <li>
                  <Link to="/projection" className="block px-4 py-2 text-black hover:text-[#00abff]">
                    Financial Projection
                  </Link>
                </li>
                <li>
                  <Link to="/valuation" className="block px-4 py-2 text-black hover:text-[#00abff]">
                    Real Estate Valuation
                  </Link>
                </li>
              </ul>
            </li> */}
            <li>
              <Link
                to="/how-to-use"
                className={`${pathname === '/how-to-use' ? 'text-[#00abff]' : 'text-black '} hover:text-[#00abff]`}
              >
                How to use Tezintel
              </Link>
            </li>
            <li>
              <Link
                to="/team"
                className={`${pathname === '/team' ? 'text-[#00abff]' : 'text-black '} hover:text-[#00abff]`}
              >
                Team
              </Link>
            </li>
            <li>
              <Link
                to="/contact-us"
                className={`${pathname === '/contact-us' ? 'text-[#00abff]' : 'text-black '} hover:text-[#00abff]`}
              >
                Contact Us
              </Link>
            </li>
            
            {/* <li className="rounded-md text-black ">
              <Link
                to="/getstarted"
                className=" px-4 py-2 rounded-lg   bg-[#00abff] text-[#000] hover:bg-white hover:text-[#00abff]"
              >
                Get Started
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
    </header>
  );
}

export default SubHeader;
