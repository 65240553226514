import React from 'react';
import nbhood from '../../assets/images/neighborhood.svg';

import { useAppState } from '../../hooks/useAppState';

import { useForm } from 'react-hook-form';
import { Form, FormGroup, Label } from 'reactstrap';
import { WrappedInput } from '../../components/WrappedInput';
import StepActions from './step-actions';
import GenericStepForm from './generic-step-form';

function NeighborhoodStep({ step, setStep }) {
  const [stepValues, setStepValues] = useAppState();
  
 
  const { register, setError, handleSubmit, formState, watch, reset } = useForm({
    defaultValues: stepValues,
    mode: 'onSubmit',
  });
  const { errors } = formState;

  return (
    <GenericStepForm step={step} setStep={setStep} submitHandler={handleSubmit}>
      <div className="section-header">
        <img src={nbhood} alt={''} />
        <h2 className="section-heading">Neighborhood</h2>
      </div>
      <h4 className="section-sub-heading">Check off any that apply about the neighborhood</h4>
      <div className="row">
        <div className="col-lg-12">
          <FormGroup check>
            <WrappedInput
              {...register('neighborhood_conditions')}
              type="checkbox"
              name={'neighborhood_conditions'}
              value={'heavyNoiseNeighborhood'}
            />{' '}
            <Label check>Location has noise from heavy road traffic, airport, or other source</Label>
          </FormGroup>
          <FormGroup check>
            <WrappedInput
              {...register('neighborhood_conditions')}
              type="checkbox"
              value={'quietNeighborhood'}
              name={'neighborhood_conditions'}
            />{' '}
            <Label check>Quiet neighborhood</Label>
          </FormGroup>
          <FormGroup check>
            <WrappedInput
              {...register('neighborhood_conditions')}
              type="checkbox"
              value={'nearbyPropertyWellMaintained'}
              name={'neighborhood_conditions'}
            />{' '}
            <Label check>Nearby properties are well maintained</Label>
          </FormGroup>
          <FormGroup check>
            <WrappedInput
              {...register('neighborhood_conditions')}
              value={'luxuryNeighborhood'}
              type="checkbox"
              name={'neighborhood_conditions'}
            />{' '}
            <Label check>Neighborhood has luxury houses, golf course, beach, other high-end features</Label>
          </FormGroup>
          <FormGroup check>
            <WrappedInput
              {...register('neighborhood_conditions')}
              type="checkbox"
              value={'boardedUpNeighborhood'}
              name={'neighborhood_conditions'}
            />{' '}
            <Label check>Neighborhood has boarded-up houses, vacant buildings</Label>
          </FormGroup>
          <FormGroup check>
            <WrappedInput
              {...register('neighborhood_conditions')}
              type="checkbox"
              value={'disrepairNeighborhood'}
              name={'neighborhood_conditions'}
            />{' '}
            <Label check>Nearby properties are in disrepair</Label>
          </FormGroup>
          <FormGroup check>
            <WrappedInput
              {...register('neighborhood_conditions')}
              type="checkbox"
              value={'averageNeighborhood'}
              name={'neighborhood_conditions'}
            />{' '}
            <Label check>Average neighborhood</Label>
          </FormGroup>
        </div>
      </div>
    </GenericStepForm>
  );
}

export default NeighborhoodStep;
