import { useAppState } from '../../hooks/useAppState';

import logo from '../../assets/images/logo/logo.png';
import { useState } from 'react';
import SiteLocationStep from './site-location-step';
// import SiteDetailStep from "./site-detail-step";
import UnitsSteps from './units-steps';
import NeighborhoodStep from './neighborhood-step';
import BuildingStep from './building-step';
// import SiteImageStep from "./site-image-step";
import ExtraIncomeStep from './extra-income-step';
import OperatingExpensesStep from '././operating-expenses-step';
import GenerateReportStep from './generate-report-step';
import ProjectTypeStep from './project-type-step';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

function ApartmentSteps() {
  const { user } = useAuth();

  const [stepValues, setStepValues] = useAppState();
  const [currentStep, setCurrentStepState] = useState(1);

  const [toggle, setToggle] = useState(false);
 

  const setCurrentStep = (step) => {
    if (step <= 1) {
      setCurrentStepState(step);
    } else if (stepValues.stepStatus[step - 1] === 'done') setCurrentStepState(step);
    else setCurrentStepState(currentStep);
  };

  const getStepClass = (step) => {
    if (step === currentStep) return 'current';
    else {
      if (!stepValues.stepStatus) return '';
      return stepValues.stepStatus[step] === 'done' ? 'done' : '';
    }
  };

  const StepListItem = ({ index, title }) => {
    return (
      <li
        role="tab"
        className={`${index === 1 ? 'first' : ''} ${getStepClass(index)}`}
        aria-disabled="false"
        aria-selected="false"
      >
        <a
          aria-controls="commercial-wizard-p-0"
          onClick={() => {
            if (stepValues.stepStatus[currentStep] === 'done') {
              setStepValues({ ...stepValues, ...{ stepSubmitInvoked: true, stepSubmitInvokedIndex: index } });
              return;
            }
            setCurrentStep(index);
          }}
        >
          <span className="number">{index}</span>
          <span>{title}</span>
        </a>
      </li>
    );
  };

  return (
    <div className={'wizard clearfix vertical'}>
      <div className={'steps clearfix'}>
        <div className="steps-text">
          <Link to={'/'}>
            <img src={logo} alt="Logo" />
          </Link>
          <p>
            Follow the simple 8 Steps to complete
            <br /> your analysis for <span className="color-primary">Residential property</span>
          </p>
        </div>
        <ul className={'tabList'}>
          <StepListItem index={1} title="Project Type" />
          <StepListItem index={2} title=" Site Location" />
          <StepListItem index={3} title="Units" />
          <StepListItem index={4} title="Neighborhood" />
          <StepListItem index={5} title="Building Details" />
          <StepListItem index={6} title="Extra Income" />
          <StepListItem index={7} title="Operating Expenses" />
          <StepListItem index={8} title="Generate Report" />
        </ul>
      </div>
      <div className="subStepHeader">
        <div className={toggle ? 'toggleMenu mb-2 active' : 'toggleMenu mb-2'}>
          <button
            onClick={() => {
              setToggle(!toggle);
            }}
            className="toggler-icon-btn-main"
          >
            <span className="toggler-icon-btn"></span>
            <span className="toggler-icon-btn"></span>
            <span className="toggler-icon-btn"></span>
          </button>
        </div>
        <ul className={toggle ? 'menuActive' : 'meneul'}>
          <li>
            {' '}
            <Link to="/">Home</Link>{' '}
          </li>
          {user && (
            <li>
              {' '}
              <Link to={'/submittedreports'}>Submitted Reports</Link>{' '}
            </li>
          )}

          <li>
            {' '}
            <Link to="/contact-us">Contact Us</Link>{' '}
          </li>
        </ul>
      </div>

      <div className={'content clearfix'}>
        {currentStep === 1 && <ProjectTypeStep step={currentStep} setStep={setCurrentStep} />}
        {currentStep === 2 && <SiteLocationStep step={currentStep} setStep={setCurrentStep} />}
        {currentStep === 3 && <UnitsSteps step={currentStep} setStep={setCurrentStep} />}
        {currentStep === 4 && <NeighborhoodStep step={currentStep} setStep={setCurrentStep} />}
        {currentStep === 5 && <BuildingStep step={currentStep} setStep={setCurrentStep} />}
        {currentStep === 6 && <ExtraIncomeStep step={currentStep} setStep={setCurrentStep} />}
        {currentStep === 7 && <OperatingExpensesStep step={currentStep} setStep={setCurrentStep} />}
        {currentStep === 8 && <GenerateReportStep step={currentStep} setStep={setCurrentStep} />}
      </div>
    </div>
  );
}

export default ApartmentSteps;
